import React from 'react';
import {Trans} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {Row} from '@edna/components/primitives';

import {Link} from 'src/components/primitives';
import {ECallbackSystem, useGetAllActiveCallbackSystemQuery} from 'src/containers/Integration/api';

import {useGetTenantDataQuery} from '../User';
import ApiKey from './ApiKey';
import AuthCallback from './AuthCallback';
import CallbackUrl from './CallbackUrl';
import {useGetAllCallbackUrlsQuery, useGetApiKeyQuery} from './api';
import {EUrlType} from './definitions';
import * as S from './style';
import {getCallbackUrlByType} from './utils';

const CompanyCallback = React.memo(() => {
  const {id: supportTenantId} = useParams<'id'>();

  const {data: tenantData} = useGetTenantDataQuery();

  const personalManagerEmail = tenantData?.personalManagerEmail ?? null;

  const {data: apiKeyData} = useGetApiKeyQuery(supportTenantId);

  const {data: activeCallbacks = []} = useGetAllActiveCallbackSystemQuery();
  const {data: callbackUrls = []} = useGetAllCallbackUrlsQuery(supportTenantId);

  const hasJivo = React.useMemo(
    () => activeCallbacks.some((item) => item === ECallbackSystem.JIVO),
    [activeCallbacks],
  );

  const callbackUrlsByType = React.useMemo(
    () => ({
      [EUrlType.STATUS]: getCallbackUrlByType(callbackUrls, EUrlType.STATUS),
      [EUrlType.IN_MESSAGE]: getCallbackUrlByType(callbackUrls, EUrlType.IN_MESSAGE),
      [EUrlType.MESSAGE_MATCHER]: getCallbackUrlByType(callbackUrls, EUrlType.MESSAGE_MATCHER),
    }),
    [callbackUrls],
  );

  const showMessage =
    hasJivo ||
    !!apiKeyData?.locked ||
    callbackUrlsByType[EUrlType.STATUS].locked ||
    callbackUrlsByType[EUrlType.IN_MESSAGE].locked ||
    callbackUrlsByType[EUrlType.MESSAGE_MATCHER].locked;

  return (
    <S.StartColumn>
      {showMessage && (
        <S.Message
          type="Warning"
          title={
            <Trans i18nKey="UserCompanyProfile:blockedByLicense">
              <Link href={`mailto:${personalManagerEmail}`} />
            </Trans>
          }
          closible
        />
      )}
      <Row>
        <S.SmallStartColumn>
          <ApiKey apiKeyData={apiKeyData} />
          <AuthCallback />
        </S.SmallStartColumn>
        <S.SmallStartColumn>
          <CallbackUrl
            hasJivo={hasJivo}
            callbackUrl={callbackUrlsByType[EUrlType.STATUS]}
            disabled={callbackUrlsByType[EUrlType.STATUS].locked || hasJivo}
          />
          <CallbackUrl
            hasJivo={hasJivo}
            callbackUrl={callbackUrlsByType[EUrlType.IN_MESSAGE]}
            disabled={callbackUrlsByType[EUrlType.IN_MESSAGE].locked || hasJivo}
          />
          <CallbackUrl
            callbackUrl={callbackUrlsByType[EUrlType.MESSAGE_MATCHER]}
            disabled={callbackUrlsByType[EUrlType.MESSAGE_MATCHER].locked}
          />
        </S.SmallStartColumn>
      </Row>
    </S.StartColumn>
  );
});

CompanyCallback.displayName = 'CompanyCallback';

export default CompanyCallback;
