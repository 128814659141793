import axios, {AxiosError, AxiosResponse} from 'axios';
import {Store} from 'redux';

import {TUser, resetUser, setTenantId} from 'src/containers/User';

import {axiosInstance} from './api';
import {EInternalError, REFRESH_API_URL} from './definitions';

const createError = (code: EInternalError) => ({data: {code}}) as AxiosResponse;

let requestRefreshPromise: Promise<AxiosResponse<{tenants: TUser['tenants']}>> | null = null;

let requestInterceptorsInstance: number | undefined = undefined;
let responseInterceptorsInstance: number | undefined = undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestInterceptors = (store: Store<TRootState, any>) => {
  if (responseInterceptorsInstance !== undefined) {
    axiosInstance.interceptors.response.eject(responseInterceptorsInstance);
  }

  if (requestInterceptorsInstance !== undefined) {
    axiosInstance.interceptors.request.eject(requestInterceptorsInstance);
  }

  requestInterceptorsInstance = axiosInstance.interceptors.request.use(
    (config) => {
      config.headers['tenant-id'] = store.getState().user.tenantId;

      return config;
    },
    (error) => Promise.reject(error),
  );

  responseInterceptorsInstance = axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,

    async (error: Error | AxiosError) => {
      if (!axios.isAxiosError(error)) {
        return Promise.reject(error);
      }

      const {response, config} = error;

      if (!response) {
        return Promise.reject(createError(EInternalError.NETWORK_ERROR));
      }

      const tenantId = store.getState().user.tenantId;

      if (response.status === 401) {
        try {
          if (!requestRefreshPromise) {
            requestRefreshPromise = axios.post(REFRESH_API_URL);
          }

          const refreshData = await requestRefreshPromise;

          if (!refreshData.data?.tenants?.some(({id}) => id === tenantId)) {
            store.dispatch(setTenantId(refreshData.data.tenants[0].id));
          }
        } catch (refreshTokenError) {
          store.dispatch(resetUser());

          // eslint-disable-next-line @typescript-eslint/return-await
          return Promise.reject(
            (refreshTokenError as TAnyObject).response ?? createError(EInternalError.NETWORK_ERROR),
          );
        } finally {
          requestRefreshPromise = null;
        }

        return axiosInstance.request(config ?? {});
      }

      if (!response.data) {
        return Promise.reject(createError(EInternalError.ABORT_ERROR));
      }

      return Promise.reject(response);
    },
  );
};

export {requestInterceptors};
