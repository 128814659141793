import {EPaymentCorrectionType} from './PaymentsTab';
import {ECorrectionsTab} from './definitions';

export default {
  tabs: {
    [ECorrectionsTab.PAYMENTS]: 'Списания и оплаты',
    [ECorrectionsTab.LICENSES]: 'Лицензии',
    [ECorrectionsTab.TARIFFS]: 'Изменения тарифов',
  },
  payments: {
    storno: 'Сторнировать операцию',
    selectType: 'Выберите тип операции',
    notFound: 'Операция не найдена',
    type: {
      [EPaymentCorrectionType.STORNO]: 'Сторнировать операцию',
      [EPaymentCorrectionType.CANCEL_STORNO]: 'Отменить сторно',
    },
    enterEventId: 'Укажите ID операции',
    searchResults: 'Результаты поиска',
    companyName: 'Наименование компании',
    paymentType: 'Тип списания',
    licensePayment: 'Списание за лицензию',
    amount: 'Сумма списания',
    currency: 'Валюта списания',
    isSearchCorrectMessage: 'Обязательно убедитесь в корректности запроса по таблицам',
    billedEvent: 'Billed event',
    debtTransaction: 'Debt transaction',
    transaction: 'Transaction',
    id: 'ID',
    originalEventId: 'Original event ID',
    originalEventType: 'Original event Type',
    confirmation: 'Confirmation',
    createdAt: 'Дата',
    eventId: 'Event ID',
    tenantId: 'ID компании',
    reason: 'Reason',
    reasonCode: 'Reason code',
    balanceId: 'Balance ID',
    openingBalance: 'Opening balance',
    state: 'Статус',
    updated: 'Обновлено',
    search: 'Найти',
    comment: 'Комментарий',
    button: {
      [EPaymentCorrectionType.STORNO]: 'Сторнировать',
      [EPaymentCorrectionType.CANCEL_STORNO]: 'Отменить сторно',
    },
    confirmModal: {
      title: {
        [EPaymentCorrectionType.STORNO]: 'Уверены? Мы отменяем списание',
        [EPaymentCorrectionType.CANCEL_STORNO]: 'Уверены? Мы отменяем сторно',
      },
      message: 'Если это ошибочная операция, то исправить это будет невозможно',
      subtitle: 'Проверьте данные:',
      button: 'Да, продолжить',
    },
    successModal: {
      title: 'Все хорошо!',
      message: 'Запрос принят к исполнению',
    },
  },
  licenses: {
    channelType: 'Укажите тип канала',
    licenseId: 'Укажите ID лицензии',
    month: 'Номер месяца',
    year: 'Год',
    eventTable: 'license_billed_event_tab (по указанному периоду)',
    notFound: 'Лицензия не найдена',
    search: 'Поиск',
    notification: 'Операция пролонгации лицензии запущена',
    tr: {
      id: 'ID',
      channel: 'Канал',
      price: 'Стоимость',
      currency: 'Валюта',
      paymentType: 'Тип списания',
      periodStartedAt: 'Начало',
      periodEndedAt: 'Окончание',
      transactionId: 'ID транзакции',
      createdAt: 'Создана',
      updatedAt: 'Обновлена',
      documentId: 'ID документа',
      tenantId: 'ID тенанты',
      subjectId: 'ID подписи',
      periodMonths: 'Период продления, мес',
      name: 'Название',
      description: 'Примечание',
      licenseStatus: 'Статус лицензии',
      activatedAt: 'Дата активации',
      expiredAt: 'Лицензия действительна до',
      cancelledAt: 'Отменена',
      activeUntil: 'Активирована с ',
      paidUntil: 'Оплачена до',
      temporaryblockedAt: 'Временно заблокировано c',
      paymentCode: 'Код списания',
    },
  },
};
