import {TCurrency} from '@edna/utils/formatMoney';

import {EChannelType, EOperatorName, TChannelType} from 'src/constants';
import {ETrafficType} from 'src/containers/Report/definitions';

import {ESmsCategory} from '../MessageMatchers/SMSForm/definitions';

export enum EWAConversation {
  REFERRAL = 'referral',
  SERVICE = 'service',
  UTILITY = 'utility',
  AUTHENTICATION = 'authentication',
  MARKETING = 'marketing',
}

export type TMessageReport = {
  subjectId: number;
  channelType: TChannelType;
  inMessageStatistics: {
    totalCount: number;
  };
  outMessageStatistics: {
    outMessageWithoutMessageMatcherStatistics: {
      totalCount: number;
      sentCount: number;
      deliveredCount: number;
      undeliveredCount: number;
      readCount: number;
    };
    outMessageWithMessageMatcherStatistics: {
      totalCount: number;
      sentCount: number;
      deliveredCount: number;
      undeliveredCount: number;
      readCount: number;
    };
  };
};

export type TBilledStatistics = {
  conversationType?: EWAConversation;
  messageMatcherExists?: boolean;
  country: string;
  price: number;
  totalCount: number;
  totalAmount: number;
};

export type TWhatsappStatistics = {
  whatsappMauStatistics: {
    licensePrice: number;
    tariffMauCount: number;
    mauCount: number;
    tariffRemainingMauCount: number;
    amauCount: number;
    amauPrice: number;
    amauTotalAmount: number;
  };
  whatsappConversationStatistics: {
    countStatistics: {
      conversationType: EWAConversation;
      totalCount: number;
      freeCount: number;
      billedCount: number;
    }[];
    billedStatistics: TBilledStatistics[];
  };
};

export type TViberStatistics = {
  tariffPackagePrice: number;
  viberOutMessageBilledStatistics: TBilledStatistics[];
};

export type TSmsBilledStatistics = {
  trafficType: ETrafficType;
  totalSegments: number;
  paidSegments: number;
  mobileOperatorCode: EOperatorName;
  costPerSegment: number;
  totalCost: number;
  currency: TCurrency;
};

export type TSmsStatistics = {
  mobileOperator: EOperatorName;
  category: ESmsCategory;
  billedCount: number;
  averageCost?: number;
  totalCost?: number;
}[];

export type TSmsBilling = {
  mobileOperator: EOperatorName;
  currency: string;
  cost: number;
}[];

export type TFilters = {
  filter: string;
  channelTypes: EChannelType[] | null;
};

export const DEFAULT_MESSAGE_REPORT: TMessageReport = {
  subjectId: 0,
  channelType: EChannelType.SMS,
  inMessageStatistics: {
    totalCount: 0,
  },
  outMessageStatistics: {
    outMessageWithoutMessageMatcherStatistics: {
      totalCount: 0,
      sentCount: 0,
      deliveredCount: 0,
      undeliveredCount: 0,
      readCount: 0,
    },
    outMessageWithMessageMatcherStatistics: {
      totalCount: 0,
      sentCount: 0,
      deliveredCount: 0,
      undeliveredCount: 0,
      readCount: 0,
    },
  },
};

export const DEFAULT_WHATSAPP_STATISTICS: TWhatsappStatistics = {
  whatsappMauStatistics: {
    licensePrice: 0,
    tariffMauCount: 0,
    mauCount: 0,
    tariffRemainingMauCount: 0,
    amauCount: 0,
    amauPrice: 0,
    amauTotalAmount: 0.0,
  },
  whatsappConversationStatistics: {
    countStatistics: [
      {
        conversationType: EWAConversation.REFERRAL,
        totalCount: 0,
        freeCount: 0,
        billedCount: 0,
      },
      {
        conversationType: EWAConversation.SERVICE,
        totalCount: 0,
        freeCount: 0,
        billedCount: 0,
      },
      {
        conversationType: EWAConversation.UTILITY,
        totalCount: 0,
        freeCount: 0,
        billedCount: 0,
      },
      {
        conversationType: EWAConversation.AUTHENTICATION,
        totalCount: 0,
        freeCount: 0,
        billedCount: 0,
      },
      {
        conversationType: EWAConversation.MARKETING,
        totalCount: 0,
        freeCount: 0,
        billedCount: 0,
      },
    ],
    billedStatistics: [
      {
        conversationType: EWAConversation.REFERRAL,
        country: 'RUS',
        price: 0,
        totalCount: 0,
        totalAmount: 0,
      },
      {
        conversationType: EWAConversation.SERVICE,
        country: 'RUS',
        price: 0,
        totalCount: 0,
        totalAmount: 0,
      },
      {
        conversationType: EWAConversation.UTILITY,
        country: 'RUS',
        price: 0,
        totalCount: 0,
        totalAmount: 0,
      },
      {
        conversationType: EWAConversation.AUTHENTICATION,
        country: 'RUS',
        price: 0,
        totalCount: 0,
        totalAmount: 0,
      },
      {
        conversationType: EWAConversation.MARKETING,
        country: 'RUS',
        price: 0,
        totalCount: 0,
        totalAmount: 0,
      },
    ],
  },
};

export const DEFAULT_VIBER_STATISTICS: TViberStatistics = {
  tariffPackagePrice: 0,
  viberOutMessageBilledStatistics: [
    {
      messageMatcherExists: false,
      country: 'RUS',
      price: 0,
      totalCount: 0,
      totalAmount: 0,
    },
  ],
};
