import {and, any, not} from '@edna/utils/operators';

import {TItemId, TTenantDependentPayload} from 'src/constants';
import {EPermission, ERoles} from 'src/containers/User';
import {rootApi} from 'src/models';

import {TCompanyDetails, TPaymentDetails, TTenantLeadDetails} from './definitions';
import {COMPANY_DETAILS, PAYMENT_DETAILS, getResidentCountry} from './utils';

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyDetails: builder.query<TCompanyDetails, TItemId | void>({
      query: (supportTenantId) => ({
        method: 'GET',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/company-details`
            : '/tenantmanagement/tenant/company-details',
        meta: {
          isShowError: true,
          permissions:
            supportTenantId !== undefined
              ? and<EPermission>('PERMISSION_TENANT_DETAILS_READ')
              : any(),
        },
      }),
      transformResponse: (data: TCompanyDetails) =>
        COMPANY_DETAILS[getResidentCountry(data.country)](data),
    }),
    saveCompanyDetails: builder.mutation<TCompanyDetails, TTenantDependentPayload>({
      query: ({data, supportTenantId}) => ({
        method: 'POST',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/company-details`
            : '/tenantmanagement/tenant/company-details',
        data,
        meta: {
          isShowError: true,
          permissions:
            supportTenantId !== undefined
              ? and<EPermission>('PERMISSION_TENANT_DETAILS_WRITE')
              : any(),
        },
      }),
      async onQueryStarted({supportTenantId}, {dispatch, queryFulfilled}) {
        try {
          const {data: newCompanyDetails} = await queryFulfilled;

          dispatch(
            api.util.updateQueryData('getCompanyDetails', supportTenantId, () => newCompanyDetails),
          );
        } catch {}
      },
    }),
    getPaymentDetails: builder.query<TPaymentDetails, TItemId | void>({
      query: (supportTenantId) => ({
        method: 'GET',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/payment-details`
            : '/tenantmanagement/tenant/payment-details',
        meta: {isShowError: true},
      }),
      transformResponse: (data: TPaymentDetails) =>
        PAYMENT_DETAILS[getResidentCountry(data.country)](data),
    }),
    savePaymentDetails: builder.mutation<TPaymentDetails, TTenantDependentPayload>({
      query: ({data, supportTenantId}) => ({
        method: 'POST',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/payment-details`
            : '/tenantmanagement/tenant/payment-details',
        data,
        meta: {isShowError: true},
      }),
      async onQueryStarted({supportTenantId}, {dispatch, queryFulfilled}) {
        try {
          const {data: newPaymentDetails} = await queryFulfilled;

          dispatch(
            api.util.updateQueryData('getPaymentDetails', supportTenantId, () => newPaymentDetails),
          );
        } catch {}
      },
    }),
    getTenantLeadDetails: builder.query<TTenantLeadDetails, TItemId | void>({
      query: (supportTenantId) => ({
        method: 'GET',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant-lead/by-tenant/${supportTenantId}`
            : '/tenantmanagement/tenant-lead/by-tenant',
        meta: {
          isShowError: true,
          roles: not<ERoles>('ROLE_BASIC_USER'),
          permissions:
            supportTenantId !== undefined
              ? and<EPermission>('PERMISSION_TENANT_DETAILS_READ')
              : any(),
        },
      }),
    }),
    saveTenantLeadDetails: builder.mutation<
      TTenantLeadDetails,
      Required<TTenantDependentPayload> & {tenantLeadId: TItemId}
    >({
      query: ({data, tenantLeadId}) => ({
        method: 'PATCH',
        url: `/tenantmanagement/tenant-lead/${tenantLeadId}`,
        data,
        meta: {isShowError: true},
      }),
      async onQueryStarted({supportTenantId}, {dispatch, queryFulfilled}) {
        try {
          const {data: newTenantLeadDetails} = await queryFulfilled;

          dispatch(
            api.util.updateQueryData(
              'getTenantLeadDetails',
              supportTenantId,
              () => newTenantLeadDetails,
            ),
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useGetCompanyDetailsQuery,
  useGetPaymentDetailsQuery,
  useGetTenantLeadDetailsQuery,
  useSaveCompanyDetailsMutation,
  useSavePaymentDetailsMutation,
  useSaveTenantLeadDetailsMutation,
  endpoints: companyDetailsEndpoints,
} = api;
