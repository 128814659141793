import styled, {css} from 'styled-components';

import {TextInput as BaseTextInput} from '@edna/components';
import {Row as BaseRow} from '@edna/components/primitives';
import {mixins} from '@edna/components/styles';

import {MenuButton as BaseMenuButton} from 'src/containers/MenuButton';

export const TextInput = styled(BaseTextInput)`
  ${({theme: {spacing, dimensions}}) => css`
    width: ${dimensions.formWidth}px;
    margin-bottom: ${spacing.s2};
  `}
`;

export const Row = styled(BaseRow)`
  ${mixins.flexAlign('flex-start', 'center')}
  ${({theme}) => css`
    margin-left: ${theme.spacing.s11};
  `}
`;

export const MenuButton = styled(BaseMenuButton)`
  align-self: flex-start;
  ${({theme: {spacing}}) => css`
    margin-bottom: ${spacing.s2};
    margin-left: ${spacing.s2};
  `}
`;
