import {EStatus} from './definitions';

export default {
  coverText:
    'Подключите ваш Instagram Business Account и общайтесь в чате с вашими клиентами когда они обращаются к вам через директ, постят сториз с упоминанием вашего аккаунта или отвечают на ваши сториз',
  editForm: {
    name: 'Внутреннее название канала',
  },
  loginMessage:
    'Чтобы зарегистрировать канал войдите в учетную запись Facebook и следуйте предложенным шагам',
  status: {
    [EStatus.SENT_TO_PLATFORM]: {
      title: 'На регистрации',
      details: 'Отправлен на регистрацию',
    },
    [EStatus.FINISH_REGISTER]: {
      title: 'Активирован',
      details: 'Работает исправно',
    },
    [EStatus.UNKNOWN]: {
      title: 'Неизвестный',
      details: '',
    },
  },
  profileName: 'Имя профиля',
  selectAccount: 'Выберите один бизнес-аккаунт для edna Pulse',
  selectAccountHint:
    'В Facebook вы выбрали несколько аккаунтов, но, во избежание путаницы, в edna Pulse к одному каналу Instagram можно подключить только один аккаунт. Для подключения другого аккаунта вы позже сможете создать еще канал. А сейчас нужно выбрать один из этого списка:',
  communicationRules: {
    title: 'Важно знать!',
    header: 'Правила общения в канале',
    content:
      'Согласно политике Instagram компания может написать пользователю Instagram только если он сам инициировал коммуникацию с Instagram Business Account компании. То есть отправить сообщение пользователю можно только в том случае если:',
    list: [
      'Пользователь Instagram обратился к вам через директ',
      'Пользователь Instagram запостил сториз с упоминанием вашего бизнес-аккаунта',
      'Пользователь Instagram ответил на ваши сториз',
    ],
    hint: `После того как произойдет одно из этих событий, у вашего аккаунта открывается возможность <b>в течение 24 часов</b> отправить этому пользователю сообщение. Если за это время аккаунт не ответит пользователю, то позже этого сделать будет нельзя.`,
  },
  button: {
    selectAccount: 'Выбрать аккаунт',
    goToInstagram: 'Перейти в Instagram',
  },
  accountError: {
    title: 'Ошибка при подключении аккаунта',
    message: `Чтобы подключить аккаунт к каналу Instagram в edna Pulse нужно выбрать страницу, привязана к Instagram аккаунту. <b>Необходимо изменить настройки</b>`,
    button: 'Попробовать заново',
  },
  accountExists: {
    title: 'Этот аккаунт уже подключен к каналу',
    message:
      'Вы уже подключили выбранный аккаунт к каналу Instagram в edna Pulse. Вы можете подключить другой аккаунт',
  },
  error: {
    accountExists: 'Этот Аккаунт уже подключен к edna Pulse. Пожалуйста, выберите другой',
  },
};
