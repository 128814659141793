import React from 'react';
import {useTranslation} from 'react-i18next';

import {Icon} from '@edna/components/primitives';
import ClockIcon from '@edna/icons/clock.svg';

import * as S from './style';

type TProps = {
  onClick: TEmptyFunction;
};

const ChangeHistory = React.memo<TProps>(({onClick}) => {
  const {t} = useTranslation();

  return (
    <S.Container>
      <Icon as={ClockIcon} size="16px" />
      <S.SmallLink onClick={onClick}>{t('Common:changeHistory')}</S.SmallLink>
    </S.Container>
  );
});

ChangeHistory.displayName = 'ChangeHistory';

export const {SmallLink} = S;
export default ChangeHistory;
