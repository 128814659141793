import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

import BaseStatusIcon, {TStatusIcon} from '../StatusIcon';

export const Description = styled.div``;

export const StatusIcon = styled(BaseStatusIcon)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: ${({theme}) => theme.spacing.s3};
`;

type TStatusProps = {
  name: TStatusIcon;
};

const getColorStyle = ({name, theme}: TTheme & TStatusProps) => {
  if (name === 'error') {
    return css`
      color: ${theme.palette.red500};
    `;
  }

  if (name === 'ok' || name === 'okContour') {
    return css`
      color: ${theme.branding.colors.success};

      ${Description} {
        color: ${theme.branding.content.secondary};
      }
    `;
  }

  if (name === 'pending') {
    return css`
      color: ${theme.branding.content.primary};

      ${Description} {
        color: ${theme.branding.content.secondary};
      }
    `;
  }

  return css`
    color: inherit;
  `;
};

export const Status = styled.div<TStatusProps>`
  ${mixins.flexAlign('flex-start')}
  ${getColorStyle}
`;

export const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  :empty {
    display: none;
  }
`;
