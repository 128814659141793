import {rootApi} from 'src/models';

const serverFileApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getFile: builder.query<File, {id: string; fileName?: string}>({
      query: ({id}) => ({
        method: 'GET',
        url: `/server-file/files/${id}`,
        responseType: 'blob',
        meta: {
          isShowError: true,
        },
      }),
      transformResponse: (blob: Blob, _, {id, fileName}) =>
        new File([blob], fileName ?? id, {type: blob.type}),
    }),
    uploadAttachment: builder.mutation<TFile, File[]>({
      query: (files) => {
        const formData = new FormData();

        formData.append('attachment', files[0]);

        return {
          method: 'POST',
          url: `/server-file/files`,
          data: formData,
          meta: {
            isShowError: true,
            errorMessageKey: 'Common:loadingError',
          },
        };
      },
    }),
    uploadAttachmentByUrl: builder.mutation<TFile, string>({
      query: (externalUrl) => {
        return {
          method: 'POST',
          url: `/server-file/files/upload`,
          data: {externalUrl},
          meta: {
            isShowError: true,
            errorMessageKey: 'Common:loadingByUrlError',
          },
        };
      },
    }),
    getFileMeta: builder.query<{size: number; mimeType: string}, string>({
      query: (url) => ({
        method: 'HEAD',
        baseUrl: url,
        url: '',
      }),
      transformResponse: (_, meta) => {
        if (!meta) {
          return {size: 0, mimeType: ''};
        }

        return {
          size: Number(meta.headers?.['content-length']) ?? 0,
          mimeType: meta.headers?.['content-type'] ?? '',
        };
      },
    }),
  }),
});

export const {
  useUploadAttachmentMutation,
  useUploadAttachmentByUrlMutation,
  useLazyGetFileQuery,
  useLazyGetFileMetaQuery,
} = serverFileApi;
