export default {
  assistant: {
    title: 'Ваш лучший друг в edna',
    hint: 'Все вопросы, аккаунт и биллинг',
  },
  tech: {
    title: 'Ваш технический гуру',
    hint: 'Любые технические вопросы',
  },
  library: {
    title: 'Ваша библиотека',
    hint: 'Мануалы и инструкции',
    link: 'Руководство пользователя Личного кабинета edna Pulse',
  },
};
