import {TItemId, TSubject} from '../constants';

interface IOptions {
  id: TItemId;
  name: string;
}

const prepareOptionsForSelect = <T extends IOptions>(content: T[]) =>
  content.map(({name, id}) => ({
    label: name,
    value: id,
  }));

const prepareSubjectOptionsForSelect = (subjects: TSubject[]) =>
  subjects.map(({subject, id}) => ({
    label: subject,
    value: id,
  }));

export {prepareOptionsForSelect, prepareSubjectOptionsForSelect};
