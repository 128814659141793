import React from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {NewTextInputField} from '@edna/components';
import {FieldError} from '@edna/components/FormField';

type TProps = {
  name: string;
  label: React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  isInvalid?: boolean;
  onChange: (value: string) => void;
};

export const UploadTextField = React.memo<TProps>(
  ({name, label, onChange, isInvalid, readOnly, disabled}) => {
    const {t} = useTranslation();
    const {
      field,
      fieldState: {error},
    } = useController({name});

    const prevValueRef = React.useRef<string>('');

    const handleBlur = React.useCallback(() => {
      if (!field.value || error || field.value === prevValueRef.current) {
        return;
      }

      prevValueRef.current = field.value;

      return onChange(field.value);
    }, [onChange, field.value, error]);

    return (
      <NewTextInputField
        name={name}
        label={label}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={handleBlur}
        isInvalid={field.value && isInvalid}
        footer={
          field.value &&
          isInvalid &&
          !error && <FieldError>{t('Common:loadingByUrlError')}</FieldError>
        }
      />
    );
  },
);

UploadTextField.displayName = 'UploadTextField';

export default UploadTextField;
