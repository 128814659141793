import React from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import noop from 'lodash/noop';

import {Modals} from '@edna/components';
import {useExactSelector} from '@edna/hooks';

import {ListLoader, Menu} from 'src/components';
import {CommonLayout} from 'src/components/primitives';
import {getPrivateRoutesOptions} from 'src/containers/App/routes';
import {selectIsMenuPinned, toggleMenuPinned} from 'src/containers/App/slice';
import {checkCustomLayout} from 'src/containers/App/utils';
import {useSignOutMutation} from 'src/containers/Auth/api';
import {
  NOTIFICATIONS_POLLING_INTERVAL,
  Notifications,
  usePollNotificationsQuery,
} from 'src/containers/Notifications';
import {getCurrentUser, useGetCurrentUserQuery} from 'src/containers/User';

import MenuItem from './MenuItem';
import MenuListItem from './MenuListItem';
import {TNavigation, bottomMenu, topMenu} from './constants';
import * as S from './style';
import {filterAllowedPaths} from './utils';

type TOpenItemId = string | null;

export type TLayoutSettings = {
  isAuth: boolean;
  isAuthByInvite: boolean;
  isTriggers: boolean;
};

type TProps = {
  settings?: TLayoutSettings;
  children: React.ReactNode;
  allowedPrivatePaths?: string[];
};

const PrivateLayout = React.memo<TProps>(({children}) => {
  const dispatch = useDispatch();
  const [openItemId, setOpenItemId] = React.useState<TOpenItemId>(null);
  const [isMenuHovered, setIsMenuHovered] = React.useState(false);
  const isMenuPinned = useExactSelector(selectIsMenuPinned);

  const {data: user} = useGetCurrentUserQuery();

  usePollNotificationsQuery(undefined, {
    pollingInterval: NOTIFICATIONS_POLLING_INTERVAL,
    skip: !user,
  });

  const [signOut] = useSignOutMutation();

  const contentRef = React.useRef<HTMLDivElement>(null);

  const allowedPrivatePaths = React.useMemo(
    () => (user ? Object.keys(getPrivateRoutesOptions(user.permissions, user.roles)) : []),
    [user],
  );

  const toggleMenuItem = React.useCallback(
    (id: string) => setOpenItemId((prevId) => (prevId === id ? null : id)),
    [setOpenItemId],
  );

  const handleToggleMenuPinned = React.useCallback(() => {
    dispatch(toggleMenuPinned());
  }, [dispatch]);

  const topMenuAllowedPaths = React.useMemo(
    () => filterAllowedPaths(topMenu, allowedPrivatePaths),
    [allowedPrivatePaths],
  );
  const bottomMenuAllowedPaths = React.useMemo(
    () => filterAllowedPaths(bottomMenu, allowedPrivatePaths),
    [allowedPrivatePaths],
  );

  const handleSignOut = React.useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      signOut();
    },
    [signOut],
  );

  const renderMenuItemsList = (data: TNavigation[]) => (menuOpen: boolean) => (
    <>
      {data.map((item) => {
        if (item.list) {
          return (
            <MenuListItem
              key={item.id}
              openItemId={openItemId}
              menuOpen={menuOpen}
              item={item}
              toggleMenuItem={toggleMenuItem}
            />
          );
        }

        return (
          <MenuItem
            key={item.id}
            data={item}
            onClick={item.id === 'signout' ? handleSignOut : noop}
            hasNotifications={item.id === 'notifications'}
            menuOpen={menuOpen}
          />
        );
      })}
    </>
  );

  return (
    <CommonLayout>
      <Menu
        pinned={isMenuPinned}
        toggleMenuPinned={handleToggleMenuPinned}
        renderTopContent={renderMenuItemsList(topMenuAllowedPaths)}
        renderBottomContent={renderMenuItemsList(bottomMenuAllowedPaths)}
        hovered={isMenuHovered}
        onHoverChange={setIsMenuHovered}
      />
      <S.Main opened={isMenuPinned || isMenuHovered}>
        <S.MainContent ref={contentRef} opened={isMenuPinned || isMenuHovered}>
          <Notifications contentRef={contentRef} />
          <React.Suspense
            fallback={
              <S.SuspenseLoading>
                <ListLoader />
              </S.SuspenseLoading>
            }
          >
            {children}
          </React.Suspense>
        </S.MainContent>
      </S.Main>
    </CommonLayout>
  );
});

const Layout = React.memo<TProps>(({children}) => {
  const {pathname} = useLocation();
  const hasCustomLayout = checkCustomLayout(pathname);

  const {data: user} = getCurrentUser.useQueryState();

  if (user && !hasCustomLayout) {
    return (
      <>
        <PrivateLayout>{children}</PrivateLayout>
        <Modals />
      </>
    );
  }

  return (
    <>
      <React.Suspense fallback={null}>{children}</React.Suspense>
      <Modals />
    </>
  );
});

Layout.displayName = 'Layout';

export default Layout;
