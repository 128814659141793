import {TValue as TPickerValue} from '@edna/components/PeriodPicker';
import {TCurrency} from '@edna/utils/formatMoney';

import {TItemId} from 'src/constants';
import {TTariffChannelType, TTariffsTemplatesIds} from 'src/containers/Tariffs/definitions';
import {EDocumentType, TPaymentType} from 'src/containers/User';

export enum EClientStatus {
  WAITING = 'WAITING',
  ACCEPTED = 'ACCEPTED',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export enum ECountries {
  KAZ = 'KAZ',
  BLR = 'BLR',
  RUS = 'RUS',
  IDN = 'IDN',
}

export enum ELeadClientCreatingError {
  TAX_NUMBER_ALREADY_IN_USE = 'TAX_NUMBER_ALREADY_IN_USE',
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
}

export type TTariffItem = {
  id?: number;
  channelType?: TTariffChannelType;
};

export type TLeadClient = {
  id: number;
  companyName: string;
  taxNumber: string;
  ogrn?: string;
  countryCode: string;
  currencyCode?: TCurrency;
  resellerId: number;
  contactName: string;
  phone: string;
  email: string;
  responsibleManagerId: number;
  partnerId?: number | null;
  status: EClientStatus;
  inviteUrl: string;
  inviteUrlCreatedAt: string;
  createdTenantId: number;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  tariffTemplatesIds: TTariffsTemplatesIds;
  documentType: EDocumentType | null;
  paymentType: TPaymentType;
  managerResponsibleForCheckingId?: number | null;
  maxOverdraft: number;
  securityDeposit: number;
  licensePaymentTerm: number;
  termBeforeTerminationLicense: number;
  locale: string;
  newLinkRequested: true;
  activateLicenseDebt: boolean;
  documentNumber: string;
  documentDate: string;
  paymentBankId?: number | null;
  billingDisabled?: boolean;
};

export type TFormData = Partial<TLeadClient> & {
  hasPartner?: boolean;
  isChecked?: boolean;
  isAgreedOverdraft?: boolean;
  agreedOverdraft?: number;
  isDisableBillingAgreed?: boolean;
  isDocumentTypeApproved?: boolean;
  tariffs: TTariffItem[];
};

export const DEFAULT_LEAD_CLIENT = {
  companyName: '',
  contactName: '',
  countryCode: '',
  email: '',
  taxNumber: '',
  phone: '',
  isDocumentTypeApproved: false,
  documentNumber: '',
  documentDate: '',
  tariffs: [{}],
};

export enum EFormStep {
  DETAILS = 'DETAILS',
  CALCULATION_AND_LICENSES = 'CALCULATION_AND_LICENSES',
  TARIFFS = 'TARIFFS',
}

export type TFormStep = keyof typeof EFormStep;

export type TFilters = {
  filter: string;
  period: TPickerValue;
  responsibleManagerId: TItemId;
  resellerId: TItemId;
  statuses: Record<EClientStatus, boolean>;
};
