import {
  EDeliveryStatus,
  EDirection,
  EMessageSource,
  ETrafficType,
  IncomingMessageStatus,
} from './definitions';

export default {
  filter: {
    period: 'Временной интервал',
    phone: 'Быстрый поиск по номеру телефона пользователя',
    channelType: 'Канал',
    deliveryStatus: 'Статус сообщения',
    broadcasts: 'Название рассылки',
    cascades: 'Название каскада',
    trafficType: 'Тип трафика',
    direction: 'Направленность трафика',
    subjects: 'Канал отправителя',
    initiator: 'Инициатор рассылки',
    source: 'Источник отправки сообщения',
    actions: 'Взаимодействие с сообщением',
    actionsHint:
      'Фильтр применяется только к сообщениям Viber с типом контента «Кнопка-ссылка» и параметром «Переход по кнопке-ссылке».Этот параметр отображается у сообщений, если при создании рассылки выбрана настройка «Отслеживать переходы по кнопкам-ссылкам»',
    isClicked: 'Переход по кнопке-ссылке',
  },
  messagesByCriteria: 'Сообщений по критериям – {{count, number}}',
  button: {
    downloadList: 'Выгрузить список',
  },
  list: {
    head: {
      sendDateTime: 'Дата и время\nотправки',
      deliveryStatus: 'Статус\nсообщения',
      recipient: 'Получатель',
      sender: 'Отправитель',
      traffic: 'Трафик',
      inout: 'ВХ/ИСХ',
      content: 'Контент',
      source: 'Источник',
    },
    loading: 'загружаем список',
  },
  sourceHint: {
    [EMessageSource.FLOW]: '<0>Сценарий</0> — функция «Сценарии edna Pulse»',
    [EMessageSource.IMHPX]: '<0>Chat Center</0> — личный кабинет edna Chat Center',
    [EMessageSource.JIVO]: '<0>Jivo</0> — платформа Jivo',
    [EMessageSource.BROADCAST]: '<0>Личный кабинет</0> — личный кабинет edna Pulse',
    [EMessageSource.JSON_API]: '<0>API</0> — API edna Pulse',
    [EMessageSource.INTERNAL_API]: '<0>Тест</0> — функция «Протестировать рассылку»',
    [EMessageSource.BROADCAST_TEST_MESSAGE]: '<0>Тест</0> — функция «Протестировать рассылку»',
  },
  source: {
    [EMessageSource.FLOW]: 'Сценарий',
    [EMessageSource.IMHPX]: 'Chat Center',
    [EMessageSource.JIVO]: 'Jivo',
    [EMessageSource.BROADCAST]: 'Личный кабинет',
    [EMessageSource.JSON_API]: 'API',
    [EMessageSource.INTERNAL_API]: 'Тест',
    [EMessageSource.BROADCAST_TEST_MESSAGE]: 'Тест',
  },
  status: {
    [EDeliveryStatus.ENQUEUED]: 'Ожидает',
    [EDeliveryStatus.SENT]: 'Отправлено',
    [EDeliveryStatus.FAILED]: 'Не отправлено',
    [EDeliveryStatus.ACCEPTED]: 'Не отправлено',
    [EDeliveryStatus.INVALID]: 'Не отправлено',
    [EDeliveryStatus.DELIVERED]: 'Доставлено',
    [EDeliveryStatus.CANCELLED]: 'Отменено',
    [EDeliveryStatus.UNDELIVERED]: 'Не доставлено',
    [EDeliveryStatus.READ]: 'Прочитано',
    [EDeliveryStatus.EXPIRED]: 'Просрочено',
    [EDeliveryStatus.BLOCKED]: 'Заблокировано',
    [EDeliveryStatus.SUSPENDED]: 'Приостановлено',
    [IncomingMessageStatus.RECEIVED]: 'Получено',
  },
  trafficType: {
    [ETrafficType.AD]: 'Рекламный',
    [ETrafficType.MULTINAMING]: 'Рекламный',
    [ETrafficType.SERVICE]: 'Сервисный',
    [ETrafficType.HSM]: 'HSM',
    [ETrafficType.CHAT]: 'Чат',
  },
  trafficTypeShort: {
    [ETrafficType.AD]: 'Рекл',
    [ETrafficType.SERVICE]: 'Серв',
    [ETrafficType.HSM]: 'HSM',
    [ETrafficType.CHAT]: 'Чат',
  },
  direction: {
    [EDirection.IN]: 'Входящий',
    [EDirection.OUT]: 'Исходящий',
  },
  directionShort: {
    [EDirection.IN]: 'ВХ',
    [EDirection.OUT]: 'ИСХ',
  },
  content: {
    headerText: 'Заголовок сообщения',
    operator: 'Оператор получателя',
    text: 'Текст сообщения',
    footerText: 'Подпись сообщения',
    securityAdvice: 'Рекомендации по безопасности',
    codeExpirationMinutes: '<0>Срок действия пароля (мин):</0> {{minutes}}',
    image: 'Изображение',
    video: 'Видео',
    audio: 'Аудио',
    document: 'Файл',
    location: 'Геометка',
    name: 'Название',
    link: 'Ссылка',
    links: 'Ссылки',
    buttonText: 'Текст кнопки:',
    value: 'Значение',
    coordinates: 'Координаты',
    error: 'Ошибка',
    broadcast: 'Рассылка',
    cascade: 'Каскад',
    segments: 'Сегментов',
    story: 'История',
    menu: 'Меню',
    buttons: 'Кнопки',
    contact: 'Контакт',
    firstName: 'Имя',
    lastName: 'Фамилия',
    phone: 'Телефон',
    subtitle: 'Подзаголовок',
    sender: 'Отправитель',
    recipientUID: 'UID получателя',
    deviceAddress: 'deviceAddress получателя',
    deviceModel: 'Модель устройства получателя',
    referral: 'Referral',
    locationButtonText: 'Место',
    linkClick: 'Переход по кнопке-ссылке',
    hasLinkClick: 'Есть переход',
    noLinkClick: 'Нет перехода',
    initiator: 'Инициатор',
  },
  noDataWithFilters: {
    title: 'Увы, мы ничего не нашли',
    text: 'Сообщений по выбранным фильтрам нет',
    hint: 'Проверьте, возможно вы ошиблись где-то в настройках или забыли поставить правильный временной интервал',
    button: 'Попробовать еще раз',
  },
  noData: {
    title: 'Здесь пока пусто',
    text: 'После того как вы запустите первую рассылку, здесь появятся данные об отправленных сообщениях. А до тех пор мы можем только предложить вам попробовать создать рассылку',
    button: 'В рассылки',
  },
  referral: {
    // TODO нужен перевод на русский?
    body: 'Body',
    headline: 'Headline',
    sourceId: 'SourceID',
    sourceType: 'SourceType',
    sourceUrl: 'SourceURL',
  },
  orderProduct: {
    product: 'Message Business',
    catalogId: 'catalog_id:',
    productId: 'product_retailer_id:',
    order: 'Placing an Order',
  },
};
