import {EPaymentCorrectionType} from './PaymentsTab';
import {ECorrectionsTab} from './definitions';

export default {
  tabs: {
    [ECorrectionsTab.PAYMENTS]: 'Списания и оплаты',
    [ECorrectionsTab.LICENSES]: 'Лицензии',
    [ECorrectionsTab.TARIFFS]: 'Изменения тарифов',
  },
  payments: {
    storno: 'Сторнировать операцию',
    selectType: 'Выберите тип операции',
    notFound: 'Операция не найдена',
    type: {
      [EPaymentCorrectionType.STORNO]: 'Сторнировать операцию',
      [EPaymentCorrectionType.CANCEL_STORNO]: 'Отменить сторно',
    },
    enterEventId: 'Укажите ID операции',
    searchResults: 'Результаты поиска',
    companyName: 'Company name',
    paymentType: 'Payment type',
    licensePayment: 'License payment',
    amount: 'Amount',
    currency: 'Currency',
    isSearchCorrectMessage: 'Обязательно убедитесь в корректности запроса по таблицам',
    billedEvent: 'Billed event',
    debtTransaction: 'Debt transaction',
    transaction: 'Transaction',
    id: 'ID',
    originalEventId: 'Original event ID',
    originalEventType: 'Original event Type',
    confirmation: 'Confirmation',
    createdAt: 'Created at',
    eventId: 'Event ID',
    tenantId: 'ID компании',
    reason: 'Reason',
    reasonCode: 'Reason code',
    balanceId: 'Balance ID',
    openingBalance: 'Opening balance',
    state: 'state',
    updated: 'Updated',
    search: 'Search',
    comment: 'Comment',
    button: {
      [EPaymentCorrectionType.STORNO]: 'Сторнировать',
      [EPaymentCorrectionType.CANCEL_STORNO]: 'Отменить сторно',
    },
    confirmModal: {
      title: {
        [EPaymentCorrectionType.STORNO]: 'Уверены? Мы отменяем списание',
        [EPaymentCorrectionType.CANCEL_STORNO]: 'Уверены? Мы отменяем сторно',
      },
      message: 'Если это ошибочная операция, то исправить это будет невозможно',
      subtitle: 'Проверьте данные:',
      button: 'Да, продолжить',
    },
    successModal: {
      title: 'Все хорошо!',
      message: 'Запрос принят к исполнению',
    },
  },
  licenses: {
    channelType: 'Channel type',
    licenseID: 'License ID',
    month: 'Month',
    year: 'Year',
    eventTable: 'license_billed_event_tab (for the specified period)',
    notFound: 'License not found',
    search: 'Search',
    notification: 'License renewal operation started',
    tr: {
      id: 'ID',
      channel: 'Channel',
      price: 'Price',
      currency: 'Currency',
      paymentType: 'Payment type',
      periodStartedAt: 'Period started at',
      periodEndedAt: 'Period ended at',
      transactionId: 'Transaction ID',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      documentId: 'Document ID',
      tenantId: 'Tenant ID',
      subjectId: 'SubjectID',
      periodMonths: 'Period months ',
      name: 'Name',
      description: 'Description',
      licenseStatus: 'License status',
      activatedAt: 'Activated at',
      expiredAt: 'Expired at',
      cancelledAt: 'Cancelled at',
      activeUntil: 'Active until',
      paidUntil: 'Paid until',
      temporaryblockedAt: 'Temporary blocked at',
      paymentCode: 'Payment code',
    },
  },
};
