import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';
import {TSize} from '@edna/components/types';

type TProgressBarLayoutProps = {
  width?: string;
  size?: TSize;
};

type TProgressBarProps = {
  percent?: number;
};

export const ProgressBar = styled.div<TProgressBarProps>`
  ${mixins.flexAlign('center', 'center')}
  position: relative;
  overflow: hidden;
  transition: width 2s ease-out;
  ${({theme, percent = 0}) => css`
    ${mixins.wh(percent !== 0 && percent < 10 ? '40px' : `${percent}%`, '100%')}
    background: linear-gradient(90deg, ${theme.palette.green400}, ${theme.palette
      .brandAccent600}, ${theme.palette.green400});
    border-radius: ${theme.borderRadius.br4};
    &::before {
      opacity: ${percent === 0 ? 0 : 1};
      transition: opacity 1s ease-in;
      content: '${percent > 0 ? `${percent} %` : ''}';
      z-index: ${theme.zIndex.dropdown};
    }
  `};
`;

export const ProgressBarLayout = styled.div<TProgressBarLayoutProps>`
  ${mixins.flexAlign('start', 'center')}
  ${({theme, width = '100%', size}) => css`
    ${size === 'S'
      ? css`
          ${mixins.wh(width, theme.spacing.s4)}
          border: 1px solid ${theme.palette.grey100};
          font-size: 10px;
          line-height: 10px;
        `
      : css`
          ${mixins.wh(width, '30px')}
          border: ${theme.spacing.s05} solid ${theme.palette.grey100};
          font-size: 16px;
          line-height: 20px;
        `};
    max-width: ${width};
    padding: 1px;
    border-radius: ${theme.borderRadius.br4};
    background-color: ${theme.palette.grey10};
    color: ${theme.palette.white};
    box-shadow: ${theme.boxShadow.default};
  `};
`;
