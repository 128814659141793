import {EResellerIds, useGetCurrentUserQuery} from 'src/containers/User';

enum ELinkType {
  DOCS = 'DOCS',
  SUPPORT_EMAIL = 'SUPPORT_EMAIL',
  PULSE_API = 'PULSE_API',
  FBM_VERIFICATION = 'FBM_VERIFICATION',
  IOS_URGENCY_LEVEL = 'IOS_URGENCY_LEVEL',
  INTEGRATION_PUSH = 'INTEGRATION_PUSH',
  INTEGRATION_WA = 'INTEGRATION_WA',
  CHANNEL_QUALITY_WA = 'CHANNEL_QUALITY_WA',
  FB_PUBLIC_TERMS = 'FB_PUBLIC_TERMS',
  RELEASE = 'RELEASE',
  ERROR_CODES = 'ERROR_CODES',
}

type TLinks = Record<ELinkType, string>;

const LINKS_OSK: TLinks = {
  [ELinkType.DOCS]: 'https://docs.edna.ru/',
  [ELinkType.SUPPORT_EMAIL]: 'support@edna.ru',
  [ELinkType.PULSE_API]: 'https://docs.edna.ru/kb/obshhaya-informaciya/',
  [ELinkType.FBM_VERIFICATION]: 'https://docs.edna.ru/kb/fbm-verification/',
  [ELinkType.IOS_URGENCY_LEVEL]:
    'https://docs.edna.ru/kb/chto-takoe-uroven-preryvaniya-uvedomlenij-v-ios/',
  [ELinkType.INTEGRATION_PUSH]: 'https://docs.edna.ru/kb/adding-push-channels/',
  [ELinkType.INTEGRATION_WA]: 'https://docs.edna.ru/kbtopic/api-ru',
  [ELinkType.CHANNEL_QUALITY_WA]: 'https://docs.edna.ru/kb/wa-how-to-send-hsm/',
  [ELinkType.FB_PUBLIC_TERMS]: 'https://edna.ru/legal/other/whatsapp-connection-conditions',
  [ELinkType.RELEASE]: 'https://docs.edna.ru/kb/edna-pulse-latest-version',
  [ELinkType.ERROR_CODES]: 'https://docs.edna.ru/kb/api-edna-pulse-error-codes#receive-statuses',
};

const LINKS_MFM: TLinks = {
  [ELinkType.DOCS]: 'https://docs.edna.io/',
  [ELinkType.SUPPORT_EMAIL]: 'support@edna.io',
  [ELinkType.PULSE_API]: 'https://docs.edna.io/kb/general-information/',
  [ELinkType.FBM_VERIFICATION]:
    'https://docs.edna.io/kb/verifying-your-business-in-facebook-business-manager-fbm/',
  [ELinkType.IOS_URGENCY_LEVEL]: 'https://docs.edna.io/kb/ios-interruption-levels/',
  [ELinkType.INTEGRATION_PUSH]: 'https://docs.edna.io/kb/adding-push-channels/',
  [ELinkType.INTEGRATION_WA]: 'https://docs.edna.io/kbtopic/api-en/',
  [ELinkType.CHANNEL_QUALITY_WA]: 'https://docs.edna.io/kb/keeping-high-account-rate/',
  [ELinkType.FB_PUBLIC_TERMS]: 'https://edna.io/company/legal/whatsapp-connection-conditions',
  [ELinkType.RELEASE]: 'https://docs.edna.io/kb/edna-pulse-latest-version',
  [ELinkType.ERROR_CODES]: 'https://docs.edna.io/kb/api-edna-pulse-error-codes#receive-statuses',
};

const LINKS_PT: TLinks = {
  [ELinkType.DOCS]: LINKS_MFM[ELinkType.DOCS],
  [ELinkType.SUPPORT_EMAIL]: LINKS_MFM[ELinkType.SUPPORT_EMAIL],
  [ELinkType.PULSE_API]: LINKS_MFM[ELinkType.PULSE_API],
  [ELinkType.FBM_VERIFICATION]: LINKS_MFM[ELinkType.FBM_VERIFICATION],
  [ELinkType.IOS_URGENCY_LEVEL]: LINKS_MFM[ELinkType.IOS_URGENCY_LEVEL],
  [ELinkType.INTEGRATION_PUSH]: LINKS_MFM[ELinkType.INTEGRATION_PUSH],
  [ELinkType.INTEGRATION_WA]: LINKS_MFM[ELinkType.INTEGRATION_WA],
  [ELinkType.CHANNEL_QUALITY_WA]: LINKS_MFM[ELinkType.CHANNEL_QUALITY_WA],
  [ELinkType.FB_PUBLIC_TERMS]: 'https://edna.io/company/legal/whatsapp-connection-conditions-id',
  [ELinkType.RELEASE]: LINKS_MFM[ELinkType.RELEASE],
  [ELinkType.ERROR_CODES]: LINKS_MFM[ELinkType.ERROR_CODES],
};

const LINKS_BLR: TLinks = {
  [ELinkType.DOCS]: 'https://docs.edna.by/',
  [ELinkType.SUPPORT_EMAIL]: 'support@edna.by',
  [ELinkType.PULSE_API]: 'https://docs.edna.by/kb/obshhaya-informaciya/',
  [ELinkType.FBM_VERIFICATION]: 'https://docs.edna.by/kb/fbm-verification/',
  [ELinkType.IOS_URGENCY_LEVEL]:
    'https://docs.edna.by/kb/chto-takoe-uroven-preryvaniya-uvedomlenij-v-ios/',
  [ELinkType.INTEGRATION_PUSH]: 'https://docs.edna.by/kb/adding-push-channels/',
  [ELinkType.INTEGRATION_WA]: LINKS_OSK[ELinkType.INTEGRATION_WA],
  [ELinkType.CHANNEL_QUALITY_WA]: LINKS_OSK[ELinkType.CHANNEL_QUALITY_WA],
  [ELinkType.FB_PUBLIC_TERMS]: LINKS_OSK[ELinkType.FB_PUBLIC_TERMS],
  [ELinkType.RELEASE]: 'https://docs.edna.by/kb/edna-pulse-latest-version',
  [ELinkType.ERROR_CODES]: 'https://docs.edna.by/kb/api-edna-pulse-error-codes#receive-statuses',
};

const LINKS_KZ: TLinks = {
  [ELinkType.DOCS]: 'https://docs.edna.kz/',
  [ELinkType.SUPPORT_EMAIL]: 'support@edna.kz',
  [ELinkType.PULSE_API]: 'https://docs.edna.kz/kb/obshhaya-informaciya/',
  [ELinkType.FBM_VERIFICATION]: 'https://docs.edna.kz/kb/fbm-verification/',
  [ELinkType.IOS_URGENCY_LEVEL]:
    'https://docs.edna.kz/kb/chto-takoe-uroven-preryvaniya-uvedomlenij-v-ios/',
  [ELinkType.INTEGRATION_PUSH]: 'https://docs.edna.kz/kb/adding-push-channels/',
  [ELinkType.INTEGRATION_WA]: LINKS_OSK[ELinkType.INTEGRATION_WA],
  [ELinkType.CHANNEL_QUALITY_WA]: 'https://docs.edna.kz/kb/wa-how-to-send-hsm/ ',
  [ELinkType.FB_PUBLIC_TERMS]:
    'https://edna.kz/legal/prepaid/usloviya-podkljucheniya-kanala-whatsapp/',
  [ELinkType.RELEASE]: 'https://docs.edna.kz/kb/edna-pulse-latest-version',
  [ELinkType.ERROR_CODES]: 'https://docs.edna.kz/kb/api-edna-pulse-error-codes#receive-statuses',
};

const LINKS_BY_RESELLER: Record<EResellerIds, TLinks> = {
  [EResellerIds.OSK_ID]: LINKS_OSK,
  [EResellerIds.MFM_ID]: LINKS_MFM,
  [EResellerIds.PT_ID]: LINKS_PT,
  [EResellerIds.KZ_ID]: LINKS_KZ,
  [EResellerIds.BLR_FIRST_ID]: LINKS_BLR,
  [EResellerIds.BLR_SECOND_ID]: LINKS_BLR,
};

const useEdnaLinks = (resellerId?: EResellerIds): TLinks => {
  const {data: user} = useGetCurrentUserQuery(undefined, {skip: !!resellerId});

  const targetResellerId = resellerId ?? user?.resellerId;

  if (targetResellerId === undefined) {
    return {} as TLinks;
  }

  return LINKS_BY_RESELLER[targetResellerId] ?? {};
};

export {useEdnaLinks};
