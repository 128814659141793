import {EChannelType} from 'src/constants';

import {EStatus} from './definitions';

export default {
  cascade: 'каскад',
  searchPlaceholder: 'Быстрый поиск по названию или ID каскада',
  filters: {
    channelLabel: 'Канал',
    cascades: 'Каскадов',
    status: 'Статус каскада',
    subjectName: 'Используемые каналы в каскаде',
  },
  title: {
    new: 'Новый каскад',
  },
  status: {
    [EStatus.ACTIVE]: 'Активен',
    [EStatus.DISABLED]: 'Отключен',
  },
  th: {
    cascadeId: 'ID каскада',
    name: 'Название каскада',
    status: 'Статус',
    channels: 'Каналы',
  },
  add: 'Подключить каскад',
  editForm: {
    name: 'Название',
    cascadeId: 'ID каскада',
    step: 'шаг',
    cascadeSteps: 'Шаги каскада',
    send: 'Отправить сообщение в канале',
    sendCondition: 'если клиенты не получили сообщение на шаге {{step}} в течение',
    lastStep: 'Это последний шаг каскада, время доставки для него составляет {{time}}',
    sendDuration: {
      [EChannelType.WHATSAPP]: 'WhatsApp будет доставлять ваше сообщение 30 суток',
      [EChannelType.SMS]: 'SMS-операторы будут доставлять ваши сообщения до 3 дней',
    },
    ttlTitle: 'Подробнее о времени доставки сообщений в каналах',
    ttlInfo:
      'Если получатель не получит сообщение в канале в течение установленного вами времени, edna Pulse отправит ему сообщение на следующем шаге каскада.\n' +
      'edna Pulse останаливает попытки доставки сообщения в канале при переходе на очередной шаг каскада.\n' +
      'Для последнего шага в каскаде используется максимально возможное время доставки сообщения в выбранном канале.',
    ttlDuration: 'Время доставки сообщений в каналах:',
    channelsTtl: {
      [EChannelType.WHATSAPP]: 'до 30 суток',
      [EChannelType.VIBER]: 'от 30 секунд до 14 суток',
      [EChannelType.VK_NOTIFY]: 'от 15 секунд до 24 часов',
      [EChannelType.OK_NOTIFY]: 'от 15 секунд до 24 часов',
      [EChannelType.PUSH]: 'до 24 часов',
      [EChannelType.SMS]: 'до 3 суток',
    },
    maxChannelsTtl: {
      [EChannelType.WHATSAPP]: '30 суток',
      [EChannelType.VIBER]: '14 суток',
      [EChannelType.VK_NOTIFY]: '24 часа',
      [EChannelType.OK_NOTIFY]: '24 часа',
      [EChannelType.PUSH]: '24 часа',
      [EChannelType.SMS]: '3 суток',
    },
  },
  deleteConfirm:
    'Вы уверены, что хотите удалить каскад "{{name}}", без возможности восстановления?',
  empty: {
    toChannels: 'В каналы',
    title: 'Заждались вас',
    subtitle:
      'Пока никто не решился настроить каскад для отправки сообщений, хотя это совсем не сложно. Единственное важное условие – у вас должен быть зарегистрирован хотя бы один канал',
  },
  modal: {
    saveWarning: {
      title: 'Будьте внимательны!',
      message: `
  После сохранения каскада вы не сможете его редактировать. Так при просмотре и/или редактировании рассылок не пропадет важный контент
  `,
    },
  },
};
