import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';

import {EStorageKeys} from 'src/constants';

import {subscribersEndpoints} from './api';
import {TFilters} from './definitions';

type TState = {
  file: File | null;
  filters: TFilters;
  chosenTagsInTable: number[];
};

const initialState = (): TState => {
  const rememberedFilters = sessionStorage.getItem(EStorageKeys.SUBSCRIBERS_FILTERS);

  return {
    filters: rememberedFilters ? JSON.parse(rememberedFilters) : {},
    file: null,
    chosenTagsInTable: [],
  };
};

const slice = createSlice({
  name: 'subscribers',
  initialState,
  reducers: {
    resetSubscribersFile(state) {
      state.file = null;
    },
    setFilters(state, action: PayloadAction<TFilters>) {
      state.filters = action.payload;
    },

    setChosenTagsInTable: (state, {payload}: PayloadAction<number[]>) => ({
      ...state,
      chosenTagsInTable: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(subscribersEndpoints.uploadSubscribers.matchFulfilled, (state, {meta}) => {
      state.file = meta.arg.originalArgs;
    });
  },
});

export const selectSubscribersFile = (state: TRootState) => state.subscribers.file;
export const {
  resetSubscribersFile,
  setFilters: setFiltersAction,
  setChosenTagsInTable,
} = slice.actions;
export const selectFilters = (state: TRootState) => state.subscribers.filters as TFilters;

const subscriberSelect = (state: TRootState) => state;

export const getChosenTagsInTable = () =>
  createSelector(subscriberSelect, (state) => state.subscribers.chosenTagsInTable);

export default slice.reducer;
