import {EContentType, EOperatorName} from 'src/constants';

export default {
  title: 'Edna',
  support: 'Support',
  passwordValid: 'Valid password',
  button: {
    yes: 'Yes',
    no: 'No',
    back: 'Back',
    ok: 'Ok',
    create: 'Create',
    new: 'New',
    download: 'Download',
    upload: 'Upload',
    next: 'Next',
    save: 'Save',
    saveDraft: 'Save as draft',
    cancel: 'Cancel',
    cancel2: 'Cancel',
    reset: 'Reset',
    resetAll: 'Reset all',
    apply: 'Apply',
    accept: 'Accept',
    check: 'Check',
    close: 'Close',
    copy: 'Copy',
    view: 'View',
    edit: 'Edit',
    delete: 'Remove',
    actions: 'Actions',
    submit: 'Send',
    add: 'Add',
    continue: 'Continue',
    confirm: 'Confirm',
    register: 'Submit',
    toPlan: 'Plan',
    toMain: 'To the main',
    toList: 'To the list',
    toProfile: 'Back to Profile',
    goToBack: 'Go back and check',
    update: 'Update',
    change: 'Change',
    understand: 'Understand',
    search: 'Search',
  },
  operator: {
    [EOperatorName.RuMts]: 'MTS',
    [EOperatorName.RuBeeline]: 'Beeline',
    [EOperatorName.RuTele2]: 'Tele2',
    [EOperatorName.RuMegafon]: 'Megafon',
    [EOperatorName.RuYota]: 'Yota',
    [EOperatorName.RuVainahtelecom]: 'Vainah Telecom',
    [EOperatorName.RuMotiv]: 'Motiv',
    [EOperatorName.RuKtelecom]: 'K-Telecom',
    [EOperatorName.RuKtktelecom]: 'KTK Telecom',
    [EOperatorName.RuSbertelecom]: 'Sberbank-Telecom',
    [EOperatorName.RuDefault]: 'Other operators',
    [EOperatorName.IoDefault]: 'International operators',
  },
  contentType: {
    [EContentType.TEXT]: 'Text',
    [EContentType.IMAGE]: 'Image',
    [EContentType.BUTTON]: 'Message with button',
    [EContentType.DOCUMENT]: 'File',
    [EContentType.VIDEO]: 'Video',
  },
  time: {
    seconds_one: 'second',
    seconds_other: 'seconds',
    minutes_one: 'minute',
    minutes_other: 'minutes',
    hours_one: 'hour',
    hours_other: 'hours',
    days_one: 'day',
    days_other: 'days',
  },
  time_alternate: {
    seconds_one: 'second',
    seconds_other: 'seconds',
    minutes_one: 'minute',
    minutes_other: 'minutes',
    hours_one: 'hour',
    hours_other: 'hours',
    days_one: 'day',
    days_other: 'days',
  },
  gender: {
    male: 'Male',
    female: 'Female',
  },
  boolean: {
    true: 'True',
    false: 'False',
  },
  loadingError: 'Error loading file',
  loadingByUrlError: 'Error loading file',
  loadingByUrlSuccess: 'File uploaded successfully',
  step: 'Step {{count}}',
  successDownload: 'File successfully downloaded',
  errorDownload: 'Error download',
  changeHistory: 'Change history',
  weekdays: {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
  },
  attachAnotherFile: 'Upload another file',
  attachFile: 'Upload file',
  more: 'More',
  monthsKeysByIndex: {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  },
};
