import {AnyAction} from '@reduxjs/toolkit';

import {SILENT_POLLING_API_URL, TMeta, unavailableStatusSet} from './definitions';

const isMatchQueryMeta =
  (meta: TMeta) =>
  (action: AnyAction): action is AnyAction =>
    Object.entries(meta).every(([key, value]) => action.meta?.baseQueryMeta?.[key] === value);

const isMatchBackendUnavailableQuery = (action: AnyAction): action is AnyAction => {
  if (!action.meta?.baseQueryMeta?.isBackendError) {
    return false;
  }

  return (
    unavailableStatusSet.has(action.payload.status) &&
    action.payload.config?.url !== SILENT_POLLING_API_URL
  );
};

export {isMatchQueryMeta, isMatchBackendUnavailableQuery};
