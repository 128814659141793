import {formatNumber} from '@edna/utils/formatMoney';

import {EContentType} from 'src/constants';

import {EMultiApproveStatus, ESmsCategory, ESmsStep} from './SMSForm/definitions';
import {EViberCategory} from './ViberForm/definitions';
import {
  MAX_ATTACHMENTS_LENGTH,
  MAX_BUTTONS_LENGTH,
  MAX_FILE_DIMENSIONS_SUM,
  MAX_FILE_SIZE_MB,
} from './VkNotifyForm/utils';
import {
  BUTTON_NAME_MAX_LENGTH,
  EAuthFormStep,
  EOldWhatsAppCategory,
  EOperatorFormStep,
  ETemplateButtonOtpType,
  EUserFormStep,
  EWhatsAppCategory,
} from './WhatsAppForm/definitions';
import {
  EApproveStatus,
  EListAction,
  EMessageMatcherButtonColor,
  EMessageMatcherButtonTypes,
  EType,
  TEMPLATE_NAME_MAX_LENGTH,
  TEMPLATE_NAME_MIN_LENGTH,
} from './definitions';

export default {
  messageMatcher: 'template',
  noMessageMatcher: 'No template',
  noOperatorMessageMatcher: 'You do not have any registered operator template',
  type: {
    [EType.OPERATOR]: {
      title: 'Operator',
      description:
        'This is a text template for an operator (service provider) that includes the required syntax and regular expressions. After you register such a template with the operator, you can create custom templates based on it which will allow you to send messages cheaper',
      short: 'O',
      tooltip:
        'An <strong>operator template</strong> is used to register the content of the message with the channel operator (mobile operators, Viber, Meta). A registered operator template is mandatory for creating custom templates. Registered operator templates allow you to get profitable prices for your broadcasts',
    },
    [EType.USER]: {
      title: 'Custom',
      description:
        'This is a text template based on an already registered operator template where you need to enter the required text and variables. Using this template will allow you to send your messages cheaper',
      short: 'C',
      tooltip:
        'A <strong>user template</strong> is created based on a registered operator template, but contains already added variable values or the final text on a specific broadcast topic',
    },
    [EType.CUSTOM]: {
      title: 'Free',
      description:
        'This is a template that you create from scratch. It has no restrictions: it can contain any desired content that is allowed for this channel',
      short: 'F',
      tooltip:
        'A <strong>custom template</strong> is a ready pre-created message you can use in your broadcasts',
    },
  },
  status: {
    [EApproveStatus.APPROVED]: 'Active',
    [EApproveStatus.REJECTED]: 'Rejected',
    [EApproveStatus.PENDING]: 'Pending registration',
    [EApproveStatus.NOT_SENT]: 'Draft',
    [EApproveStatus.PAUSED]: 'Paused',
    [EApproveStatus.DISABLED]: 'Disabled',
    [EApproveStatus.ARCHIVED]: 'Archived',
  },
  multiStatus: {
    [EMultiApproveStatus.FULLY_APPROVED]: 'Active',
    [EMultiApproveStatus.PARTIALLY_APPROVED]: 'Partially active',
    [EMultiApproveStatus.REJECTED]: 'Rejected',
    [EMultiApproveStatus.PENDING]: 'Pending registration',
    [EMultiApproveStatus.NOT_SENT]: 'Draft',
  },
  statusDescription: {
    [EApproveStatus.PAUSED]:
      'This template was paused by WhatsApp due to large number of user complaints. While it is paused for 3-6 hours, it cannot be used for broadcasts. After that the template status will either be changed to ACTIVE or DISABLED.',
    [EApproveStatus.DISABLED]:
      'This template was disabled by WhatsApp due to large number of user complaints. To restore the template you need to appeal. Ask your manager to assist you',
  },
  list: {
    th: {
      name: 'Template',
      type: 'Type',
      status: 'Status',
      channel: 'Channel',
      edited: 'Last edited on',
    },
    actions: {
      [EListAction.CREATE_BASED_ON]: 'Create based on',
      [EListAction.DUPLICATE]: 'Duplicate',
    },
    content: 'The template contains: {{types}}',
    contentType: {
      [EContentType.IMAGE]: 'image',
      [EContentType.BUTTON]: 'button',
      [EContentType.DOCUMENT]: 'file',
    },
  },
  deleteConfirm: {
    title: 'Are you sure?',
    operatorBody:
      'After deleting the template, it will no longer be available for further work. If user templates are linked to this template, they will be unavailable as well.',
    userBody: 'After deleting the template, it will no longer be available for further work.',
  },
  deleteDenied: {
    title: 'Additional action required.',
    body1: 'We cannot remove this template now as it is used in a scheduled broadcasts:',
    body2: 'You can only remove the template if you cancel these broadcasts.',
  },
  view: {
    sms: {
      operatorsLabel: 'Статус шаблона у операторов',
      operatorsLabelExtended:
        'The template is available for broadcasts to the following operators:',
      availableApproved: 'Available now',
      availablePending:
        'Available only after registration of the operator template. Before that, messages are charged as advertising',
      availableRejected: 'Unavailable due to rejection',
    },
  },
  editForm: {
    sms: {
      type: {
        [EType.OPERATOR]: {
          description:
            'Templates of this type should be registered with the operator. The template can contain static text for an SMS message and a set of regular expressions. By default, any SMS message without an agreed operator template is charged as advertising.',
        },
        [EType.USER]: {
          description:
            'The custom template is configured based on the active operator template. You can add text or variables to it instead of regular expressions. The custom template should match the operator template text, including the static and variable parts. Otherwise, the messages are charged as advertising.',
        },
        [EType.CUSTOM]: {
          description:
            'The free template can have any content allowed for this channel. By default, the template is charged as advertising.',
        },
      },
      steps: {
        [ESmsStep.DATA]: 'General',
        [ESmsStep.CONTENT]: 'Text',
      },
      operators: {
        label: 'The mobile operators for which the template is registered.',
        hint: 'Select the operators that support the specified template category.',
        title: 'The template available for broadcasting by operators:',
        approved: 'Available after creation',
        pending:
          'Available only after registration of the operator template. Before that, messages are charged as advertising',
        rejected: 'Unavailable due to rejection',
      },
      hints: {
        [EType.OPERATOR]:
          'Add text to the template. Use regular expressions to automatically insert variables or text into the template.',
        [EType.USER]:
          "You can't change the template text. Add the required variables or enter text in the specified places.",
        [EType.CUSTOM]: 'Use any text and add variables.',
      },
      viewHints: {
        [EType.CUSTOM]: "You can't change the template text.",
        [EType.OPERATOR]:
          'The operator will register this text and the order of the regular expressions in the template as an example of your outgoing message. Regular expressions in a custom template or broadcast can be replaced with variables or the required text.',
      },
      subjectIds: 'The name of the sender to whom the template will be registered.',
      nameHint: 'You can use any kind of letters and symbols.',
      parentId: 'Create from template',
      viewExamples: 'Посмотреть примеры шаблонов',
      checkText: 'Проверка примера текста на соотвествие шаблону',
      checkHint:
        'Вставьте пример текста, который вы будете отправлять своим пользователям по этому шаблону',
      category: 'Template category',
      operatorsRequired: 'Select at least one operator.',
      emptyOperators: 'There are no operators available that support the specified category.',
      chooseCategory: 'Choose category',
      categoryHint:
        "The operator template category shows the type of traffic for your SMS messages. You can't change the template category after registration. Learn more about the template categories.",
      categories: {
        [ESmsCategory.SERVICE]: {
          title: 'Service',
          description:
            'Utility messages are used to inform customers about the status of providing services. See more.',
        },
        [ESmsCategory.AUTHORISATION]: {
          title: 'Authentication',
          description:
            'Authentication messages are used to inform customers about the authorization or verification process, to confirm actions, or to provide consent for the service. See more.',
        },
        [ESmsCategory.TEMPLATE_ADV]: {
          title: 'Advertising template',
          description:
            'Advertising messages are used to draw attention to and promote products or services. Templates can contain Cyrillic characters only. See more.',
        },
      },
    },
    whatsApp: {
      steps: {
        [EOperatorFormStep.DETAILS]: 'General',
        [EOperatorFormStep.TEXT_CONTENT]: 'Text',
        [EOperatorFormStep.HEADER_AND_SIGNATURE]: 'Header and footer',
        [EOperatorFormStep.BUTTONS]: 'Interaction',
        [EUserFormStep.USER_CONTENT]: 'Content',
        [EAuthFormStep.AUTH_CONTENT]: 'Content',
      },
      dynamicUrl: 'Dynamic URL',
      nameHint:
        'Enter a name consisting of letters, digits, and underscores (_). Do not use other characters or spaces.',
      subjectIds: 'Register to this sender name (WhatsApp account) ',
      subjectIdsView: 'Senders that can use this template:',
      subjectIdsError: 'WhatsApp Business Account not found',
      subjectIdsHint:
        'Facebook will register this template to your WhatsApp Business Account. When registered, the template will be available to all senders linked to this WABA:',
      subjectIdsDescription:
        'You can’t change the sender name after a template is registered. Custom templates based on this operator template will be available only for the sender that you select here.',
      categoryHint: 'You can’t change the category of a template after the template is registered',
      languageHint:
        'Select a language that matches the language of the template text. If the languages don’t match, the template will be rejected.',
      language: 'Language',
      category: 'Category',
      [EType.OPERATOR]: {
        description:
          'You must register this template with the operator. To broadcast messages, create personalized custom templates based on a registered operator template.',
      },
      [EType.USER]: {
        description:
          'Set up a custom template based on a registered operator template. To create custom templates, you must have at least one registered operator template.',
      },
      textHint:
        'You must enter some text for your template. Template text can contain placeholders. In a custom template, you must replace placeholders with text or variables. If a placeholder is located before or after the text, you must provide an example for it. You will be prompted for the examples after completing the fourth step of template creation',
      viewTextHint:
        'The text and order of character strings are registered by the operator like template of an outgoing message. In a custom template you can substitute character strings by specific variables or required text and use this custom template for mailings to your clients.',
      headerType: 'Header',
      authTextHint_1: 'Standard authorization template text can’t be changed.',
      authTextHint_2: ' You can replace only the parameter.',
      hasHeaderHint:
        'Add a text, image, video, or document as a header. You can’t change the type of header after the template is registered. If you select an attachment for your header (an image, video, or document), you will be prompted to attach an example after completing the fourth step of template creation.',
      footer: 'Signature',
      header: 'Header text',
      addFooterHint:
        'You can’t change your signature after the template is registered. Maximum signature length is 60 characters.',
      addFooter: 'Add a message signature',
      footerText: 'Message signature',
      addButtons: 'Buttons',
      authButtonsTitle: 'Template buttons',
      userButtonsHint: 'The template is registered with the following buttons',
      authButtonsHint:
        'An authentication template contains one mandatory OTP copy button. After the template is registered, you won’t be able to change button parameters that you set up here.',
      addButtonsHint:
        'You can add up to 10 buttons of different types to a message: multiple chat buttons, two link buttons and a call button. The buttons are divided into two sections: "Quick reply" (chat buttons) and "Call to action" (link and call buttons). Once the template is registered, the button text cannot be changed.',
      contentType: {
        [EContentType.TEXT]: 'Text header',
        [EContentType.IMAGE]: 'Image header',
        [EContentType.DOCUMENT]: 'File header',
        [EContentType.VIDEO]: 'Video header',
      },
      button: 'Button',
      buttonText: `Button text (maximum ${BUTTON_NAME_MAX_LENGTH} characters)`,
      phoneLabel: 'Phone number',
      buttonPayload: 'Button code, payload (maximum 250 characters)',
      buttonPayloadHint:
        'This code is be displayed as an incoming message in the Messages report, if a client responded by clicking this button',
      linkButtonMessage: `The button URL can be both static (fixed) and dynamic. If it is a dynamic URL, it must have two parts. The first one is a fixed (invariable) part (for example, a section of a website, like https://example.com/marketing/). The second, final part of the URL, can change for each broadcast created based on this template (for example, https://example.com/marketing/<b>action234</b> or https://example.com/marketing/<b>gift-1000</b>).  <br/>While setting up a broadcast, you can make a dynamic URL individual for each recepient of the broadcast if you add the required variable at the end of the URL. To make the URL in the template a dynamic one, select "Make the button URL dynamic."`,
      phoneButtonMessage:
        'Enter a phone number with the plus sign and a country code, for example, +18001234567.',
      urlLabel: 'Button link (maximum 2 000 characters)',
      urlLabelView: 'Button link',
      deleteConfirmTitle: 'Delete the template?',
      deleteConfirmText:
        'Data that you entered will be lost. You can save the template as a draft and resume your work later.',
      parentId: 'Create from template',
      parentIdView: 'Created from template',
      parentIdHint:
        'Now you can customize a registered operator template. Important! You can change only parameters, attachments, and the dynamic part of a link button. The main text, header, signature, and the rest of button settings can’t be changed.',
      parentIdPlaceHolder: 'Select template',
      userTextHint_1: 'Template text can’t be changed.',
      userTextHint_2: ' Replace placeholders with text or variables.',
      userHeaderHint: {
        [EContentType.DOCUMENT]:
          'Operator template that you selected contains a document in the header. You will be prompted to select a PDF file when creating a broadcast.',
        [EContentType.IMAGE]:
          'Operator template that you selected contains an image in the header. You will be prompted to select an image file when creating a broadcast.',
        [EContentType.VIDEO]:
          'Operator template that you selected contains a video in the header. You will be prompted to select a video file when creating a broadcast.',
        [EContentType.TEXT]:
          'Header text is inherited from the selected operator template. You can’t change it.',
      },
    },
    id: 'Template ID',
    name: 'Template name',
    nameHint: `For the template name, use only Latin characters and underscores. Using capital letters and spaces is not allowed. From ${TEMPLATE_NAME_MIN_LENGTH} to ${TEMPLATE_NAME_MAX_LENGTH} characters.`,
    subjectIds: 'Sender(s)',
    subjectIdsUserHint: 'The sender is specified in the operator template.',
    type: 'Select which template you want to create',
    title: 'New template',
    subject: 'Sender name',
    contentType: 'Select content type',
    textWithRegexp: 'Enter the template text and regular expressions (regexp)',
    exampleText: 'Enter the text of the sample message',
    description: 'Leave a comment if required',
    registeredTemplateText: 'Registered template text',
    textBasedOnTemplate: 'Enter the text based on the template',
    userTypeText: 'Enter your message text',
    textWithVariables: 'Enter template text and variables',
    textWithVariablesHint:
      'The device displays the header in one line. The maximum length is approximately 50 characters',
    text: 'Template text',
    language: 'Select a language',
    category: 'Select a category',
    categoryHint: {
      [EWhatsAppCategory.UTILITY]: `Utility messages:
1. Account updates, order updates, loyalty program updates;
2. Payment receipt notification, money transfers confirmation and other financial transactions.`,
      [EWhatsAppCategory.AUTHENTICATION]: `Authentication messages are one-time passwords for secure access to user profiles and applications.`,
      [EWhatsAppCategory.MARKETING]:
        'Marketing messages are useful news about your company, promotions, discounts, events, webinars, upsell, cross-sell, cart abandonment reminders',
    },
    categoryMessage: {
      [EWhatsAppCategory.MARKETING]: `Marketing messages share useful news about your company, promotions, discounts, events, webinars, upsell, cross-sell, cart abandonment reminders`,
      [EWhatsAppCategory.UTILITY]: `Utility messages:
1. Account updates, order updates, loyalty program updates;
2. Payment receipt notification, money transfers confirmation and other financial transactions.`,
      [EWhatsAppCategory.AUTHENTICATION]: `Authentication messages are one-time passwords for secure access to user profiles and applications.`,
    },
    categoryWarning:
      'WhatsApp can change the template category during the template registration. In case this happens it will also affect the price of the conversation initiated by sending a message using this template. Take this into account when planning the budget.',
    hasHeader: 'Add a header',
    headerType: 'Header',
    header: 'Content',
    addFooter: 'Add a message signature',
    addButtons: 'Add buttons',
    addImages: 'Attach images',
    checkCompliance: 'Check compliance',
    categories: {
      [EViberCategory.AccountUpdate]: 'Account update',
      [EViberCategory.AlertUpdate]: 'Alert update',
      [EViberCategory.AppointmentUpdate]: 'Appointment update',
      [EViberCategory.IssueResolution]: 'Issue resolution',
      [EViberCategory.PaymentUpdate]: 'Payment update',
      [EViberCategory.PersonalFinanceUpdate]: 'Personal finance update',
      [EViberCategory.ReservationUpdate]: 'Reservation update',
      [EViberCategory.ShippingUpdate]: 'Shipping update',
      [EViberCategory.TicketUpdate]: 'Ticket update',
      [EViberCategory.AutoReply]: 'Auto reply',
      [EViberCategory.TransportationUpdate]: 'Transportation update',
      [EOldWhatsAppCategory.TRANSACTIONAL]: 'Transactional',
      [EWhatsAppCategory.MARKETING]: 'Marketing',
      [EOldWhatsAppCategory.OTP]: 'OTP',
      [EWhatsAppCategory.AUTHENTICATION]: 'Authentication',
      [EWhatsAppCategory.UTILITY]: 'Utility',
    },
    addButtonsHint: 'A template can contain either chat buttons or call-to-action buttons.',
    buttonType: 'Button type',
    buttonTypePlaceholder: 'Select button type',
    chatButtons: 'Chat buttons (quick reply)',
    callButtons: 'Call to action buttons',
    buttonFields: {
      resetTemplate: 'Reset template',
      textLabel: 'Button name',
      urlLabel: 'Button link',
      urlHint: 'Only https URL is allowed',
      payloadLabel: 'Button code',
      payloadHint: 'Use this code to track the button clicks via API',
      phoneLabel: 'Phone number (required a plus sign and the country code)',
      phoneHint:
        'Phone number should include symbol plus and country code. For example, +441234567890',
      urlPostfixLabel: 'Make the button URL dynamic',
    },
    buttonTypes: {
      [EMessageMatcherButtonTypes.PHONE]: 'Call button',
      [EMessageMatcherButtonTypes.URL]: 'Link button',
      [EMessageMatcherButtonTypes.CHAT]: 'Chat button',
      [EMessageMatcherButtonTypes.LOCATION]: 'Location',
      [EMessageMatcherButtonTypes.VK_MINI_APPS]: 'VK Mini Apps',
    },
    addButtonText: 'button',
    buttonWithCount: 'Button {{count}}',
    parentId: 'You can select an existing template as the basis for a new one',
    testTemplate: 'Validate template',
    exampleMatches: 'Match',
    exampleNotMatches: 'Mismatch',
    additionalParameters: 'Additional settings',
    // viber custom content
    optional: ' (optional)',
    imageLabel: 'Add a picture',
    uploadDocument: 'Upload from computer',
    addDocumentLink: 'Insert link to file',
    documentLabel: 'Upload file',
    documentUrlLabel: 'File link address',
    documentUrlHint:
      'Supported attachment formats: text files (.doc, .docx, .txt, .rtf, .dot, .dotx, .odt, odf, .fodt, .info), PDF, Excel (.xls and .xlsx), and graphic files (.xps, .pdax, .eps). Maximum file size is 25MB',
    registrationMessage:
      "The template is being registered. You can see the template's registration status on the Settings > Templates page.",
    registrationMessage_one:
      "The template is being registered, the process will take about {{count}} day. You can see the template's registration status on the Settings > Templates page.",
    registrationMessage_other:
      "The template is being registered, the process will take about {{from}}-{{count}} days. You can see the template's registration status on the Settings > Templates page.",
    linkButtonMessage:
      'The button URL can be both static (fixed) and dynamic. If it is a dynamic URL, it must have two parts. The first one is a fixed (invariable) part (for example, a section of a website, like https://example.com/marketing/). The second, final part of the URL, can change for each broadcast created based on this template (for example, https://example.com/marketing/action234 or https://example.com/marketing/gift-1000). While setting up a broadcast, you can make a dynamic URL individual for each recepient of the broadcast if you add the required variable at the end of the URL. To make the URL in the template a dynamic one, select "Make the button URL dynamic."',
  },
  regularExpressionsTitle: 'Regular expressions',
  whatsAppRules: {
    title: 'Rules for using a string of characters',
    hint: `In a template, a string of characters is a placeholder the values of which you will change as required. You can add any text and variables instead of this placeholder.
You can add any text and variables instead of this placeholder - any combination of words, numbers, symbols, and spaces, <0>except for the & symbol</0>`,
  },
  viberRules: {
    title: 'Rules for using symbols in the template',
    rule1:
      'Remove the following characters from the pattern to match: /! No #%. ,:; ? / () + - “” - _ \'"` & ^? {} [] <> / |! @ # $% ^ () + = ~ *',
    rule2: 'Replace line breaks with space',
    rule3: 'Replace repeated consecutive spaces with one space',
    info1:
      'For registration with Viber, a template cannot have any special characters. However, you can use those symbols in broadcast messages based on this template. If you create a custom template based on this registered template, you can also add these special characters to it. ',
    info2: 'Refer to the following document <0> Rules for composing templates for Viber </0>',
  },
  smsRegularExpressions: [
    {
      key: '%w',
      value: 'Any continuous set of letters and/or special characters',
    },
    {
      key: '%w{1,10}',
      value: 'A limited sequence of words',
      hint: 'that consists of letters, digits, or special characters separated by space(s), where 10 is the maximum number of words',
    },
    {
      key: '%d',
      value: 'Any continuous set of numbers and/or special characters',
    },
    {
      key: '%d{1,10}',
      value: 'A limited sequence of numbers',
      hint: 'that consists of digits or special characters separated by space(s), where 10 is the maximum number of digits',
    },
  ],
  viberRegularExpressions: [
    {key: '\\d+', value: 'Digit or number'},
    {
      key: '\\w+',
      value: 'Any continuous set of letters and/or numbers',
    },
    {
      key: '[\\s\\w]+',
      value: 'A sequence of words',
      hint: 'consists of at least one element from the list or their combination: letter, word, digit, number, space, line break',
    },
    {
      key: '[\\s\\d]+',
      value: 'A sequence of several numbers and / or digits',
    },
  ],
  viberRegularExpressionsHint:
    'These are the most popular regular expressions for Viber templates, you can use others as well.',
  exampleTemplatesTitle: 'Template examples',
  exampleTemplatesTemplate: 'Registered template',
  exampleTemplatesMessage: 'Message based on the template',
  viberExampleTemplates: [
    [
      {
        key: '\\w+',
        value: 'Alex',
        text: '<0>,</0> on ',
      },
      {
        key: '\\d+',
        value: '10<0>.</0>01<0>.</0>2021',
        text: '<0>,</0> card payments will not be available in your personal account due to ',
      },
      {
        key: '[\\s\\w]+',
        value: 'technical maintenance',
        text: '<0>.</0> We apologize for any inconvenience this may cause you<0>.</0>',
      },
    ],
    [
      {
        key: '\\w+',
        value: 'Maria',
        text: '<0>,</0> starting ',
      },
      {
        key: '\\d+',
        value: '14<0>.</0>06<0>.</0>2021',
        text: '<0>,</0> we are changing your cards fees and charges<0>.</0> Learn more about the new terms using the following link ',
      },
      {key: '\\w+', value: 'https<0>://</0>site<0>.</0>com<0>/</0>UmR8E'},
    ],
    [
      {
        key: '\\w+',
        value: 'Elena',
        text: `<0>,</0> this is to remind you that you have booked a stay with the <0>"</0>Happy Paws<0>"</0> pet boarding services starting `,
      },
      {
        key: '\\d+',
        value: '10<0>.</0>10<0>.</0>2021',
        text: '<0>.</0> The amount to be paid<0>:</0> ',
      },
      {
        key: '[\\d\\s]+',
        value: '1 000',
        text: 'USD<0>.</0> We are looking forward for your pet ',
      },
      {key: '\\w+', value: 'Max', text: ' to stay with us<0>.</0>'},
    ],
  ],
  specialCharactersHint: `
     - Special characters that were removed in the registration template can be returned when sending a message
      or when creating a custom template
   `,
  smsExampleTemplates: [
    [
      {
        key: '%w',
        value: 'Alex,',
        text: ' on ',
      },
      {
        key: '%d',
        value: '10.01.2021,',
        text: ' card payments will not be available in your personal account due to ',
      },
      {
        key: '%w{1,10}',
        value: 'technical maintenance.',
        text: ' We apologize for any inconvenience this may cause you.',
      },
    ],
    [
      {
        key: '%w',
        value: 'Maria,',
        text: ' starting ',
      },
      {
        key: '%d',
        value: '14.06.2021,',
        text: ' we are changing your cards fees and charges. Learn more about the new terms using the following link ',
      },
      {key: '%w', value: 'https://site.com/UmR8E'},
    ],
    [
      {
        key: '%w',
        value: 'Elena,',
        text: ` this is to remind you that you have booked a stay with the "Happy Paws" pet boarding services starting `,
      },
      {key: '%d', value: '10.10.2021.', text: ' The amount to be paid: '},
      {key: '%w', value: 'Max', text: ' to stay with us.'},
    ],
  ],
  selectMessage: {
    placeholder: 'Find template',
    name: 'Template name',
    type: 'Type',
    date: 'Date of last use',
    action: 'Use',
  },
  successRegister: {
    head: 'The template has been submitted for registration',
    text: 'Verification and registration will take the operator some time. You can keep track of the status of the template in the list of templates',
    textWithDays_one:
      'It will take the operator about {{count}} day to check and register the template. Keep track of the template status in the list of templates.',
    textWithDays_other:
      'It will take the operator about {{from}}-{{count}} days to check and register the template. Keep track of the template status in the list of templates.',
    mainButton: 'To the main page',
    listButton: 'To the list',
  },
  empty: {
    title: 'Nothing here yet',
    subtitle:
      "Sorry, you don't have any templates yet. A template is what the system uses to shape your message - and what helps you save time and money. You create templates for each of the channels.",
    action: 'Create a template',
  },
  filters: {
    status: 'Template status',
    channelType: 'Channel',
    type: 'Template type',
    channelName: 'Channel name',
    templates: 'Templates',
  },
  template: {
    options: {
      variable: 'Variable',
      text: 'Text',
      changeToText: 'Change to text',
      changeToVariable: 'Change to variable',
    },
    placeholders: {
      variable: 'Choose variable',
      textOrVariable: 'text or variable',
    },
    title: 'Template text',
    postTitle: ' (substitute a variable or enter text in the specified places)',
  },
  message:
    'The main text and content of the template cannot be changed. Only variables and attachments can be changed',
  parameterExamplesModal: {
    title: 'Add sample of message content',
    text: 'To help Facebook to understand what kind of message you want to send, you need to provide specific content examples for your template',
    header: 'Header',
    [EContentType.IMAGE]: {
      label: 'Image sample (.jpg or .png up to 5 MB)',
      addLink: 'Enter the image link',
      hint: 'Image URL (the link should end with the file extension. Example https://ltdfoto.ru/image/lRe1bG.png)',
    },
    [EContentType.VIDEO]: {
      label: 'Video sample (.mp4, .3gpp up to 5 MB)',
      addLink: 'Enter the video link',
      hint: 'Video URL (the link should end with the file extension. Example https://ltdfoto.ru/lRe1bG.mp4)',
    },
    [EContentType.DOCUMENT]: {
      label: 'File sample (.pdf)',
      addLink: 'Enter the file link',
      hint: 'File URL (the link should end with the file extension. Example https://ltdfoto.ru/lRe1bG.pdf)',
    },
    upload: 'Upload from computer',
    textLabel: 'Content',
    textHint: 'Message text (add samples of the variables in the marked positions)',
    example: ` Sample of {\u200b{\u200b{{variableName}}\u200b}\u200b}`,
    linkButton: 'Link button',
    linkButtonHint: 'Add sample of a link in the button',
    linkButtonText: 'Button text:',
    linkButtonUrlHint:
      'Enter full URL. Example: https://weblink.com/1" . Where https://weblink.com/ - URL from the template form. 1 - sample of URL variable.',
  },
  vkNotify: {
    images: 'Template images',
    imagePickerAdd: 'image',
    imagesHint: `You can attach up to ${MAX_ATTACHMENTS_LENGTH} images in the JPG, PNG, and GIF formats, with the combined size of up to ${MAX_FILE_SIZE_MB} MB, combined width and height of up to ${formatNumber(
      MAX_FILE_DIMENSIONS_SUM,
      {},
      'en',
    )} pixels, and measurement ratios of up to 1:20.`,
    button: 'Button',
    buttons: 'Template buttons',
    buttonsHint: `Add up to ${MAX_BUTTONS_LENGTH} buttons of different types. You can't change these buttons after sending a template for registration.`,
    buttonText: 'Button text (maximum 40 characters)',
    buttonPayload: 'Button code, payload (maximum 255 characters)',
    buttonColor: 'Button color',
    buttonColorPlaceholder: 'Select button color',
    buttonHash: 'App hash (optional)',
    buttonOwnerId: 'owner_id',
    buttonAppId: 'app ID',
    buttonLocation: 'Location',
    invalidImageFormat: 'Image upload error: invalid format',
  },
  vkNotifyUserAnswer: {
    label: 'Replies',
    on: 'Users can reply',
    onHint:
      'Your company can maintain conversations with users by turning on user replies. Read their replies and answer back in VK community/OK group messages.',
    off: "Users can't reply",
    offHint: "Your company sends notifications that don't require user replies.",
  },
  vkNotifyButtons: {
    [EMessageMatcherButtonTypes.CHAT]: 'Sends a message to a community chat or conversation',
    [EMessageMatcherButtonTypes.URL]: 'Opens the link specified below',
    [EMessageMatcherButtonTypes.LOCATION]:
      'Sends user location to a community chat or conversation',
    [EMessageMatcherButtonTypes.VK_MINI_APPS]: 'Opens the VK Mini App specified below',
  },
  vkNotifyColors: {
    [EMessageMatcherButtonColor.PRIMARY]: 'Blue',
    [EMessageMatcherButtonColor.SECONDARY]: 'Gray',
    [EMessageMatcherButtonColor.POSITIVE]: 'Green',
    [EMessageMatcherButtonColor.NEGATIVE]: 'Red',
  },
  whatsAppAuthTemplate: {
    text: 'Message text',
    addSecurityRecommendation: 'Add security disclaimer',
    securityRecommendationHint:
      'Add the following sentence to your message: "For your security, do not share this code."',
    userSecurityRecommendationHint:
      'The following sentence was added to your message: "For your security, do not share this code."',
    addPasswordExpiration: 'Add expiration warning',
    passwordExpirationTimeHint: 'Enter expiration interval between 1 and 90 minutes',
    passwordExpirationHint:
      'Add the following warning to your message: "This code expires in <NUM_MINUTES> minutes."',
    userPasswordExpirationHint:
      'The following warning was added to your message: "This code expires in {{codeExpirationMinutes}} minutes."',
    baseText: '{{1}} is your verification code.',
    securityRecommendationText: 'For your security, do not share this code.',
    passwordExpirationText: 'This code expires in {{count}} minutes.',
    button: 'Button',
    buttonText: 'Button text',
    buttonLabel: {
      [ETemplateButtonOtpType.COPY]: 'Copy code',
      [ETemplateButtonOtpType.AUTO]: 'One-tap autofill',
    },
    autoButtonText: 'Autofill',
    package: 'Package name',
    hash: 'App signing key hash',
    autofillText: 'Copy code button text',
    handshakeCopyButton:
      'Copy code button text. This button is shown if your app can\'t complete a "handshake". <0>More information.</0>',
    handshakeLink:
      'https://developers.facebook.com/docs/whatsapp/business-management-api/authentication-templates#handshake',
  },
};
