import styled from 'styled-components';

type TPre = {
  isTab?: boolean;
};

export const Pre = styled.pre<TPre>`
  width: ${({isTab}) => (isTab ? 'fit-content' : '100%')};
`;

export const Code = styled.div`
  ${Pre} {
    margin: 0;
  }
`;
