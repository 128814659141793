import React from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

import {move} from 'src/utils';

import * as S from './style';

const DragHandler = SortableHandle(() => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => event.stopPropagation(),
    [],
  );

  return <S.DragIcon onClick={handleClick} />;
});

type TSortableItemProps = {
  children: React.ReactNode;
};

const SortableItem = SortableElement(({children}: TSortableItemProps) => <>{children}</>);

type TSortableWrapperProps = {
  children: React.ReactNode;
  isDragging: boolean;
  renderWrapper?: ({
    children,
    isDragging,
  }: {
    children: React.ReactNode;
    isDragging: boolean;
  }) => React.ReactNode;
};

const SortableWrapper = SortableContainer(
  ({children, isDragging, renderWrapper}: TSortableWrapperProps) => (
    <>{renderWrapper ? renderWrapper({children, isDragging}) : children}</>
  ),
);

type TProps<T extends {id: string | number} = TAnyObject & {id: string | number}> = {
  items: T[];
  onMove: ({fromIndex, toIndex, items}: {fromIndex: number; toIndex: number; items: T[]}) => void;
  header?: React.ReactNode;
  grabbingClassName?: string;
  renderItem: ({
    item,
    index,
    isDragging,
  }: {
    item: T;
    index: number;
    isDragging: boolean;
  }) => React.ReactNode;
  renderWrapper?: ({
    children,
    isDragging,
  }: {
    children: React.ReactNode;
    isDragging: boolean;
  }) => React.ReactNode;
  listComponent: React.ComponentType<{children: React.ReactNode}>;
};

const SortableList = React.memo<TProps>(
  ({
    grabbingClassName,
    listComponent: ListComponent,
    renderItem,
    renderWrapper,
    onMove,
    items,
    header,
  }) => {
    const [isDragging, setIsDragging] = React.useState<boolean>(false);

    const onSortEnd = React.useCallback(
      ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) => {
        setIsDragging(false);
        onMove({fromIndex: oldIndex, toIndex: newIndex, items: move(items, oldIndex, newIndex)});
      },
      [setIsDragging, onMove],
    );

    const onSortStart = React.useCallback(() => {
      setIsDragging(true);
    }, [setIsDragging]);

    return (
      <SortableWrapper
        useDragHandle
        useWindowAsScrollContainer
        onSortEnd={onSortEnd}
        onSortStart={onSortStart}
        isDragging={isDragging}
        lockAxis="y"
        lockOffset={0}
        helperClass={grabbingClassName}
        renderWrapper={renderWrapper}
      >
        {header}
        <ListComponent>
          {items.map((item, index) => {
            // SortableItem key must contain a constant value for the list item
            return (
              <SortableItem key={item.id} index={index}>
                {renderItem({item, index, isDragging})}
              </SortableItem>
            );
          })}
        </ListComponent>
      </SortableWrapper>
    );
  },
);

SortableList.displayName = 'SortableList';

export {SortableList, DragHandler};
