import React from 'react';
import {useTranslation} from 'react-i18next';

import camelCase from 'lodash/camelCase';

import {ActionButton, EButtonAppearance, TActionButtonOption, TButtonProps} from '@edna/components';
import EditIcon from '@edna/icons/edit.svg';
import PlayIcon from '@edna/icons/play.svg';
import VisibilityIcon from '@edna/icons/visibility.svg';

import {EPermission, usePermission} from 'src/containers/User';
import {BlockIcon, CopyIcon, PauseIcon, RefreshIcon, SettingsIcon, TrashIcon} from 'src/icons';

import * as S from './style';

enum EMenuButtonAction {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  COPY = 'COPY',
  CANCEL = 'CANCEL',
  DELETE = 'DELETE',
  //  Flow actions
  SETTINGS = 'SETTINGS',
  START = 'START',
  STOP = 'STOP',
  // WhatsApp actions
  REFRESH = 'REFRESH',
}

type TMenuButtonAction = keyof typeof EMenuButtonAction;

type TMenuButtonOption = {
  type: string;
  icon?: TSvgComponentType;
  label?: React.ReactNode;
  priority?: number;
  onClick: () => void;
};

const LOW_PRIORITY = 10;

const iconComponentHash: Record<TMenuButtonAction, TSvgComponentType> = {
  VIEW: VisibilityIcon,
  EDIT: EditIcon,
  COPY: CopyIcon,
  CANCEL: BlockIcon,
  DELETE: TrashIcon,
  SETTINGS: SettingsIcon,
  START: PlayIcon,
  STOP: PauseIcon,
  REFRESH: RefreshIcon,
};

const priority: Record<TMenuButtonAction, number> = {
  VIEW: 0,
  EDIT: 1,
  COPY: 2,
  REFRESH: 3,
  SETTINGS: 4,
  START: 5,
  STOP: 6,
  CANCEL: 7,
  DELETE: 8,
};

const getPriority = (item: TMenuButtonOption) => {
  if (item.priority) {
    return item.priority;
  }

  return priority[item.type as TMenuButtonAction] ?? LOW_PRIORITY;
};

const MenuButton = React.memo(
  ({
    className,
    options,
    title,
    prefix = null,
    size = 'S',
    overlayClassName,
    iconClassName,
  }: {
    title?: string;
    size?: TButtonProps['size'];
    className?: string;
    prefix?: React.ReactNode;
    options: TMenuButtonOption[];
    overlayClassName?: string;
    iconClassName?: string;
  }) => {
    const {t} = useTranslation();

    const hasWritePermission = usePermission(EPermission.PERMISSION_WRITE);

    const sortedOptions = options
      .sort((a, b) => getPriority(a) - getPriority(b))
      .reduce((result, {type: optionType, icon, label, onClick}) => {
        if (
          ![EMenuButtonAction.VIEW, EMenuButtonAction.EDIT].includes(
            optionType as EMenuButtonAction,
          ) &&
          !hasWritePermission
        ) {
          return result;
        }

        return [
          ...result,
          {
            id: optionType,
            content: (
              <>
                <S.OptionIcon as={icon ?? iconComponentHash[optionType as TMenuButtonAction]} />
                <span {...edna.renderTestId(edna.TEST_IDS.MENU_BUTTON.OPTION(optionType))}>
                  {label ?? t(`Common:button.${camelCase(optionType)}`)}
                </span>
              </>
            ),
            handler: () => onClick(),
          },
        ];
      }, [] as TActionButtonOption[]);

    return (
      <ActionButton
        className={className}
        square={!prefix}
        options={sortedOptions}
        appearance={EButtonAppearance.primary}
        size={size}
        overlayClassName={overlayClassName}
      >
        {prefix}
        <S.MoreHorizIcon
          title={title}
          className={iconClassName}
          {...edna.renderTestId(edna.TEST_IDS.MENU_BUTTON.CONTAINER)}
        />
      </ActionButton>
    );
  },
);

MenuButton.displayName = 'MenuButton';

export type {TMenuButtonOption, TMenuButtonAction};
export {MenuButton, EMenuButtonAction};
