import {EDocumentType, EPaymentType} from 'src/containers/User';

import {EFormStep, ELeadClientCreatingError} from '../definitions';

export default {
  errors: {
    [ELeadClientCreatingError.TAX_NUMBER_ALREADY_IN_USE]:
      'Профиль с таким ИНН и контактным лицом уже существует.',
    [ELeadClientCreatingError.EMAIL_ALREADY_IN_USE]: 'Профиль с таким email уже существует.',
  },
  labels: {
    companyName: 'Название организации',
    taxNumber: 'ИНН',
    binNumber: 'Бизнес-идентификационный номер (БИН)',
    blrTaxNumber: 'УНП / ИНН',
    ogrn: 'ОГРН/ОГРНИП',
    countryCode: 'Страна',
    currencyCode: 'Валюта',
    resellerId: 'Юрисдикция',
    contactName: 'ФИО контактного лица',
    phone: 'Телефон',
    company: 'Компания',
    responsibleManagerId: 'Ответственный менеджер edna',
    channel: 'Канал',
    tariff: 'Тариф',
    special: 'Индивидуальный',
    tariffConditions: 'Описание условий тарифа',
    documentType: 'Оферта/договор',
    paymentType: 'Тип оплаты',
    manager: 'Ответственный за проверку',
    isChecked: 'Прошел проверку в финансовом и юридическом департаменте',
    depositAmount: 'Размер обеспечительного платежа',
    overdraftAmount: 'Размер овердрафта',
    paymentPeriod: 'Срок оплаты лицензии, дней',
    cancellationPeriod: 'Срок до аннулирования лицензии при неоплате, дней',
    activateLicenseDebt: 'Активировать Лицензию в долг',
    disableBilling: 'Отключить биллинг клиенту',
    contractNumber: 'Номер договора',
    pilotContractNumber: 'Номер договора (опционально)',
    contractDate: 'Дата заключения договора',
    pilotContractDate: 'Действует до',
    partnerId: 'Агент',
    hasPartner: 'Лид от агента',
    bank: 'Банк',
    isAgreedOverdraft: 'Подключение и размер овердрафта согласованы',
    agreedOverdraft: 'Овердрафт согласован с',
  },
  placeholders: {
    email: 'yourname@company.com',
    phone: 'Введите номер',
    currencyCode: 'Выберите валюту',
    resellerId: 'Выберите юрлицо',
    channel: '-',
    tariff: 'Выберите тариф',
    partnerId: 'Выберите агента',
    agreedOverdraft: 'Выберите согласующего',
    managerResponsibleForCheckingId: 'Выберите менеджера',
  },
  buttons: {
    create: 'Создать',
    next: 'Дальше',
    addTariff: 'Добавить тариф',
    confirm: 'Подтверждаю',
    agreed: 'Согласовано',
  },
  titles: {
    dataAndContacts: 'Данные компании и контакты',
    calculationAndLicenses: 'Взаиморасчеты и лицензии',
    companyTariffs: 'Тарифы компании',
  },
  subtitles: {
    commonData: 'Общие данные',
    contacts: 'Контакты',
    paymentSystem: 'Согласованная система оплаты',
    credits: 'Кредитные возможности',
    licensesConditions: 'Условия подключения лицензий',
    proposedCompanyTariffs: 'Предлагаемые компании тарифы',
    billing: 'Биллинг',
    contract: 'Договор',
  },
  steps: {
    [EFormStep.DETAILS]: 'Данные компании',
    [EFormStep.CALCULATION_AND_LICENSES]: 'Взаиморасчеты и лицензии',
    [EFormStep.TARIFFS]: 'Тарифы клиента',
  },
  documentTypes: {
    [EDocumentType.AGREEMENT]: {
      label: 'Оферта',
      message:
        'Внимание! Данный тип регистрации доступен для клиента, с которым не подписан бумажный договор. Для работы с сервисом клиент должен будет принять пользовательское соглашение. Подтвердите свой выбор',
    },
    [EDocumentType.CONTRACT]: {
      label: 'Бумажный договор',
      message:
        'Внимание! Данный тип регистрации доступен только для клиента, с которым вы уже подписали договор. Подтвердите свой выбор',
    },
    [EDocumentType.TECH_PARTNER]: {
      label: 'Технический партнер',
      message:
        'Внимание! Данный тип регистрации доступен только для клиента, с которым вы уже подписали договор. Подтвердите свой выбор',
    },
    [EDocumentType.PILOT]: {
      label: 'Пилот',
    },
  },
  paymentTypes: {
    [EPaymentType.POSTPAID]: {
      label: 'Постоплата',
    },
    [EPaymentType.PREPAYMENT]: {
      label: 'Предоплата',
    },
    [EPaymentType.MIXED_PREPAYMENT]: {
      label: 'Смешанная оплата',
    },
  },
  error: {
    confirm: 'Необходимо подтвердить',
  },
  description: {
    blrTariff: 'Условия выбранного тарифа отражены в договоре.',
    whatsapp: {
      firstReseller: {
        template_1: `1. Лицензионное вознаграждение за лицензию на Системы edna Pulse с функциональностью WA состоит из:
1.1. Фиксированная часть лицензионного вознаграждения составляет {{licensePrice}} {{currency}} (пакет включает в себя {{mauCount}} MAU*)
1.2. Переменная часть лицензионного вознаграждения состоит из стоимости каждого дополнительного MAU (сверх количества MAU фиксированной части) – {{defaultMauPrice}} {{currency}}
2. {{paymentCondition}}`,
        template_2_link: `3. Услуга маршрутизации сообщений в мессенджер WhatsApp:\xa0<0>Стоимость за диалоги</0>`,
        template_2_text_1: `3. Услуга маршрутизации сообщений в мессенджер WhatsApp: `,
        template_2_text_2: `Стоимость за диалоги`,
        template_3: `
Все цены указаны без НДС**

*MAU (monthly active user) – это уникальный номер телефона вашего клиента, который отправил или получил сообщение от вашей компании в течение одного календарного месяца.
**НДС уплачивается по ставке в соответствии с законодательством Российской Федерации.
Стоимость, установленная в USD, оплачивается в рублях по курсу ЦБ РФ на последний день месяца.
`,
        template_3_kzt: `
Все цены указаны без НДС**

*MAU (monthly active user) – это уникальный номер телефона вашего клиента, который отправил или получил сообщение от вашей компании в течение одного календарного месяца.
**НДС уплачивается по ставке в соответствии с законодательством Республики Казахстан.
`,
        paymentCondition: `Подключение одного номера телефона составляет {{price}} {{currency}}.`,
        freePaymentCondition: `Подключение номера телефона бесплатно.`,
      },
      //ioReseller - только на английском языке
      ioReseller: {
        agreement:
          'Connecting one account costs {{price}} {{currency}}.\n' +
          'The license fee consists of the following:\n' +
          '1. The fixed part of the license fee is {{licensePrice}} {{currency}}. (The package includes {{mauCount}} MAU)*\n' +
          '2. The variable part of the license fee consists of the cost of each additional MAU (over the number of MAU of the fixed part) - {{defaultMauPrice}} {{currency}}.\n' +
          '<0>Price per initiated conversation</0>\n' +
          'All prices are without VAT**\n' +
          '*MAU (monthly active user) is a unique phone number of your client who sent or received a message from your company within one calendar month.\n' +
          '**The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation',
        contract:
          'The cost of WhatsApp license and conversations according to the terms of the contract\n' +
          'All prices are without VAT. The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation',
      },
    },
    viber: {
      packagePrice: `
Стоимость минимального платежа {{packagePrice}} {{currency}}`,
      packagePriceContract: `
Сумма минимального платежа за услуги передачи сообщений Viber согласно условиям договора`,
      firstReseller: {
        template_1_1: `1. Лицензионное вознаграждение за лицензию на Системы edna Pulse с функциональностью VB составляет {{licensePrice}} {{currency}}`,
        template_1_2: `
2. Услуга отправки сообщений в мессенджер Viber:`,
        template_2: 'Стоимость Viber-сообщений согласно условиям договора',
        template_2_link: '<0>Стоимость доставленных Viber-сообщений</0>',
        template_3: `Все цены указаны без НДС. НДС уплачивается по ставке в соответствии с законодательством Российской Федерации.
Стоимость, установленная в EUR, оплачивается в рублях по курсу ЦБ РФ на последний день месяца.`,
      },
      secondReseller: {
        template_1: 'Стоимость Viber-сообщений согласно условиям договора',
        template_1_license: `Стоимость лицензии {{licensePrice}} {{currency}}`,
        template_1_link: `
<0>Стоимость доставленных Viber-сообщений</0>`,
      },
      thirdReseller: {
        packagePrice: `
Viber minimum fee {{packagePrice}} {{currency}}`,
        packagePriceContract: `
The cost of Viber minimum fee according to the terms of the contract`,
        contract: `The cost of Viber messages according to the terms of the contract
All prices are without VAT. The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Indonesia`,
        offer_1: `License price {{licensePrice}} {{currency}}`,
        offer_2: `
<0>Price for delivered Viber messages</0>`,
        offer_3: `
All prices are without VAT*
*The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Indonesia`,
      },
    },
    sms: {
      firstReseller: {
        template_1: `1. Лицензионное вознаграждение за лицензию на Системы edna Pulse с функциональностью SMS составляет {{licensePrice}} {{currency}}
2. Услуга отправки SMS-сообщений:`,
        template_2: 'Стоимость SMS-сообщений согласно условиям договора',
        template_2_link: '<0>Стоимость отправленных SMS-сообщений</0>',
        template_3:
          'Все цены указаны без НДС. НДС уплачивается по ставке в соответствии с законодательством Российской Федерации.',
      },
      secondReseller: {
        template_1: 'Стоимость SMS-сообщений согласно условиям договора',
        template_1_link: `Стоимость лицензии {{licensePrice}} {{currency}}
<0>Стоимость доставленных SMS-сообщений</0>`,
      },
      thirdReseller: {
        contract: `The cost of SMS messages according to the terms of the contract
All prices are without VAT. The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Indonesia`,

        offer_1: `License price {{licensePrice}} {{currency}}`,
        offer_2: `
<0>Price for delivered SMS messages</0>`,
        offer_3: `All prices are without VAT. The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Indonesia`,
      },
    },
  },
  addPersonalTariff: 'Добавить индивидуальный тариф',
  billingMessage:
    'Важно! Отключить биллинг можно только клиенту, с которыми заключен договор старого образца. Отключение биллинга должно быть обязательно согласовано с руководителем. Подтвердите свой выбор',
  blrBillingMessage:
    'Клиентам в выбранной юрисдикции биллинг недоступен. Все взаиморасчеты с клиентом производятся на стороне ООО «Инфосинтез».',
};
