import {CSSProperties} from 'react';

import styled, {css, keyframes} from 'styled-components';

import {Button} from '@edna/components';
import {Row, Stack} from '@edna/components/primitives';
import {mixins as uiMixins} from '@edna/components/styles';
import BaseArrowBackIcon from '@edna/icons/arrowBack.svg';

import {LoadingIcon as BaseLoadingIcon} from 'src/icons';

export {
  Text,
  SecondaryText,
  Hint,
  BigText,
  InlineLink,
  SecondaryLink,
  Link,
  RouterLink,
  getFontSize,
  Error,
} from './typography';

export const FormTitle = styled.div<{noMargin?: boolean}>`
  ${uiMixins.f5}

  ${({theme, noMargin}) => css`
    color: ${theme.branding.content.secondary};
    ${!noMargin &&
    css`
      margin-bottom: ${theme.spacing.s4};
    `}
  `};
`;

const ContentWithRightPreviewWrapper = styled.div`
  ${uiMixins.stack({size: '4', justify: 'space-between', direction: 'ROW'})};
`;

const ContentWithRightPreview = styled.div`
  width: 100%;
  max-width: ${({theme}) => theme.dimensions.formWidth}px;
`;

const LeftButton = styled(Button)`
  margin-right: ${({theme}) => theme.spacing.s2};
`;

const ModalLayout = styled.div`
  display: flex;
`;

const ModalLeftContent = styled.div`
  ${uiMixins.flexAlign('space-between', 'flex-start')}
  flex-direction: column;
  min-width: 325px;
  margin-right: ${({theme}) => theme.spacing.s8};
`;

const ModalLeftActions = styled.div`
  margin-top: auto;
  padding-top: ${({theme}) => theme.spacing.s6};
`;

const ModalImage = styled.svg`
  display: block;
  flex-shrink: 0;
`;

const ModalCenterImage = styled(ModalImage)`
  ${({theme}) => css`
    margin: ${theme.spacing.s6} auto 0;
  `}
`;

const NoShrinkImage = styled.svg<{maxWidth?: string}>`
  flex-shrink: 0;
  max-width: ${({maxWidth}) => maxWidth};
`;

type TFieldFooterProps = {
  justifyContent?: CSSProperties['justifyContent'];
};

const FieldFooter = styled.div<TFieldFooterProps>`
  ${({theme, justifyContent = 'flex-start'}) => css`
    ${uiMixins.flexAlign(justifyContent, 'center')}
    margin-top: ${theme.spacing.s2};
  `}
`;

const NestedBlock = styled.div`
  margin: ${({theme: {spacing}}) => `${spacing.s3} 0 ${spacing.s1} ${spacing.s8}`};
`;

const Segments = styled.div`
  ${uiMixins.f1}
  display: inline-flex;
  width: max-content;
  ${({theme: {spacing, borderRadius, branding, palette, zIndex}}) => css`
    z-index: ${-zIndex.background};
    margin: -${spacing.s7} ${spacing.s3} ${spacing.s2} auto;
    padding: 2px ${spacing.s2};
    background-color: ${branding.content.secondary};
    color: ${palette.white};
    border-radius: ${borderRadius.br8};
  `}
`;

const FullForm = styled.form`
  width: 100%;
`;

type TCenterRowProps = {
  hasTopIndent?: boolean;
};

const CenterRow = styled(Row)<TCenterRowProps>`
  ${uiMixins.flexAlign('initial', 'center')}
  margin-top: ${({theme, hasTopIndent}) => (hasTopIndent ? theme.spacing.s4 : 0)};
`;

const ClickBlocker = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  cursor: not-allowed;
`;

const circular = keyframes`
  100% {
    transform: rotate(360deg)
  }
`;

const Loader = styled(BaseLoadingIcon)`
  ${uiMixins.wh('24px')};
  animation: ${circular} 3s linear infinite;
`;

const InlineLinkButton = styled.button<{weight?: number}>`
  ${uiMixins.pureButton}
  font-size: 'inherit';
  font-weight: ${({weight}) => weight ?? 'inherit'};
  color: currentColor;
  text-decoration: underline;
`;

const ButtonContent = styled(Stack).attrs({size: '2', direction: 'ROW', align: 'center'})``;

const CommonLayout = styled.div`
  ${uiMixins.stack({direction: 'COLUMN'})};
  min-height: 100vh;
`;

const ArrowNextIcon = styled(BaseArrowBackIcon)`
  transform: rotate(180deg);
`;

export {
  InlineLinkButton,
  Loader,
  ClickBlocker,
  CenterRow,
  FullForm,
  Segments,
  NestedBlock,
  FieldFooter,
  NoShrinkImage,
  ModalCenterImage,
  ModalImage,
  ModalLeftActions,
  ModalLeftContent,
  ModalLayout,
  LeftButton,
  ContentWithRightPreview,
  ContentWithRightPreviewWrapper,
  ButtonContent,
  CommonLayout,
  ArrowNextIcon,
};
