import {EStatus} from './definitions';

export default {
  coverText:
    'Connect your Instagram Business Account and chat with your customers when they contact you via direct messages, post stories mentioning your account, or reply to your stories',
  editForm: {
    name: 'Internal channel name',
  },
  loginMessage:
    'To register a channel, please login to your Facebook account and follow the steps provided.',
  status: {
    [EStatus.SENT_TO_PLATFORM]: {
      title: 'Pending registration',
      details: 'Submitted for registration',
    },
    [EStatus.FINISH_REGISTER]: {
      title: 'Active',
      details: 'Works properly',
    },
    [EStatus.UNKNOWN]: {
      title: 'Unknown',
      details: '',
    },
  },
  profileName: 'Profile name',
  selectAccount: 'Select one business account for edna Pulse',
  selectAccountHint:
    'You have selected several Instagram accounts linked to Facebook, but you can only specify one Instagram acount for this channel. You can also select another Instagram account, but you need to create a new channel for that first. So, to avoid confusion, select one of the following accounts for this channel:',
  communicationRules: {
    title: 'Important to know!',
    header: 'Channel communication rules',
    content: `According to Instagram policy, a company can message an Instagram user only after they contact your company's Instagram business account first. This way, you can only send a message to a client via Instagram if they:`,
    list: [
      'Send a direct message (DM) to your Instagram business account',
      'Tag your business account in their stories',
      `Reply to your business account's story`,
    ],
    hint: `Once a client contacts your Instagram business account, you have <b>24 hours</b> to send them a message. If you don't respond to this client in these 24 hours, you won't be able to do it afterward (unless they interact with your business account again).`,
  },
  button: {
    selectAccount: 'Select account',
    goToInstagram: 'Open Instagram',
  },
  accountError: {
    title: 'Error connecting account',
    message:
      'To connect your account to the Instagram channel in edna Pulse, you need to select the page linked to your Instagram account. <b>Settings need to be changed</b>',
    button: 'Try again',
  },
  accountExists: {
    title: 'This account is already connected to the channel',
    message:
      'You have already connected the selected account to edna Pulse Instagram feed. You can connect another account ',
  },
  error: {
    accountExists: 'This account is already connected to edna Pulse. Please select another one',
  },
};
