import React from 'react';

import {TOperator} from '@edna/utils/operators';

import {EFeatureFlag, EPermission, EResellerIds, ERoles} from './definitions';
import {useFeatureFlag, usePermission, useReseller, useRole} from './useAccess';

const FeatureGuard = React.memo(
  ({
    flag,
    children,
  }: {
    flag: EFeatureFlag | TOperator<EFeatureFlag>;
    children: React.ReactNode | (() => React.ReactNode);
  }) => {
    const hasFlag = useFeatureFlag(flag);

    if (!hasFlag) {
      return null;
    }

    return typeof children === 'function' ? children() : children;
  },
);

const PermissionGuard = React.memo(
  ({
    permissions,
    children,
  }: {
    permissions: EPermission | TOperator<EPermission>;
    children: React.ReactNode | (() => React.ReactNode);
  }) => {
    const hasPermission = usePermission(permissions);

    if (!hasPermission) {
      return null;
    }

    return typeof children === 'function' ? children() : children;
  },
);

const ResellerGuard = React.memo(
  ({
    resellerIds,
    children,
  }: {
    resellerIds: EResellerIds | TOperator<EResellerIds>;
    children: React.ReactNode | (() => React.ReactNode);
  }) => {
    const hasReseller = useReseller(resellerIds);

    if (!hasReseller) {
      return null;
    }

    return typeof children === 'function' ? children() : children;
  },
);

const RoleGuard = React.memo(
  ({
    roles,
    children,
  }: {
    roles: ERoles | TOperator<ERoles>;
    children: React.ReactNode | (() => React.ReactNode);
  }) => {
    const hasRole = useRole(roles);

    if (!hasRole) {
      return null;
    }

    return typeof children === 'function' ? children() : children;
  },
);

export {PermissionGuard, FeatureGuard, ResellerGuard, RoleGuard};
