import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {isSameDay} from 'date-fns/isSameDay';
import {startOfDay} from 'date-fns/startOfDay';
import {sub} from 'date-fns/sub';

import {notificationsEndpoints} from './api';
import {TFilters} from './definitions';

type TState = {
  filters: TAnyObject;
  periodBorder: {today?: number; yesterday?: number; earlier?: number};
};

const initialState: TState = {
  filters: {},
  periodBorder: {},
};

const slice = createSlice({
  name: 'notificationsHistory',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TFilters>) {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationsEndpoints.getNotifications.matchFulfilled,
      (state, {payload}) => {
        if (payload.first) {
          state.periodBorder = {};
        }

        if (!state.periodBorder.today) {
          const firstTodayItem = payload.content.find((item) =>
            isSameDay(new Date(item.occurredAt), new Date()),
          );

          state.periodBorder.today = firstTodayItem?.id;
        }

        const yesterday = sub(new Date(), {days: 1});

        if (!state.periodBorder.yesterday) {
          const firstYesterdayItem = payload.content.find((item) =>
            isSameDay(new Date(item.occurredAt), yesterday),
          );

          state.periodBorder.yesterday = firstYesterdayItem?.id;
        }

        if (!state.periodBorder.earlier) {
          const firstEarlierItem = payload.content.find(
            (item) => new Date(item.occurredAt) < startOfDay(yesterday),
          );

          state.periodBorder.earlier = firstEarlierItem?.id;
        }
      },
    );
  },
});

export const selectFilters = (state: TRootState) => state.notificationsHistory.filters as TFilters;
export const selectPeriodBorder = (state: TRootState) => state.notificationsHistory.periodBorder;
export const {setFilters: setFiltersAction} = slice.actions;

export default slice.reducer;
