export default {
  tariff: 'Тариф',
  acceptedAt: 'Дата принятия тарифа',
  activatedAt: 'Дата активации',
  updateTariff: 'Изменить тариф',
  downloadTariff: 'Скачать тариф',
  activeTariffFileName: '{{tenantId}}_{{tariffName}}_{{date}}_активный.xlsx',
  updateScheduled: 'Запланировано изменение тарифа с {{date}}.',
  showUpdate: 'Посмотреть изменение',
};
