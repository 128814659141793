import React from 'react';
import {useTranslation} from 'react-i18next';

import range from 'lodash/range';

import {Button} from '@edna/components';
import {Stack} from '@edna/components/primitives';

import {ButtonContent} from 'src/components/primitives';

import {
  ANIMATION_DURATION,
  EPosition,
  ETour,
  HIGHLIGHTER_PADDING,
  HIGHLIGHT_ATTRIBUTE,
  HIGHLIGHT_CLASSNAME,
} from './definitions';
import * as S from './style';
import {TOURS} from './tours';

const Stepper = React.memo<{count: number; currentIndex: number}>(({count, currentIndex}) => {
  return (
    <Stack size="1" direction="ROW" justify="center">
      {range(0, count).map((index) => (
        <S.StepperDot key={index} isActive={currentIndex === index} />
      ))}
    </Stack>
  );
});

const Hint = React.memo<{
  children: React.ReactNode;
  onBack: TEmptyFunction;
  onNext: TEmptyFunction;
  onClose: TEmptyFunction;
  stepIndex: number;
  stepsCount: number;
  position: EPosition;
}>(({children, onNext, onBack, onClose, stepIndex, stepsCount, position}) => {
  const {t} = useTranslation();

  return (
    <S.Hint position={position}>
      <Stack size="2" justify="stretch">
        <Stack justify="flex-end">
          <S.CloseIcon onClick={onClose} />
        </Stack>
        {children}
        <Stack size="4" justify="stretch">
          <Stepper count={stepsCount} currentIndex={stepIndex} />
          <div>
            <Stack direction="ROW" size="2" justify="flex-end">
              {stepIndex > 0 && (
                <Button appearance="secondary" onClick={onBack}>
                  <ButtonContent>
                    <S.LeftArrowIcon />
                    {t('Common:button.back')}
                  </ButtonContent>
                </Button>
              )}
              {stepIndex < stepsCount - 1 && (
                <Button appearance="primary" onClick={onNext}>
                  <ButtonContent>
                    {t('Common:button.continue')}
                    <S.RightArrowIcon />
                  </ButtonContent>
                </Button>
              )}
            </Stack>
          </div>
        </Stack>
      </Stack>
    </S.Hint>
  );
});

const EMPTY_ELEMENT = document.createElement('div');

const Tour = React.memo<{id: keyof typeof ETour; onClose: TEmptyFunction}>(({id, onClose}) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState<number>(0);

  const currentTour = TOURS[id];
  const currentStep = currentTour.steps[currentStepIndex];

  const currentElement = React.useMemo(
    () =>
      document.querySelector(`[${HIGHLIGHT_ATTRIBUTE}="${currentStep.onboardingId}"]`) ??
      EMPTY_ELEMENT,
    [currentStep],
  );

  const highlighterStyle = React.useMemo(() => {
    const {left, top, width, height} = currentElement.getBoundingClientRect();

    return {
      left: `${left - HIGHLIGHTER_PADDING}px`,
      top: `${top - HIGHLIGHTER_PADDING + document.documentElement.scrollTop}px`,
      width: `${width + HIGHLIGHTER_PADDING * 2}px`,
      height: `${height + HIGHLIGHTER_PADDING * 2}px`,
    };
  }, [currentElement]);

  React.useEffect(() => {
    const timerId = setTimeout(() => {
      currentElement.classList.add(HIGHLIGHT_CLASSNAME);
    }, ANIMATION_DURATION);

    return () => {
      clearTimeout(timerId);
      currentElement.classList.remove(HIGHLIGHT_CLASSNAME);
    };
  }, [highlighterStyle]);

  const handleNextStep = React.useCallback(() => {
    setCurrentStepIndex((prev) => prev + 1);
  }, []);

  const handlePrevStep = React.useCallback(() => {
    setCurrentStepIndex((prev) => prev - 1);
  }, []);

  return (
    <>
      <S.Overlay onClick={onClose} />
      <S.Highlighter style={highlighterStyle} />
      <S.HintReference style={highlighterStyle}>
        <Hint
          position={currentStep.position}
          stepsCount={currentTour.steps.length}
          stepIndex={currentStepIndex}
          onBack={handlePrevStep}
          onNext={handleNextStep}
          onClose={onClose}
        >
          {currentStep.content}
        </Hint>
      </S.HintReference>
    </>
  );
});

export {Tour};
