import styled, {css} from 'styled-components';

import {Button as BaseButton} from '@edna/components';
import {Column as BaseColumn, Row as BaseRow} from '@edna/components/primitives';
import {mixins} from '@edna/components/styles';

import {
  Badge as BaseBadge,
  Message as BaseMessage,
  Tab as BaseTab,
  Tabs as BaseTabs,
} from 'src/components';
import {RouterLink as BaseRouterLink, Text as BaseText} from 'src/components/primitives';
import {CopyIcon as BaseCopyIcon} from 'src/icons';

export const Row = styled(BaseRow)`
  ${mixins.flexAlign('flex-start')};
`;

export const Section = styled.section`
  margin-bottom: ${({theme}) => theme.spacing.s4};
`;

export const Tab = styled(BaseTab)`
  ${mixins.f4}
  font-weight: 500;
`;

export const Tabs = styled(BaseTabs)`
  margin-bottom: ${({theme}) => theme.spacing.s6};
`;

export const RouterLink = styled(BaseRouterLink)`
  &:hover {
    text-decoration: none;
  }
`;

export const StartColumn = styled(BaseColumn)`
  ${mixins.flexAlign('flex-start', 'flex-start')};
`;

export const SmallStartColumn = styled(StartColumn)`
  width: 50%;
  padding-right: ${({theme}) => theme.spacing.s8};
`;

export const Text = styled(BaseText)`
  max-width: 670px;
`;

export const Content = styled.div`
  margin-bottom: ${({theme}) => theme.spacing.s8};
`;

type TCenterRowProps = {
  hasTopIndent?: boolean;
};

export const CenterRow = styled.div<TCenterRowProps>`
  ${mixins.flexAlign('initial', 'center')}
  flex-flow: row wrap;
  margin-top: ${({theme, hasTopIndent}) => (hasTopIndent ? theme.spacing.s4 : 0)};
`;

type TButtonProps = {
  hasTopIndent?: boolean;
};

export const Button = styled(BaseButton)<TButtonProps>`
  ${({theme: {spacing}, hasTopIndent}) => css`
    margin: ${hasTopIndent ? spacing.s4 : 0} ${spacing.s2} 0 0;
  `}
`;

export const Badge = styled(BaseBadge)`
  ${({theme: {spacing}}) => css`
    margin: ${spacing.s3} ${spacing.s2} ${spacing.s2} 0;
  `};
`;

export const Error = styled.div`
  ${mixins.f2}
  ${({theme}) => css`
    color: ${theme.palette.red500};
    margin-top: ${theme.spacing.s1};
  `};
`;

export const Message = styled(BaseMessage)`
  width: 100%;
`;

export const CopyIcon = styled(BaseCopyIcon)`
  ${mixins.wh('20px')}
  cursor: pointer;
`;
