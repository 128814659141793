import {EBlrOperatorName, EOperatorName} from 'src/constants';
import {EResellerIds} from 'src/containers/User';

//TODO: удалить old после очисти фича-тоглов для смс канала
export enum EOldOperatorName {
  RuMts = 'russia-mts',
  RuBeeline = 'russia-beeline',
  RuTele2 = 'russia-tele2',
  RuMegafon = 'russia-megafon',
  RuYota = 'russia-yota',
  RuMotiv = 'russia-motiv', // Прочие операторы России
  RuDefault = 'russia-default', // Международные операторы
}

export enum ESubscriptionType {
  FEE = 'FEE',
  MULTI = 'MULTI',
  STEP = 'STEP',
  FEE_FIX = 'FEE_FIX',
  NONE = 'NONE',
}

export type TSubscriptionType = keyof typeof ESubscriptionType;

export type TOldSubscriptionByOperatorCode = Record<
  Exclude<EOldOperatorName, EOldOperatorName.RuYota>,
  boolean
>;
export type TSubscriptionByOperatorCode = Record<EOperatorName, TSubscriptionType | null>;

export type TOperatorSubscriptionFormData = {
  value: TSubscriptionType | null;
  name: EOperatorName;
};

export const RESELLERS_WITHOUT_SUBSCRIPTIONS = [
  EResellerIds.OSK_ID,
  EResellerIds.BLR_FIRST_ID,
  EResellerIds.BLR_SECOND_ID,
];

export type TSmsChannel = {
  id: number;
  name: string;
  createdAt: string;
  clientComment?: string | null;
  subject?: string | null;
  website?: string | null;
  exampleMessage?: string | null;
  broadcastCountries: string[];
  subjectRef?: string | null;
  updatedAt: string;
  status: EStatus;
  subscriptionByOperatorCode: TOldSubscriptionByOperatorCode | TSubscriptionByOperatorCode;
  tariffTemplateId?: number | null;
};
export type TOperatorSubscriptions = Record<EOperatorName | EBlrOperatorName, ESubscriptionType[]>;

export enum EStatus {
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
  CREATE_SUBJECT = 'CREATE_SUBJECT',
  DONE = 'DONE',
  CREATE_SUBJECT_ERROR = 'CREATE_SUBJECT_ERROR',
}

export type TFormData<
  TOperatorSubscription = TOldSubscriptionByOperatorCode | TOperatorSubscriptionFormData[],
> = {
  id?: TSmsChannel['id'];
  name: TSmsChannel['name'];
  subject: TSmsChannel['subject'];
  website?: TSmsChannel['website'];
  broadcastCountries?: TSmsChannel['broadcastCountries'];
  subscriptionByOperatorCode?: TOperatorSubscription;
  exampleMessage?: TSmsChannel['exampleMessage'];
  clientComment: TSmsChannel['clientComment'];
  tariffTemplateId?: TSmsChannel['tariffTemplateId'];
};
