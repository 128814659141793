import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {
  Modal,
  ModalActions,
  ModalCancelButton,
  ModalContent,
  ModalHeader,
  ModalSubmitButton,
  showModal,
} from '@edna/components';

import {ModalCenterImage, SecondaryText} from 'src/components/primitives';
import {BlockImage} from 'src/images';

import {
  useChargeAutopaymentMutation,
  useLazyStripeRedirectQuery,
  useOffAutopaymentMutation,
} from './api';

type TModalProps = {
  closeModal: TEmptyFunction;
};

const OffAutopaymentModal = React.memo<TModalProps>(({closeModal}) => {
  const {t} = useTranslation();

  const [offAutopayment] = useOffAutopaymentMutation();

  const handleOff = React.useCallback(() => {
    offAutopayment();
    closeModal();
  }, []);

  return (
    <Modal width="430px">
      <ModalHeader>{t('Autopayment:offModal.title')}</ModalHeader>
      <ModalContent>
        <SecondaryText>{t('Autopayment:offModal.subtitle')}</SecondaryText>
      </ModalContent>
      <ModalCenterImage as={BlockImage} />
      <ModalActions>
        <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
        <ModalSubmitButton onClick={handleOff}>
          {t('Autopayment:offModal.submitButton')}
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
});

const AutopaymentSupportModal = React.memo<TModalProps & {isActivate: boolean}>(
  ({closeModal, isActivate}) => {
    const {t} = useTranslation();
    const {id: supportTenantId} = useParams<'id'>();

    const [offAutopayment] = useOffAutopaymentMutation();

    const toggleAutopayment = React.useCallback(() => {
      if (isActivate && supportTenantId) {
        // TODO: https://jira.edna.ru/browse/EP-4311 Добавить функцию включения автоплатежа
        closeModal();

        return;
      }

      offAutopayment(supportTenantId);
      closeModal();
    }, [closeModal, supportTenantId, isActivate]);

    return (
      <Modal width="530px">
        <ModalHeader>
          {t(`Autopayment:supportModal.title.${isActivate ? 'on' : 'off'}`)}
        </ModalHeader>
        <ModalContent>
          <SecondaryText>{t('Autopayment:supportModal.subtitle')}</SecondaryText>
        </ModalContent>
        <ModalActions>
          <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
          <ModalSubmitButton onClick={toggleAutopayment}>
            {t(`Autopayment:supportModal.submitButton.${isActivate ? 'on' : 'off'}`)}
          </ModalSubmitButton>
        </ModalActions>
      </Modal>
    );
  },
);

const ChangeAutopaymentCreditsModal = React.memo<TModalProps>(({closeModal}) => {
  const {t} = useTranslation();

  const [redirectToStripe] = useLazyStripeRedirectQuery();

  const handleChangeCredits = React.useCallback(async () => {
    try {
      const result = await redirectToStripe().unwrap();

      if (result?.stripeUrl) {
        window.open(result.stripeUrl, '_blank', 'noreferrer');
      }

      closeModal();
    } catch (e) {}
  }, [closeModal]);

  return (
    <Modal width="530px">
      <ModalHeader>{t('Autopayment:changeCardCreditsModal.title')}</ModalHeader>
      <ModalContent>
        <SecondaryText>{t('Autopayment:changeCardCreditsModal.subtitle')}</SecondaryText>
      </ModalContent>
      <ModalActions>
        <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
        <ModalSubmitButton onClick={handleChangeCredits}>
          {t('Common:button.change')}
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
});

const ChargeAutopaymentModal = React.memo<TModalProps>(({closeModal}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const [chargeAutopayment] = useChargeAutopaymentMutation();

  const handleChargeAutopayment = React.useCallback(() => {
    if (supportTenantId) {
      chargeAutopayment(supportTenantId);
      closeModal();
    }
  }, [closeModal, supportTenantId]);

  return (
    <Modal width="530px">
      <ModalHeader>{t('Autopayment:chargeAutopaymentModal.title')}</ModalHeader>
      <ModalContent>
        <SecondaryText>{t('Autopayment:chargeAutopaymentModal.subtitle')}</SecondaryText>
      </ModalContent>
      <ModalActions>
        <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
        <ModalSubmitButton onClick={handleChargeAutopayment}>
          {t('Autopayment:chargeAutopaymentModal.submitButton')}
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
});

const showAutopaymentSupportModal = (isActivate: boolean) =>
  showModal((closeModal) => (
    <AutopaymentSupportModal closeModal={closeModal} isActivate={isActivate} />
  ));

const showOffAutopaymentModal = () =>
  showModal((closeModal) => <OffAutopaymentModal closeModal={closeModal} />);

const showChangeAutopaymentCreditsModal = () =>
  showModal((closeModal) => <ChangeAutopaymentCreditsModal closeModal={closeModal} />);

const showChargeAutopaymentModal = () =>
  showModal((closeModal) => <ChargeAutopaymentModal closeModal={closeModal} />);

export {
  showChangeAutopaymentCreditsModal,
  showOffAutopaymentModal,
  showAutopaymentSupportModal,
  showChargeAutopaymentModal,
};
