import {createSlice} from '@reduxjs/toolkit';

import {EChannelType} from 'src/constants';

import {TWhatsAppFormData} from '../definitions';
import {getInitialValues} from '../utils';
import {EOperatorFormStep, TFormStep, TSteps, defaultOperatorSteps} from './definitions';

type TState = {
  activeStep: TFormStep;
  steps: TSteps;
  messageMatcher: TWhatsAppFormData;
};

const defaultMessageMatcher = getInitialValues(EChannelType.WHATSAPP) as TWhatsAppFormData;

const initialState: TState = {
  activeStep: EOperatorFormStep.DETAILS,
  steps: defaultOperatorSteps,
  messageMatcher: defaultMessageMatcher,
};

const whatsAppMessageMatcherSlice = createSlice({
  name: 'whatsAppMessageMatcherSlice',
  initialState,
  reducers: {
    setMessageMatcher(state, {payload}) {
      state.messageMatcher = payload;
    },
    resetMessageMatcher(state) {
      state.messageMatcher = defaultMessageMatcher;
    },
    setActiveStep(state, {payload}) {
      state.activeStep = payload;
    },
    setSteps(state, {payload}) {
      state.steps = payload;
    },
  },
});

export const {
  setActiveStep: setActiveStepAction,
  setSteps: setStepsAction,
  resetMessageMatcher,
  setMessageMatcher,
} = whatsAppMessageMatcherSlice.actions;

export const selectMessageMatcher = (state: TRootState) =>
  state.whatsAppMessageMatcher.messageMatcher;
export const selectSteps = (state: TRootState) => state.whatsAppMessageMatcher.steps;
export const selectActiveStep = (state: TRootState) => state.whatsAppMessageMatcher.activeStep;

export default whatsAppMessageMatcherSlice.reducer;
