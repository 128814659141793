import {initReactI18next} from 'react-i18next';

import i18n from 'i18next';

import formatDate from '@edna/utils/formatDate';
import {formatNumber} from '@edna/utils/formatMoney';
import storage from '@edna/utils/storage';

import {ELanguage, EStorageKeys, isEnDomain} from 'src/constants';
import {axiosInstance} from 'src/models';

import {getCurrentLang} from '../utils';
import resources from './resources';

const currentLang = getCurrentLang() as ELanguage;

const fallbackLng = (() => {
  if (isEnDomain()) {
    return ELanguage.en;
  }

  return ELanguage.ru;
})();

const initialLang = Object.values(ELanguage).includes(currentLang) ? currentLang : fallbackLng;

i18n.use(initReactI18next).init({
  lng: initialLang,
  fallbackLng,
  interpolation: {
    escapeValue: false, // not needed for react
    format(value, format) {
      if (format === 'number' && typeof value === 'number') {
        return formatNumber(value);
      }

      if (format === 'date' && value instanceof Date) {
        return formatDate(value, {format});
      }

      return value;
    },
  },
  react: {
    useSuspense: false,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'b', 'i', 'a', 'strong'],
  },
  cleanCode: true,
  lowerCaseLng: true,
  resources,
});

i18n.on('languageChanged', (lang) => {
  storage.set(EStorageKeys.LANG, lang);
  axiosInstance.defaults.headers.common['Accept-Language'] = lang.toLocaleUpperCase();
  document.documentElement.setAttribute('lang', lang);
});

i18n.changeLanguage(initialLang);

if (process.env.MOCK_I18) {
  i18n.t = (key) => key;
}

export default i18n;
