import {NavLink} from 'react-router-dom';

import styled, {css} from 'styled-components';

import {Collapse as BaseCollapse} from '@edna/components';
import {mixins} from '@edna/components/styles';
import KeyboardArrowDownIcon from '@edna/icons/keyboardArrowDown.svg';

import {CLOSED_MENU_WIDTH} from 'src/components';

export const SuspenseLoading = styled.div`
  margin-top: ${({theme}) => theme.spacing.s12};
`;

type TMainProps = {
  opened: boolean;
};

const getMainStyle = ({theme: {dimensions, animation}, opened}: TTheme & TMainProps) => {
  const menuSize = opened ? dimensions.asideNavWidth : CLOSED_MENU_WIDTH;

  return css`
    width: calc(100% - ${menuSize}px);
    margin-left: ${menuSize}px;
    transition: margin ${animation.duration}ms;
    padding-left: calc(100vw - 100%);
  `;
};

export const Main = styled.main<TMainProps>`
  ${getMainStyle}
`;

export const MainContent = styled.div<TMainProps>`
  ${({
    theme: {
      spacing: {s12, s8},
      dimensions,
    },
    opened,
  }) => {
    const menuSize = opened ? dimensions.asideNavWidth : CLOSED_MENU_WIDTH;

    return css`
      ${mixins.wh('100%')}
      position: relative;
      display: flex;
      flex-flow: column;
      max-width: ${dimensions.maxWidthLayout}px;
      margin: 0 auto;
      padding: ${s8} calc(${s12} + ${menuSize}px - 100vw + 100%) ${s8} ${s12};

      @media (min-width: ${menuSize + dimensions.maxWidthLayout}px) {
        margin-left: auto;
        padding-right: ${s12};
      }
    `;
  }}
`;

const label = css`
  min-width: 52px;
  ${mixins.flexAlign('space-between', 'center')}
  ${mixins.f3}
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;

  ${({theme: {spacing, animation, palette, borderRadius}}) => css`
    margin: 0 10px;
    padding: ${spacing.s5} 14px ${spacing.s5} 10px;
    color: currentColor;
    border-radius: ${borderRadius.br2};
    transition:
      color ${animation.duration}ms,
      background-color ${animation.duration}ms;

    &:hover {
      background-color: ${palette.grey800};
      color: ${palette.grey200};
    }

    &:active {
      background-color: ${palette.black};
    }

    &.active {
      color: ${palette.white};
      background-color: ${palette.black};
    }
  `}
`;

export const MenuItem = styled(NavLink)`
  ${label}
`;
export const NestedMenuItem = styled(MenuItem)`
  margin: 0;

  ${({theme: {spacing}}) => css`
    padding: ${spacing.s3} ${spacing.s4} ${spacing.s3} 52px;
  `}
`;

type TArrowIconProps = {
  open: boolean;
};

type TCollapseLabelProps = {
  open: boolean;
};

export const CollapseLabel = styled.div<TCollapseLabelProps>`
  ${label};
  margin: 0;
  padding-right: ${({theme}) => theme.spacing.s2};
`;

export const ArrowIcon = styled(KeyboardArrowDownIcon)<TArrowIconProps>`
  ${mixins.wh('18px')}
  transform: rotate(${({open}: TArrowIconProps) => (open ? 0 : -90)}deg);
`;

export const Collapse = styled(BaseCollapse)`
  overflow: hidden;
  flex-shrink: 0;
  ${({theme: {branding, spacing, borderRadius}}) => css`
    margin: 0 ${spacing.s3};
    background-color: ${branding.content.primary};
    border-radius: ${borderRadius.br2};
  `}
`;

export const LinkText = styled.span`
  margin-right: auto;
  white-space: nowrap;
  margin-left: ${({theme}) => theme.spacing.s4};
`;

export const LinkIcon = styled.div`
  ${mixins.wh('16px')}
  flex-shrink: 0;
  margin: ${({theme}) => theme.spacing.s05};
`;
