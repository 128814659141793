import {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

export const link = css`
  font-weight: 500;
  cursor: pointer;

  ${({theme: {palette, branding, animation}}) => css`
    color: ${branding.content.primary};
    transition: color ${animation.duration}ms;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &:active {
      color: ${palette.grey700};
    }
    &:disabled {
      color: ${palette.grey50};
    }
  `}
`;

export const bullet = css`
  ${({theme: {spacing, branding}}) => css`
    padding-left: ${spacing.s7};
    position: relative;
    &::before {
      ${mixins.wh('12px')}
      ${mixins.position({
        position: 'absolute',
        top: '4px',
        left: 0,
      })}
      content: '';
      border-radius: 50%;
      background: ${branding.content.primary};
    }
  `}
`;
