import React from 'react';
import {useTranslation} from 'react-i18next';

import {ELanguage, isProdDomain} from 'src/constants';

import {
  EFacebookStatus,
  FACEBOOK_SDK_ID,
  TFacebook,
  TFacebookPayload,
  TLoginOptions,
  TLoginResponse,
} from './definitions';

const loadDynamicScript = (
  language: string,
  callback?: TEmptyFunction,
  errorCallback?: TEmptyFunction,
) =>
  new Promise(async (resolve) => {
    if (document.getElementById(FACEBOOK_SDK_ID)) {
      await callback?.();
      resolve(true);

      return;
    }

    const script = document.createElement('script');
    const languageCode = language === ELanguage.ru ? 'RU' : 'US';

    script.src = `https://connect.facebook.net/${language}_${languageCode}/sdk.js`;
    script.id = FACEBOOK_SDK_ID;

    script.onload = () => {
      callback?.();
      resolve(false);
    };

    script.onerror = () => {
      errorCallback?.();
      resolve(false);
    };

    document.head.append(script);
  });

const handleFBLogin = (options: TLoginOptions, onError?: TEmptyFunction) =>
  new Promise<TLoginResponse>((resolve) => {
    try {
      FB.login(
        (response) => {
          console.log('FB response:', response);

          if (response.status === 'connected' && response?.authResponse) {
            resolve({result: response.authResponse});

            return;
          }

          resolve({error: response});
        },
        {
          response_type: 'code',
          override_default_response_type: true,
          ...options,
        },
      );
    } catch (e) {
      resolve({});
      onError?.();
    }
  });

const useFacebookLogin = ({config, onError}: TFacebookPayload): TFacebook => {
  const {i18n} = useTranslation();
  const abortControllerRef = React.useRef(new AbortController());

  const [status, setStatus] = React.useState<EFacebookStatus>(EFacebookStatus.LOADING);

  const handleInit = () => {
    try {
      FB.init({
        appId: isProdDomain() ? config.PROD_APP_ID : config.DEV_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v19.0',
      });
    } catch (e) {
      onError?.();
      setStatus(EFacebookStatus.ERROR);
    }
  };

  const handleLogin = (options: TLoginOptions) =>
    handleFBLogin(options, () => setStatus(EFacebookStatus.ERROR));

  const checkConnection = async () => {
    try {
      setStatus(EFacebookStatus.LOADING);

      await loadDynamicScript(i18n.language, handleInit, onError);

      FB.api(`/${isProdDomain() ? config.PROD_APP_ID : config.DEV_APP_ID}`, function (response) {
        if (abortControllerRef.current?.signal.aborted) {
          return;
        }

        const responseError =
          typeof response === 'object' && !!response && 'error' in response ? response.error : null;

        if (responseError) {
          setStatus(EFacebookStatus.ERROR);
        } else {
          setStatus(EFacebookStatus.SUCCESS);
        }
      });
    } catch (e) {
      setStatus(EFacebookStatus.ERROR);
    }
  };

  React.useEffect(() => {
    checkConnection();

    return () => abortControllerRef.current?.abort();
  }, []);

  return {login: handleLogin, checkConnection, status};
};

export {useFacebookLogin, handleFBLogin};
