import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';

import HelpIcon from '@edna/icons/help.svg';

import {useNavigateToReportByBroadcastId} from 'src/containers/Report/hooks';
import {useDownloadFile} from 'src/containers/ServerFile/hooks';
import {useGetTenantDataQuery} from 'src/containers/User';
import {useEdnaLinks} from 'src/hooks';
import {AudienceIcon, DownloadIcon} from 'src/icons';
import PATHS from 'src/paths';
import {parseISO} from 'src/utils';

import {
  EAutopaymentNotificationStatus,
  EWebNotificationType,
  TAgreedChangeTariffRequestNotification,
  TBroadcastNotification,
  TChannelProfileMessagingLimitChangedNotification,
  TChannelProfileQualityScoreChangedNotification,
  TChannelProfileQualityStatusChangedNotification,
  TCompanyDetailsUpdatedNotification,
  TJivoNotification,
  TLicenseExpiringNotification,
  TMessageMatcherCategoryChangedNotification,
  TMessageMatcherStatusChangedNotification,
  TNotAgreedChangeTariffRequestNotification,
  TPaymentDetailsUpdatedNotification,
  TStripeAutopaymentNotification,
  TSubscribersUploadNotification,
  TTenantTariffAcceptedNotification,
  TWebNotification,
} from '../model';
import * as S from '../style';

type TProps = {
  item: TWebNotification;
};

type TCommonNotificationProps = {
  type: EWebNotificationType;
  values?: TAnyObject;
  children?: React.ReactNode;
};

const CommonNotification = React.memo<TCommonNotificationProps>(({values, type, children}) => (
  <>
    <Trans i18nKey={`Notifications:web.${type}`} values={values}>
      <S.Link to={PATHS.NOTIFICATIONS} as={RouterLink} />
    </Trans>
    {children}
  </>
));

const SubscribersUpload = React.memo<{item: TSubscribersUploadNotification}>(
  ({item: {meta, type}}) => {
    const {t} = useTranslation();
    const subscriberUpload = meta?.subscriberUpload;
    const downloadFile = useDownloadFile();
    const links = useEdnaLinks();

    const handleDownloadFile = () => {
      if (!subscriberUpload?.errorReportFileName) {
        return;
      }

      downloadFile(subscriberUpload.errorReportFileName, subscriberUpload.fileName);
    };

    let button = null;

    if (type === EWebNotificationType.SubscribersUploadFailed) {
      button = (
        <S.Button as="a" href={`mailto:${links.SUPPORT_EMAIL}`}>
          <S.ButtonIcon as={HelpIcon} />
          {t('Notifications:button.contactTechnicalSupport')}
        </S.Button>
      );
    }

    if (type === EWebNotificationType.SubscribersUploadPartial) {
      button = (
        <S.Button onClick={handleDownloadFile}>
          <S.ButtonIcon as={DownloadIcon} />
          {t('Notifications:button.downloadErrorFile')}
        </S.Button>
      );
    }

    if (type === EWebNotificationType.SubscribersUploadSuccess) {
      button = (
        <S.Button as={RouterLink} to={PATHS.AUDIENCE}>
          <S.ButtonIcon as={AudienceIcon} />
          {t('Notifications:button.toAudienceSection')}
        </S.Button>
      );
    }

    return <CommonNotification type={type}>{button}</CommonNotification>;
  },
);

const Broadcast = React.memo<{item: TBroadcastNotification}>(
  ({
    item: {
      meta: {broadcast},
      type,
    },
  }) => {
    const {t} = useTranslation();

    const goToReport = useNavigateToReportByBroadcastId({broadcastId: broadcast?.id});

    return (
      <CommonNotification type={type} values={broadcast}>
        <S.Button onClick={goToReport}>
          <S.ButtonIcon as={AudienceIcon} />
          {t('Notifications:button.toReportBroadcast')}
        </S.Button>
      </CommonNotification>
    );
  },
);

const License = React.memo<{item: TLicenseExpiringNotification}>(
  ({
    item: {
      type,
      meta: {licenseExpiring},
    },
  }) => {
    const {data: tenantData} = useGetTenantDataQuery();
    const personalManagerName = tenantData?.personalManagerName ?? null;
    const personalManagerEmail = tenantData?.personalManagerEmail ?? null;

    if (type === EWebNotificationType.LicenseExpiring && licenseExpiring !== undefined) {
      const date = parseISO(licenseExpiring.expireAt, 'dd.MM.yyyy');

      return (
        <Trans
          i18nKey={`Notifications:web.${type}`}
          values={{
            date,
            name: personalManagerName,
            email: personalManagerEmail,
          }}
        >
          <S.Link href={`mailto:${personalManagerEmail}`} />
        </Trans>
      );
    }

    return null;
  },
);

const TenantDetailsUpdates = React.memo<{
  item: TCompanyDetailsUpdatedNotification | TPaymentDetailsUpdatedNotification;
}>(({item}) => {
  const login =
    item.type === EWebNotificationType.CompanyDetailsUpdated
      ? item.meta.updateCompanyDetails.login
      : item.meta.updatePaymentDetails.login;

  return <CommonNotification type={item.type} values={{login}} />;
});

const TenantAcceptance = React.memo<{item: TTenantTariffAcceptedNotification}>(({item}) => (
  <CommonNotification type={item.type} values={{login: item.meta.acceptTenantTariff.login}} />
));

const TariffChange = React.memo<{
  item: TAgreedChangeTariffRequestNotification | TNotAgreedChangeTariffRequestNotification;
}>(({item}) => {
  const values =
    item.type === EWebNotificationType.AgreedChangeTariffRequest
      ? {
          login: item.meta.agreedChangeTariffRequest.login,
          tariffName: item.meta.agreedChangeTariffRequest.tariffName,
        }
      : {
          login: item.meta.notAgreedChangeTariffRequest.login,
          tariffName: item.meta.notAgreedChangeTariffRequest.tariffName,
        };

  return <CommonNotification type={item.type} values={values} />;
});

const Jivo = React.memo<{item: TJivoNotification}>(({item}) => (
  <CommonNotification type={item.type} values={{login: item.meta?.updateJivoIntegration?.login}} />
));

const MessageMatcher = React.memo<{
  item: TMessageMatcherStatusChangedNotification | TMessageMatcherCategoryChangedNotification;
}>(({item}) => {
  const {t} = useTranslation();

  if (item.type === EWebNotificationType.MessageMatcherStatusChanged) {
    const {name, approveStatus} = item.meta?.messageMatcher;

    return (
      <Trans i18nKey={`Notifications:web.${item.type}.${approveStatus}`} values={{name}}>
        <S.Link to={PATHS.NOTIFICATIONS} as={RouterLink} />
      </Trans>
    );
  }

  if (item.type === EWebNotificationType.MessageMatcherCategoryChanged) {
    const {name, newCategory} = item.meta?.messageMatcherCategoryChanged;

    return (
      <CommonNotification
        type={item.type}
        values={{
          name,
          newCategory: t(`MessageMatchers:editForm.categories.${newCategory}`),
        }}
      />
    );
  }

  return null;
});

const StripeAutopayment = React.memo<{item: TStripeAutopaymentNotification}>(
  ({item: {meta, type}}) => {
    const links = useEdnaLinks();

    if (!meta?.checkoutData) {
      return null;
    }

    if (meta.checkoutData.status === EAutopaymentNotificationStatus.PAYMENT_FAILED) {
      return (
        <Trans
          i18nKey={`Notifications:web.${type}.${meta.checkoutData.declineCode}`}
          values={{
            date: parseISO(meta.checkoutData.occurredAt, 'dd.MM.yyyy'),
            email: links.SUPPORT_EMAIL,
          }}
        >
          <S.Link to={PATHS.NOTIFICATIONS} as={RouterLink} />
          <S.Link href={`mailto:${links.SUPPORT_EMAIL}`} />
        </Trans>
      );
    }

    return (
      <Trans i18nKey={`Notifications:web.${type}.${meta.checkoutData.status}`}>
        <S.Link to={PATHS.NOTIFICATIONS} as={RouterLink} />
        <S.Link href={meta.checkoutData.hostedReceiptUrl} target="_blank" />
      </Trans>
    );
  },
);

const ChannelProfile = React.memo<{
  item:
    | TChannelProfileQualityScoreChangedNotification
    | TChannelProfileMessagingLimitChangedNotification
    | TChannelProfileQualityStatusChangedNotification;
}>(({item}) => {
  const {t} = useTranslation();

  let values = {};

  if (item.type === EWebNotificationType.ChannelProfileQualityScoreChanged) {
    const {name, qualityScore} = item.meta.whatsAppProfileQualityScoreChanged;

    values = {name, qualityScore: t(`Channels:qualityScore.${qualityScore}`).toLowerCase()};
  }

  if (item.type === EWebNotificationType.ChannelProfileMessagingLimitChanged) {
    const {name, messagingLimit} = item.meta.whatsAppProfileMessagingLimitChanged;

    values = {name, messagingLimit: t(`Channels:limit.${messagingLimit}`).toLowerCase()};
  }

  if (item.type === EWebNotificationType.ChannelProfileQualityStatusChanged) {
    const {name, qualityStatus} = item.meta.whatsAppProfileQualityStatusChanged;

    values = {name, qualityStatus: t(`Channels:qualityStatus.${qualityStatus}`).toLowerCase()};
  }

  return <CommonNotification type={item.type} values={values} />;
});

const WebNotification = React.memo<TProps>(({item}) => {
  switch (item.type) {
    case EWebNotificationType.SubscribersUploadSuccess:
    case EWebNotificationType.SubscribersUploadFailed:
    case EWebNotificationType.SubscribersUploadPartial:
      return <SubscribersUpload item={item} />;

    case EWebNotificationType.BroadcastCancelled:
    case EWebNotificationType.BroadcastCompleted:
      return <Broadcast item={item} />;

    case EWebNotificationType.LicenseExpiring:
      return <License item={item} />;

    case EWebNotificationType.CompanyDetailsUpdated:
    case EWebNotificationType.PaymentDetailsUpdated:
      return <TenantDetailsUpdates item={item} />;

    case EWebNotificationType.TenantTariffAccepted:
      return <TenantAcceptance item={item} />;

    case EWebNotificationType.AgreedChangeTariffRequest:
    case EWebNotificationType.NotAgreedChangeTariffRequest:
      return <TariffChange item={item} />;

    case EWebNotificationType.JivoEnabled:
    case EWebNotificationType.JivoDisabled:
      return <Jivo item={item} />;

    case EWebNotificationType.MessageMatcherStatusChanged:
    case EWebNotificationType.MessageMatcherCategoryChanged:
      return <MessageMatcher item={item} />;

    case EWebNotificationType.StripeCheckout:
      return <StripeAutopayment item={item} />;

    case EWebNotificationType.ChannelProfileQualityScoreChanged:
    case EWebNotificationType.ChannelProfileMessagingLimitChanged:
    case EWebNotificationType.ChannelProfileQualityStatusChanged:
      return <ChannelProfile item={item} />;

    case EWebNotificationType.CallbackUpdated:
    case EWebNotificationType.ApikeyUserUpdated:
    case EWebNotificationType.InvoicePaid:
      return <CommonNotification type={item.type} />;

    case EWebNotificationType.SubscribersUploadLoading:
    default:
      return null;
  }
});

WebNotification.displayName = 'WebNotification';

export {WebNotification};
