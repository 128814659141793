import {EOperatorName} from 'src/constants';

import {EStatus, ESubscriptionType} from './definitions';

export default {
  title: 'Channel registration form',
  subject: 'Sender name',
  website: 'Your company website',
  broadcastCountries: 'Destination countries',
  exampleMessage: 'Message text example',
  name: 'Internal channel name',
  subscriptionByOperatorCode: 'Sending conditions for this sender (must fill all statements)',
  subscriptionByOperatorCodeLabel: 'For {{name}} subscribers',
  subscriptionByOperatorCodeLabelRu: 'For subscribers of other operators in Russia',
  subscriptionByOperatorCodeLabelIo: 'For subscribers of international operators',
  subscriptionByOperatorCodeRadio1: 'Fixed price',
  subscriptionByOperatorCodeRadio2:
    'Non-fixed price with monthly payment of a subscription fee to the operator',
  clientComment: 'Comment',
  status: {
    [EStatus.DRAFT]: 'Draft',
    [EStatus.CREATE_SUBJECT]: 'Create subject',
    [EStatus.CREATE_SUBJECT_ERROR]: 'Create subject error',
    [EStatus.IN_PROGRESS]: 'Under consideration',
    [EStatus.REJECTED]: 'Disabled',
    [EStatus.DONE]: 'Correct',
  },
  options: {
    [ESubscriptionType.FEE]: 'Stepped billing of messages with subscription fee',
    [ESubscriptionType.MULTI]: 'Fixed message cost without subscription fee',
    [ESubscriptionType.STEP]: '[en] Тариф с абонентской платой и степ-тарификацией',
    [ESubscriptionType.FEE_FIX]: 'Fixed message cost with subscription fee',
    [ESubscriptionType.NONE]: "Don't connect",
  },
  yotaOptions: {
    [ESubscriptionType.MULTI]: ' Fixed message cost without subscription fee, specified name',
    [ESubscriptionType.STEP]: 'Fixed message cost without subscription fee, default name',
  },
  operators: {
    [EOperatorName.RuMts]: {
      [ESubscriptionType.FEE]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified by default. It doesn’t match the name from the application form.',
      [ESubscriptionType.STEP]:
        '[en] Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuBeeline]: {
      [ESubscriptionType.FEE]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified in the application form.',
      [ESubscriptionType.STEP]:
        '[en] Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuTele2]: {
      [ESubscriptionType.FEE]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified in the application form.',
      [ESubscriptionType.STEP]:
        '[en] Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuMegafon]: {
      [ESubscriptionType.FEE]:
        'Messages are sent from the name that you enter in this form. Your subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified by default. It doesn’t match the name from the application form.',
      [ESubscriptionType.STEP]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuMotiv]: {
      [ESubscriptionType.FEE]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified in the application form.',
      [ESubscriptionType.STEP]:
        '[en] Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuKtelecom]: {
      [ESubscriptionType.FEE]:
        'Messages are sent from the name that you enter in this form. Your subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified by default. It doesn’t match the name from the application form.',
      [ESubscriptionType.STEP]:
        '[en] Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuYota]: {
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified in the application form.',
      [ESubscriptionType.STEP]:
        'Messages are sent on behalf of the name specified by default. It doesn’t match the name from the application form.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuVainahtelecom]: {
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified by default. It doesn’t match the name from the application form.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuKtktelecom]: {
      [ESubscriptionType.FEE]:
        'Messages are sent from the name that you enter in this form. Your subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified by default. It doesn’t match the name from the application form.',
      [ESubscriptionType.STEP]:
        '[en] Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuSbertelecom]: {
      [ESubscriptionType.FEE]:
        'Messages are sent from the name that you enter in this form. Your subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified in the application form.',
      [ESubscriptionType.STEP]:
        '[en] Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Messages are sent on behalf of the name specified in the application form. The subscription fee is charged monthly regardless of traffic.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.RuDefault]: {
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified in the application form.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to this operator numbers. We won't charge you if you attempt broadcasting to these numbers.",
    },
    [EOperatorName.IoDefault]: {
      [ESubscriptionType.MULTI]:
        'Messages are sent on behalf of the name specified in the application form.',
      [ESubscriptionType.NONE]:
        "You won't be able to send messages to international operators numbers. We won't charge you if you attempt broadcasting to these numbers.",
      message: 'This direction is available in agreement with your manager',
    },
  },
  subscriptionsMessage: `To register a channel, fill in the form below.<br/> For a list of available operators and connection details, contact you account manager`,
  registrationMessage: `This SMS channel form was sent for registration. You will receive an email when the status changes`,
  channelDisabled: `This channel was disabled by you operator. Ask your manager for details`,
};
