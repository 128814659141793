export default {
  tariff: 'Pricing',
  acceptedAt: 'Pricing acceptance date',
  activatedAt: 'Activation date',
  updateTariff: 'Update tariff',
  downloadTariff: 'Download tariff',
  activeTariffFileName: '[en]_{{tenantId}}_{{tariffName}}_{{date}}_активный.xlsx',
  updateScheduled: '[en] Запланировано изменение тарифа с {{date}}.',
  showUpdate: '[en] Посмотреть изменение',
};
