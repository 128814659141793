export enum EUrlType {
  STATUS = 'STATUS',
  IN_MESSAGE = 'IN_MESSAGE',
  MESSAGE_MATCHER = 'MESSAGE_MATCHER',
}

export type TUrlType = keyof typeof EUrlType;

export type TApiKeyData = {
  id: number;
  apiKey: string;
  locked: boolean;
};

export type TCallbackUrl = {
  id?: number;
  locked: boolean;
  type: TUrlType;
  url: string;
};

export type TCallbackAuth = {
  id: number;
  activated: boolean;
};

export enum ECompanyDataErrors {
  LICENSE_NOT_FOUND = 'license.not-found',
  BALANCE_NOT_FOUND = 'balance.not-found',
  CALLBACK_URL_INCORRECT = 'callback.url.incorrect',
  APIKEY_NOT_FOUND = 'apikey.not-found',
}
