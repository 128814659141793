import styled from 'styled-components';

import {TextInput} from '@edna/components';
import {mixins} from '@edna/components/styles';
import BaseSearchIcon from '@edna/icons/search.svg';

export const Input = styled(TextInput)`
  max-width: 100%;
`;

export const SearchIcon = styled(BaseSearchIcon)`
  ${mixins.wh('16px')}
  flex-shrink: 0;
  margin-right: ${({theme}) => theme.spacing.s2};
`;
