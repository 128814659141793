import React from 'react';
import {useTranslation} from 'react-i18next';

import {ESize, TSize} from '@edna/components';

import {useGetTenantLeadDetailsQuery} from 'src/containers/CompanyDetails/api';

import * as S from '../style';
import {TBilledEventData} from './definitions';

type TProps = {
  size?: TSize;
  billedEventData: TBilledEventData;
};

export const BilledEventDetails = React.memo<TProps>(({size = ESize.S, billedEventData}) => {
  const {t} = useTranslation();

  const {data: tenantLeadDetails} = useGetTenantLeadDetailsQuery(
    billedEventData.debtTransaction?.tenantId ?? billedEventData.transaction?.tenantId,
  );

  if (!tenantLeadDetails) {
    return null;
  }

  const {billedEvent, debtTransaction, transaction} = billedEventData;

  return (
    <S.Column>
      <S.InfoRow size={size}>
        <S.DetailsLabel>{t('Corrections:payments.tenantId')}</S.DetailsLabel>
        {debtTransaction?.tenantId ?? transaction?.tenantId}
      </S.InfoRow>
      <S.InfoRow size={size}>
        <S.DetailsLabel>{t('Corrections:payments.companyName')}</S.DetailsLabel>
        {tenantLeadDetails.companyName}
      </S.InfoRow>
      <S.InfoRow size={size}>
        <S.DetailsLabel>{t('Corrections:payments.paymentType')}</S.DetailsLabel>
        {billedEvent?.originalEventType}
      </S.InfoRow>
      <S.InfoRow size={size}>
        <S.DetailsLabel>{t('Corrections:payments.amount')}</S.DetailsLabel>
        {transaction?.amount ?? debtTransaction?.amount}
      </S.InfoRow>
      <S.InfoRow size={size}>
        <S.DetailsLabel>{t('Corrections:payments.currency')}</S.DetailsLabel>
        {transaction?.currency ?? debtTransaction?.currency}
      </S.InfoRow>
    </S.Column>
  );
});

BilledEventDetails.displayName = 'BilledEventDetails';
