import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {EStorageKeys} from 'src/constants';
import {listenerMiddleware} from 'src/models';

import {TFilters} from './definitions';

type TState = {
  filters: TAnyObject;
};

const initialState = (): TState => {
  const rememberedFilters = sessionStorage.getItem(EStorageKeys.MESSAGE_MATCHERS_FILTERS);

  return {
    filters: rememberedFilters ? JSON.parse(rememberedFilters) : {},
  };
};

const messageMatchersSlice = createSlice({
  name: 'messageMatchers',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TFilters>) {
      state.filters = action.payload;
    },
  },
});

export const {setFilters: setFiltersAction} = messageMatchersSlice.actions;

export const selectFilters = (state: TRootState) => state.messageMatchers.filters as TFilters;

listenerMiddleware.startListening({
  actionCreator: setFiltersAction,
  effect: (action) => {
    sessionStorage.setItem(EStorageKeys.MESSAGE_MATCHERS_FILTERS, JSON.stringify(action.payload));
  },
});

export default messageMatchersSlice.reducer;
