/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash/isEmpty';

const mapEmptyFieldsToUndefined = (value: any): any => {
  if (value === null || value === '') {
    return undefined;
  }

  if (Array.isArray(value)) {
    return value.map(mapEmptyFieldsToUndefined);
  }

  if (value instanceof Date) {
    return value;
  }

  if (typeof value === 'object') {
    const cleanObject = Object.entries(value).reduce((acc, [key, _value]) => {
      const cleanValue = mapEmptyFieldsToUndefined(_value);

      return cleanValue === undefined
        ? acc
        : {
            ...acc,
            [key]: cleanValue,
          };
    }, {});

    return isEmpty(cleanObject) ? undefined : cleanObject;
  }

  return value;
};

const mapNullToUndefined = (value: any): any => {
  if (value === null) {
    return undefined;
  }

  if (Array.isArray(value)) {
    return value.map(mapNullToUndefined);
  }

  if (value instanceof Date) {
    return value;
  }

  if (typeof value === 'object') {
    return Object.entries(value).reduce(
      (acc, [key, _value]) => ({
        ...acc,
        [key]: mapNullToUndefined(_value),
      }),
      {},
    );
  }

  return value;
};

const mapUndefinedToNull = (value: any): any => {
  if (value === undefined || value === null) {
    return null;
  }

  if (Array.isArray(value)) {
    return value.map(mapUndefinedToNull);
  }

  if (value instanceof Date) {
    return value;
  }

  if (typeof value === 'object') {
    return Object.entries(value).reduce(
      (acc, [key, _value]) => ({
        ...acc,
        [key]: mapUndefinedToNull(_value),
      }),
      {},
    );
  }

  return value;
};

export {mapNullToUndefined, mapUndefinedToNull, mapEmptyFieldsToUndefined};
/* eslint-enable @typescript-eslint/no-explicit-any */
