import {ERegExpType, TRegexp} from 'src/components/EditorWithSelector/definitions';
import {XLSX_MIME_TYPE} from 'src/constants';

export enum EViberCategory {
  AccountUpdate = 'ACCOUNT_UPDATE',
  AlertUpdate = 'ALERT_UPDATE',
  AppointmentUpdate = 'APPOINTMENT_UPDATE',
  IssueResolution = 'ISSUE_RESOLUTION',
  PaymentUpdate = 'PAYMENT_UPDATE',
  PersonalFinanceUpdate = 'PERSONAL_FINANCE_UPDATE',
  ReservationUpdate = 'RESERVATION_UPDATE',
  ShippingUpdate = 'SHIPPING_UPDATE',
  TicketUpdate = 'TICKET_UPDATE',
  AutoReply = 'AUTO_REPLY',
  TransportationUpdate = 'TRANSPORTATION_UPDATE',
}

export const VIBER_CATEGORIES = Object.values(EViberCategory);

export const allowedRegexps: TRegexp[] = [
  {type: ERegExpType.STATIC, value: '\\w+'},
  {type: ERegExpType.STATIC, value: '\\d+'},
  {type: ERegExpType.STATIC, value: '[\\s\\w]+'},
  {type: ERegExpType.STATIC, value: '[\\s\\d]+'},
];

export const ALLOWED_DOCUMENT_EXTENSIONS = [
  '.doc',
  '.docx',
  '.txt',
  '.rtf',
  '.dot',
  '.dotx',
  '.odt',
  '.odf',
  '.fodt',
  '.info',
  '.pdf',
  '.xls',
  '.xlsx',
  '.xps',
  '.pdax',
  '.eps',
];

export const ALLOWED_DOCUMENT_MIME_TYPES = [
  XLSX_MIME_TYPE,
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'text/plain',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.ms-xpsdocument',
  'application/postscript',
];

export const MAX_DOCUMENT_SIZE = 1024 * 1024 * 25;
