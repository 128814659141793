import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {routeChanged} from 'src/models/actions';

import {TFilters} from './definitions';

const slice = createSlice({
  name: 'accountStatement',
  initialState: {
    filters: {},
  },
  reducers: {
    setFilters(state, action: PayloadAction<TFilters>) {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(routeChanged, (state) => {
      state.filters = {};
    });
  },
});

export const {setFilters: setFiltersAction} = slice.actions;
export const selectFilters = (state: TRootState) => state.accountStatement.filters as TFilters;

export default slice.reducer;
