const enumToBooleanHash = <T extends string, U extends string>(
  object: Record<T, U>,
  defaultValue = false,
) => {
  const result = {} as Record<U, boolean>;

  Object.values<U>(object).forEach((item) => {
    result[item] = defaultValue;
  });

  return result;
};

const booleanHashToArray = <V extends string>(hash?: Partial<Record<V, boolean>>): V[] => {
  const result: V[] = [];

  if (hash) {
    Object.entries(hash).forEach(([key, value]) => {
      if (value) {
        result.push(key as V);
      }
    });
  }

  return result;
};

const arrayToBooleanHash = <V extends string>(array: V[]): Record<V, boolean> => {
  const result = {} as Record<V, boolean>;

  array.forEach((item) => {
    result[item] = true;
  });

  return result;
};

export {enumToBooleanHash, booleanHashToArray, arrayToBooleanHash};
