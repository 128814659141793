import {TCurrency} from '@edna/utils/formatMoney';

import {TBilledEventState} from 'src/constants';

export enum EPaymentCorrectionType {
  STORNO = 'STORNO',
  CANCEL_STORNO = 'CANCEL_STORNO',
}

export type TPaymentCorrectionType = keyof typeof EPaymentCorrectionType;
export const PAYMENT_CORRECTION_TYPES = Object.values(EPaymentCorrectionType);

export enum EBilledEventConfirmation {
  CLOSED = 'CLOSED',
  HOLD = 'HOLD',
  REFUNDED = 'REFUNDED',
  POSTPONED = 'POSTPONED',
  STORNO = 'STORNO',
}

export type TBilledEvent = {
  id: number;
  originalEventId: string;
  originalEventType: string;
  confirmation: keyof typeof EBilledEventConfirmation;
  content: TAnyObject;
  createdAt: string;
};

export type TDebtTransaction = {
  id: number;
  eventId: number;
  tenantId: number;
  reason: string;
  reasonCode: string;
  balanceId: number;
  amount: number;
  currency: TCurrency;
  createdAt: string;
  details: TAnyObject;
};

export type TTransaction = {
  id: number;
  eventId: number;
  tenantId: number;
  reason: string;
  reasonCode: string;
  balanceId: number;
  openingBalance: number;
  amount: number;
  currency: TCurrency;
  createdAt: string;
  state: TBilledEventState;
};

export type TBilledEventData = {
  billedEvent: TBilledEvent | null;
  debtTransaction: TDebtTransaction | null;
  transaction: TTransaction | null;
};

export type TStornoFormData = {
  correctionType?: TPaymentCorrectionType;
  originalEventId: string | null;
  comment?: string;
};
