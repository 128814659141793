import {TValue as TPickerValue} from '@edna/components/PeriodPicker';
import {TCurrency} from '@edna/utils/formatMoney';

import {TItemId} from 'src/constants';
import {TPaymentType} from 'src/containers/User';

export type TSupportClient = {
  id: number;
  locale: string;
  companyName: string;
  currencyCode: TCurrency;
  createdAt: string;
  taxNumber: string;
  email: string;
  paymentData: {
    operBankAccount: string;
    corrBankAccount: string;
    bankName: string;
    taxNumber: string;
    kpp: string;
    bic: string;
    nonresident: boolean;
    postAddress: string;
    legalAddress: string;
    siteAddress: string;
  };
  paymentType: TPaymentType;
  contacts: {
    email: string;
    phone: string;
    country: string;
  };
  personalManagerId: number;
  personalManagerName: string;
  personalManagerEmail: string;
  personalManagerPhone: string;
  partnerId: number;
  resellerId: number;
  tenantId: number;
  supportEmail: string;
  offerContentUrl: string;
  vatNumber: string;
  registrationEmail: string;
  needAgreementConfirmation: boolean;
  temporaryBlocked?: boolean;
  unblockAt?: string;
  locked?: boolean;
  defaultCurrency: TCurrency;
};

export enum ESupportProfileTabs {
  PAYMENT = 'payment',
  LICENSE = 'license',
  REQUISITES = 'requisites',
  PRICING = 'pricing',
  CALLBACK = 'callback',
}

export const SUPPORT_PROFILE_TABS = Object.values(ESupportProfileTabs);

export enum EPaymentTab {
  REPLENISHMENT = 'replenishment',
  ACCOUNT_STATEMENT = 'accountStatement',
  DOCUMENTATION = 'documentation',
}

export enum ETransactionPaymentType {
  INIT = 'INIT',
  RENEWAL = 'RENEWAL',
}

export type TFilters = {
  filter: string;
  period: TPickerValue;
  resellerId?: TItemId;
  responsibleManagerId?: TItemId;
};
