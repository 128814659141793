import {TValue as TPickerValue} from '@edna/components/PeriodPicker';

import {
  EChannelType,
  EContentType,
  ELanguage,
  EOperatorName,
  TChannelType,
  TItemId,
} from 'src/constants';
import {TPushChannel} from 'src/containers/PushChannel/definitions';
import {EUrgencyType, TPushStyle} from 'src/containers/PushStyle';
import {
  CustomTemplateIcon,
  OperatorTemplateIcon,
  UserTemplateIconEn,
  UserTemplateIconRu,
} from 'src/icons';

import {EMultiApproveStatus, ESmsCategory, TSmsProvider} from './SMSForm/definitions';
import {EViberCategory} from './ViberForm/definitions';
import {
  EOldWhatsAppCategory,
  ETemplateButtonOtpType,
  EWhatsAppCategory,
} from './WhatsAppForm/definitions';

enum EApproveStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  NOT_SENT = 'NOT_SENT',
  PAUSED = 'PAUSED',
  DISABLED = 'DISABLED',
  ARCHIVED = 'ARCHIVED',
}

enum EType {
  OPERATOR = 'OPERATOR',
  USER = 'USER',
  CUSTOM = 'CUSTOM',
}

const TYPES = Object.values(EType);

const ICONS_MAP: Record<ELanguage, Record<EType, TAnyComponent>> = {
  [ELanguage.ru]: {
    [EType.OPERATOR]: OperatorTemplateIcon,
    [EType.USER]: UserTemplateIconRu,
    [EType.CUSTOM]: CustomTemplateIcon,
  },
  [ELanguage.en]: {
    [EType.OPERATOR]: OperatorTemplateIcon,
    [EType.USER]: UserTemplateIconEn,
    [EType.CUSTOM]: CustomTemplateIcon,
  },
};

enum EListAction {
  CREATE_BASED_ON = 'CREATE_BASED_ON',
  DUPLICATE = 'DUPLICATE',
}

enum ESubmitType {
  SAVE = 'SAVE',
  REGISTER = 'REGISTER',
  APPROVE = 'APPROVE',
  DRAFT = 'DRAFT',
}

const COLUMNS_WIDTH = ['8%', '30%', '7%', '16%', '16%', '16%', '7%'];
const TEMPLATE_NAME_MIN_LENGTH = 3;
const TEMPLATE_NAME_MAX_LENGTH = 60;
const TEMPLATE_TEXT_MAX_LENGTH = 1024;
const VARIABLE_TEXT_MAX_LENGTH = 512;
const MAX_URL_LENGTH = 2000;

type TType = keyof typeof EType;

type TTHeaderType =
  | EContentType.TEXT
  | EContentType.IMAGE
  | EContentType.VIDEO
  | EContentType.DOCUMENT;

type THeader = {
  text?: string;
  headerType?: TTHeaderType;
  attachment?: TFile | null;
  headerExampleMediaUrl?: string;
};

type TPushBigContent = {
  text?: string;
  header?: THeader;
};

type TAdditionalParameter = Record<string, string>;

type TCommonContent = {text?: string};

type TPushContent = TCommonContent & {
  attachment?: TFile | null;
  header?: THeader;
  bigContent?: TPushBigContent;
  style?: TPushStyle;
  deviceSettings?: {
    urgency?: EUrgencyType;
  };
  buttons?: TMessageMatcherButton[];
  action?: string;
  serviceAttrs?: TAdditionalParameter;
};

type TVkNotifyContent = TCommonContent & {
  vkAttachments?: TFile[];
  vkTwoWayEnabled?: boolean;
  buttons?: TMessageMatcherButton[];
};

type TViberContent = TCommonContent & {
  attachment?: TFile | null;
  caption?: string;
  action?: string;
  buttons?: TMessageMatcherButton[];
};

type TWhatsAppContent = TCommonContent & {
  header?: THeader;
  footer?: {
    text?: string;
  };
  attachment?: TFile | null;
  buttons?: TMessageMatcherButton[];
  textExampleParams?: string[];
  codeExpirationMinutes?: number;
  securityRecommendation?: boolean;
};

type TInstagramContent = TCommonContent & {
  attachment?: TFile | null;
};

type TContent = TCommonContent &
  TPushContent &
  TInstagramContent &
  TWhatsAppContent &
  TVkNotifyContent &
  TViberContent;

type TMessageMatcher<T = TContent> = {
  id: TItemId;
  parentId: TItemId;
  name: string;
  type: TType;
  channelType: TChannelType;
  adminComment: string;
  description: string;
  createdAt: string;
  createdBy: string;
  approveStatus: EApproveStatus;
  statusAt: string;
  statusComment: string;
  subjectIds: number[];
  template: string;
  exampleText: string;
  updatedAt: string;
  updatedBy: string;
  language: string;
  category: EViberCategory | EWhatsAppCategory | EOldWhatsAppCategory | ESmsCategory | '';
  hasChildren: boolean;
  children?: TMessageMatcher[];
  lastUsedAt: string;
  smsProviders?: TSmsProvider[];
  contentType?: EContentType;
  multiApproveStatus?: EMultiApproveStatus;
  content: T;
};

type TInitialMessageMatcher<T = TContent> = Partial<TMessageMatcher<T>>;

enum EMessageMatcherButtonTypes {
  PHONE = 'PHONE',
  OTP = 'OTP',
  URL = 'URL',
  CHAT = 'QUICK_REPLY',
  LOCATION = 'LOCATION',
  VK_MINI_APPS = 'VK_MINI_APPS',
}

enum EMessageMatcherButtonColor {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

type TMessageMatcherButton = {
  buttonType?: EMessageMatcherButtonTypes;
  required?: boolean;
  text?: string;
  payload?: string;
  phone?: string;
  url?: string;
  hasUrlPostfix?: boolean;
  color?: EMessageMatcherButtonColor;
  hash?: string;
  appId?: number;
  ownerId?: number;
  packageName?: string;
  autofillText?: string;
  otpType?: ETemplateButtonOtpType | '';
  // field for broadcasts
  urlPostfix?: string;
};

type TFormDataButtons = Partial<
  Record<EMessageMatcherButtonTypes, Omit<TMessageMatcherButton, 'buttonType'>[]>
> & {
  isChatType?: boolean;
};

type TWhatsAppMessageMatchersContent = {
  text: TWhatsAppContent['text'];
  hasHeader?: boolean;
  header?: TWhatsAppContent['header'];
  addFooter?: boolean;
  footer?: TWhatsAppContent['footer'];
  buttons?: TMessageMatcherButton[];
  textExampleParams?: string[];
  addPasswordExpiration?: boolean;
  codeExpirationMinutes?: number;
  securityRecommendation?: boolean;
};

type TWhatsAppFormData = TFormData<TWhatsAppMessageMatchersContent>;

type TViberMessageMatchersContent = {
  text?: TViberContent['text'];
  attachment?: TViberContent['attachment'];
  caption?: TViberContent['caption'];
  action?: TViberContent['action'];
  shouldCollectClicks?: boolean;
};

type TInstagramMessageMatchersContent = {
  text: TInstagramContent['text'];
  isUploadImage?: boolean;
  attachment?: TInstagramContent['attachment'];
  imageUrl?: string;
};

type TPushMessageMatchersContent = {
  text: TPushContent['text'];
  action?: TPushContent['action'];
  style?: TPushContent['style'];
  deviceSettings?: TPushContent['deviceSettings'];
  headerText?: THeader['text'];
  headerAttachment?: TFile | null;
  bigContentText?: TPushBigContent['text'];
  bigContentAttachment?: TFile | null;
  attachment?: TPushContent['attachment'];
  buttons?: TFormDataButtons;
  imageUrl?: string;
  logoUrl?: string;
  addButtons?: boolean;
  addLinks?: boolean;
  parameters?: TAdditionalParameter[];
};

type TVkNotifyFormData = TFormData<TVkNotifyMessageMatchersContent>;

type TVkNotifyMessageMatchersContent = {
  text?: TVkNotifyContent['text'];
  vkTwoWayEnabled: boolean;
  addAttachments?: boolean;
  vkAttachments?: Partial<TFile>[];
  addButtons?: boolean;
  buttons?: TMessageMatcherButton[];
};

type TOkNotifyMessageMatchersContent = {
  text?: TCommonContent['text'];
};

type TSmsMessageMatchersContent = {
  text?: TCommonContent['text'];
};

enum EVariableType {
  TEXT = 'TEXT',
  PARAMS = 'PARAMS',
}

type TVariables = {
  type: EVariableType;
  value: string;
};

type TMessageMatchersContent =
  | TWhatsAppMessageMatchersContent
  | TViberMessageMatchersContent
  | TInstagramMessageMatchersContent
  | TPushMessageMatchersContent
  | TVkNotifyMessageMatchersContent
  | TOkNotifyMessageMatchersContent
  | TSmsMessageMatchersContent;

type TFormData<T = TMessageMatchersContent> = {
  id?: TMessageMatcher['id'];
  name?: TMessageMatcher['name'];
  type?: TMessageMatcher['type'];
  approveStatus?: TMessageMatcher['approveStatus'];
  multiApproveStatus?: TMessageMatcher['multiApproveStatus'];
  description?: TMessageMatcher['description'];
  exampleText?: TMessageMatcher['exampleText'];
  category?: TMessageMatcher['category'];
  language?: TMessageMatcher['language'];
  createdBy?: TMessageMatcher['createdBy'];
  channelType: EChannelType;
  subjectIds: TMessageMatcher['subjectIds'];
  smsProviders?: Partial<Record<EOperatorName, boolean>>;
  parentMessageMatcherText?: TCommonContent['text'];
  content: T;
  variables?: TVariables[];
  parentId?: TMessageMatcher['parentId'];
  // viber custom content
  contentType?: TMessageMatcher['contentType'];
  // push
  pushChannelId?: TPushChannel['id'];
  isUploadExampleFile?: boolean;
  subjectId?: number;
};

type TValidationResult = {
  success: boolean;
  textExampleParams: [boolean];
  header: {
    headerExampleTextParam: boolean;
    headerExampleMediaUrl: boolean;
  };
  buttons: [
    {
      urlTextExample: boolean;
    },
  ];
};

type TLanguage = {
  code: string;
  ru: string;
  en: string;
};

type TSupportedLanguages = {
  whatsapp?: TLanguage[];
};

type TCheckPatternMatch = {
  channelType: TChannelType;
  message: string;
  pattern: string;
};

type TFilters = {
  filter?: string;
  channelTypes: Record<EChannelType, boolean>;
  statuses: Partial<Record<EApproveStatus, boolean>>;
  types: Record<EType, boolean>;
  subjectIds: number[];
  exceptCategories?: string[];
  categories?: string[];
  period?: TPickerValue;
};

export type {
  TInitialMessageMatcher,
  TMessageMatcher,
  TInstagramContent,
  TWhatsAppContent,
  TViberContent,
  TVkNotifyContent,
  TPushContent,
  TFormData,
  TWhatsAppMessageMatchersContent,
  TWhatsAppFormData,
  TViberMessageMatchersContent,
  TInstagramMessageMatchersContent,
  TPushMessageMatchersContent,
  TVkNotifyMessageMatchersContent,
  TVkNotifyFormData,
  TOkNotifyMessageMatchersContent,
  TSmsMessageMatchersContent,
  TFormDataButtons,
  TMessageMatcherButton,
  TTHeaderType,
  TType,
  TVariables,
  TSupportedLanguages,
  TCheckPatternMatch,
  TValidationResult,
  TFilters,
};
export {
  EMessageMatcherButtonTypes,
  EMessageMatcherButtonColor,
  EVariableType,
  ESubmitType,
  EListAction,
  EType,
  EApproveStatus,
  TYPES,
  ICONS_MAP,
  COLUMNS_WIDTH,
  TEMPLATE_NAME_MIN_LENGTH,
  TEMPLATE_NAME_MAX_LENGTH,
  TEMPLATE_TEXT_MAX_LENGTH,
  VARIABLE_TEXT_MAX_LENGTH,
  MAX_URL_LENGTH,
};
