import {EChannelType} from 'src/constants';
import {rootApi} from 'src/models';

import {TLicenseData} from './LicensesTab';
import {TBilledEvent, TBilledEventData, TStornoFormData} from './PaymentsTab';

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getStornoData: builder.mutation<TBilledEventData, TBilledEvent['originalEventId']>({
      query: (eventId) => ({
        method: 'GET',
        url: `/billing/storno/find/${eventId}`,
        meta: {
          isShowError: true,
        },
      }),
    }),
    startStornoProcess: builder.mutation<
      TBilledEventData,
      Pick<TStornoFormData, 'originalEventId' | 'comment'>
    >({
      query: (data) => ({
        method: 'POST',
        url: '/billing/storno',
        data,
        meta: {
          isShowError: true,
        },
      }),
    }),
    cancelStornoProcess: builder.mutation<TBilledEventData, TBilledEvent['originalEventId']>({
      query: (eventId) => ({
        method: 'POST',
        url: '/billing/storno/cancel',
        data: {originalEventId: eventId},
        meta: {
          isShowError: true,
        },
      }),
    }),
    getLicenseRenewal: builder.mutation<
      TLicenseData,
      {
        licenseId: number | string;
        month: string;
        year: string;
      }
    >({
      query: (payload) => ({
        method: 'GET',
        url: '/admin/billing/license/renewal',
        params: {
          licenseId: payload.licenseId,
          period: `${payload.year}-${payload.month}`,
        },
        meta: {
          isShowError: true,
        },
      }),
    }),
    renewLicense: builder.mutation<
      TLicenseData,
      {
        licenseId: number | string;
        month: string;
        year: string;
        channelType: EChannelType;
      }
    >({
      query: (payload) => ({
        method: 'POST',
        url: `/billing/jobs/license/renewal/${payload.channelType.toUpperCase()}/period/${
          payload.year
        }-${payload.month}`,
        params: {
          licenseIds: payload.licenseId,
        },
        meta: {
          isShowError: true,
          isShowSuccess: true,
          successMessageKey: 'LicenseRenewal:notification',
        },
      }),
    }),
  }),
});

export const {
  // Payments
  useGetStornoDataMutation,
  useStartStornoProcessMutation,
  useCancelStornoProcessMutation,
  // Licenses
  useRenewLicenseMutation,
  useGetLicenseRenewalMutation,
} = api;
