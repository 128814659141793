import styled from 'styled-components';

import {
  Actions as BaseActions,
  Content as BaseContent,
  Header as BaseHeader,
  Window as BaseWindow,
} from '@edna/components/Modals/style';

export * from '@edna/components/Modals/style';

export const Window = styled(BaseWindow)`
  padding: ${({theme: {spacing}}) => spacing.s10};
`;

export const Header = styled(BaseHeader)`
  padding: ${({theme: {spacing}}) => `0 0 ${spacing.s4}`};
`;

export const Content = styled(BaseContent)`
  padding: 0;
`;

export const Actions = styled(BaseActions)`
  margin-top: ${({theme}) => theme.spacing.s5};
  padding: 0;
`;
