/* eslint-disable import/first */
import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

/* eslint-disable-next-line import/no-extraneous-dependencies */
import isPropValid from '@emotion/is-prop-valid';
import * as Sentry from '@sentry/react';
import {ShouldForwardProp, StyleSheetManager, ThemeProvider} from 'styled-components';

import storage from '@edna/utils/storage';

import App from 'src/containers/App';
import GlobalStyle from 'src/style';

import {Onboarding} from './components';
import {
  EN_DOMAIN_POSTFIX,
  EStorageKeys,
  RU_DOMAIN_POSTFIX,
  isEnDomain,
  isEnvDevelopment,
} from './constants';
import i18n from './i18n';
import store from './store';
import theme from './styles/theme';

if (!isEnvDevelopment) {
  Sentry.setTag('hostname', window.location.hostname);
  Sentry.setTag('tenantId', storage.get(EStorageKeys.TENANT_ID));
  Sentry.setUser({username: String(storage.get(EStorageKeys.USERNAME))});
  Sentry.init({
    dsn: `https://fb85f2ed80a14789b3b21136adb8c47b@sentry.edna${
      isEnDomain() ? EN_DOMAIN_POSTFIX : RU_DOMAIN_POSTFIX
    }/4`,
    autoSessionTracking: true,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
  });
}

// This implements the default behavior from styled-components v5
const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }

  // For other elements, forward all props
  return true;
};

const Application = React.memo(() => (
  <StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <BrowserRouter>
              <Onboarding>
                <App />
              </Onboarding>
            </BrowserRouter>
          </ThemeProvider>
        </StyleSheetManager>
      </I18nextProvider>
    </Provider>
  </StrictMode>
));

const setupBackendMocking = async () => {
  if (!process.env.MOCK_ENDPOINTS?.length) {
    return;
  }

  const {worker} = await import('./jest/mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
};

const renderApp = () => {
  const target = document.getElementById('root');

  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(<Application />, target);
};

setupBackendMocking().then(renderApp);
/* eslint-enable import/first */
