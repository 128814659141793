import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';
import {hexToRgba} from '@edna/utils/hexToRgba';

type TTogglerProps = {
  open: boolean;
};

export const Toggler = styled.div<TTogglerProps>`
  ${mixins.wh('36px')}
  display: block;
  flex-shrink: 0;
  cursor: pointer;
  ${({open, theme: {spacing, animation}}) => css`
    margin: auto ${spacing.s3} ${spacing.s4} auto;
    padding: ${spacing.s2};
    transform: rotate(${open ? 0 : '180deg'});
    transition: transform ${animation.duration}ms;
  `}
`;

type TMenuProps = {
  open: boolean;
  hasWhiteBackground?: boolean;
};

export const Menu = styled.div<TMenuProps>`
  display: flex;
  flex-direction: column;
  user-select: none;
  flex-shrink: 0;
  height: 100vh;
  overflow: hidden;

  ${({
    open,
    hasWhiteBackground,
    theme: {borderRadius, dimensions, branding, palette, spacing},
  }) => css`
    padding-bottom: ${spacing.s2};
    background-color: ${hasWhiteBackground
      ? branding.backgrounds.primary
      : branding.content.primary};
    color: ${branding.content.tertiary};
    border-radius: 0 ${borderRadius.br5} ${borderRadius.br5} 0;
    box-shadow:
      0 8px 24px -4px ${hexToRgba(branding.content.primary, '38%')},
      0 2px 6px -1px ${hexToRgba(branding.content.primary, '16%')};

    ${mixins.position({position: 'fixed', top: 0, left: 0})};

    @media (min-width: ${dimensions.asideNavWidth * 2 + dimensions.maxWidthLayout}px) {
      position: fixed;
    }
    ${Toggler} {
      color: ${hasWhiteBackground && !open ? palette.green400 : 'inherit'};
      &:hover {
        color: ${hasWhiteBackground ? palette.green400 : palette.white};
      }
    }
  `}
`;

export const Aside = styled.div`
  position: fixed;

  bottom: 0;
  left: 0;
`;
export const LogoWrapper = styled.div<{isMenuOpen?: boolean}>`
  ${mixins.flexAlign('flex-start', 'center')};
  ${({theme, isMenuOpen}) => css`
    gap: ${theme.spacing.s2};
    margin: 34px 21px 30px ${isMenuOpen ? '21px' : '14px'};
    color: ${theme.palette.white};
    transition: margin-left ${theme.animation.duration}ms;
  `};
`;

type TScrollContent = {
  hasWhiteBackground?: boolean;
};

export const ScrollContent = styled.div<TScrollContent>`
  overflow: hidden auto;

  ${({hasWhiteBackground, theme: {spacing, palette, branding}}) => css`
    margin-right: ${spacing.s1};
    &::-webkit-scrollbar {
      ${mixins.wh('4px')}
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${hasWhiteBackground ? palette.grey300 : branding.content.secondary};
    }
  `}
`;
