import {TOperator, and} from '@edna/utils/operators';

import {booleanHashToArray} from 'src/utils';

import {useGetCurrentUserQuery, useGetFeatureFlagsQuery} from './api';
import {EFeatureFlag, EPermission, EResellerIds, ERoles} from './definitions';

const useFeatureFlag = (flag: EFeatureFlag | TOperator<EFeatureFlag>) => {
  const {data: featureFlags} = useGetFeatureFlagsQuery();

  if (!featureFlags) {
    return false;
  }

  const normalizedFlags = booleanHashToArray(featureFlags);

  if (typeof flag === 'function') {
    return flag(normalizedFlags);
  }

  return and(flag)(normalizedFlags);
};

const usePermission = (permission: EPermission | TOperator<EPermission>) => {
  const {data: user} = useGetCurrentUserQuery();

  if (!user) {
    return false;
  }

  if (typeof permission === 'function') {
    return permission(user.permissions);
  }

  return and(permission)(user.permissions);
};

const useReseller = (resellerId: EResellerIds | TOperator<EResellerIds>) => {
  const {data: user} = useGetCurrentUserQuery();

  if (!user) {
    return false;
  }

  if (typeof resellerId === 'function') {
    return resellerId(user.resellerId);
  }

  return and<EResellerIds>(resellerId)(user.resellerId);
};

const useRole = (roles: ERoles | TOperator<ERoles>) => {
  const {data: user} = useGetCurrentUserQuery();

  if (!user) {
    return false;
  }

  if (typeof roles === 'function') {
    return roles(user.roles);
  }

  return and(roles)(user.roles);
};

export {useFeatureFlag, usePermission, useReseller, useRole};
