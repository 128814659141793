import React from 'react';
import {useTranslation} from 'react-i18next';

import {
  Modal,
  ModalActions,
  ModalCancelButton,
  ModalHeader,
  ModalSubmitButton,
  showModal,
} from '@edna/components';

import {SuccessSubscribersImage} from 'src/images';

import * as S from '../style';
import {BilledEventDetails} from './BilledEventDetails';
import {TBilledEventData, TPaymentCorrectionType} from './definitions';

type TModalProps = {
  closeModal: TEmptyFunction;
};

type TConfirmProps = {
  onSubmit: TEmptyFunction;
  onCancel: TEmptyFunction;
  correctionType: TPaymentCorrectionType;
  billedEventData?: TBilledEventData;
};

const ConfirmModal = React.memo<TModalProps & TConfirmProps>(
  ({closeModal, onSubmit, onCancel, correctionType, billedEventData}) => {
    const {t} = useTranslation();

    const handleCancel = React.useCallback(() => {
      closeModal();
      onCancel();
    }, [closeModal, onCancel]);

    const handleSubmit = React.useCallback(() => {
      closeModal();
      onSubmit();
    }, [closeModal, onSubmit]);

    return (
      <Modal width="534px">
        <ModalHeader>{t(`Corrections:payments.confirmModal.title.${correctionType}`)}</ModalHeader>
        <S.BigText>{t('Corrections:payments.confirmModal.subtitle')}</S.BigText>
        {billedEventData && <BilledEventDetails size="M" billedEventData={billedEventData} />}
        <ModalActions>
          <ModalCancelButton data-selector="ModalCancelButton" onClick={handleCancel}>
            {t('Common:button.cancel')}
          </ModalCancelButton>
          <ModalSubmitButton onClick={handleSubmit}>
            {t('Corrections:payments.confirmModal.button')}
          </ModalSubmitButton>
        </ModalActions>
      </Modal>
    );
  },
);

ConfirmModal.displayName = 'ConfirmModal';

type TSuccessProps = {
  onClose: TEmptyFunction;
};

const SuccessModal = React.memo<TModalProps & TSuccessProps>(({closeModal, onClose}) => {
  const {t} = useTranslation();

  const handleClose = React.useCallback(() => {
    closeModal();
    onClose();
  }, [closeModal, onClose]);

  return (
    <Modal width="430px" onExit={onClose}>
      <ModalHeader>{t('Corrections:payments.successModal.title')}</ModalHeader>
      <S.BigText>{t('Corrections:payments.successModal.message')}</S.BigText>
      <S.SuccessImage as={SuccessSubscribersImage} />
      <ModalActions>
        <ModalSubmitButton data-selector="ModalCancelButton" onClick={handleClose}>
          {t('Common:button.ok')}
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
});

SuccessModal.displayName = 'SuccessModal';

const showConfirmModal = ({onSubmit, onCancel, correctionType, billedEventData}: TConfirmProps) =>
  showModal((closeModal) => (
    <ConfirmModal
      closeModal={closeModal}
      onSubmit={onSubmit}
      correctionType={correctionType}
      onCancel={onCancel}
      billedEventData={billedEventData}
    />
  ));

const showSuccessModal = ({onClose}: TSuccessProps) =>
  showModal((closeModal) => <SuccessModal closeModal={closeModal} onClose={onClose} />);

export {showConfirmModal, showSuccessModal};
