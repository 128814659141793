import {EContentType} from 'src/constants';

import {EMenuButtonAction} from '../MenuButton';
import {ETemplateButtonOtpType} from '../MessageMatchers/WhatsAppForm/definitions';
import {EMessageMatcherButtonTypes} from '../MessageMatchers/definitions';
import {ECommonMenuType} from './Layout/constants';
import {EActionType, EFlowStatus, EFlowStepType, EUIConditionType} from './types';

export default {
  createTrigger: 'сценарий',
  status: {
    [EFlowStatus.DRAFT]: 'Черновик',
    [EFlowStatus.ACTIVE]: 'Активен',
    [EFlowStatus.INACTIVE]: 'Остановлен',
    [EFlowStatus.SCHEDULED]: 'Запланирован',
    [EFlowStatus.DELETED]: 'Удален',
    [EFlowStatus.FINISHED]: 'Завершен',
  },
  type: {
    [EFlowStepType.TRIGGER]: 'Стартовый блок',
    [EFlowStepType.CONDITION]: 'Условие',
    [EFlowStepType.ACTION]: 'Действие',
  },
  changeCondition: 'Изменить условие',
  transitions: 'Переходов: {{count}}',
  description: {
    [EFlowStepType.TRIGGER]: 'Выберите условие, с которого начнется ваш сценарий',
    [EFlowStepType.CONDITION]: 'Выберите условие',
    [EFlowStepType.ACTION]: 'Выберите действие',
    [EActionType.CC_DIALOG_SWITCH_ACTION]: 'Диалог с получателем переводится в edna Chat Center',
    [EActionType.PAUSE_ACTION]: 'Во время паузы абонент не получает сообщений от сценария',
    [EActionType.ADD_SUBSCRIBER_TAG_ACTION]: 'Всем получателям будут присвоены указанные теги',
    [EActionType.DELETE_SUBSCRIBER_TAG_ACTION]: 'Всем получателям будут удалены указанные теги',
    [EUIConditionType.TAG_PRESENCE_CONDITION]: 'Выбрать абонентов на основе тегов',
    [EUIConditionType.BUTTON_PRESSED]: 'Выбрать абонентов, которые нажали кнопки в сообщении',
    [EUIConditionType.MESSAGE_RECEIVED]: 'Выбрать абонентов, которые направили входящее сообщение',
  },
  title: {
    [EActionType.PAUSE_ACTION]: 'Сценарий ожидает ответ клиента в течение',
  },
  trigger: {
    [EUIConditionType.MESSAGE_RECEIVED]: {
      title: 'Получено входящее сообщение',
      subtitle: 'Абонент отправил сообщение',
    },
    [EUIConditionType.BUTTON_PRESSED]: {
      title: 'Нажатие кнопки в сообщении',
      buttonLabel: 'Название кнопки',
      buttonId: 'Код кнопки',
    },
    [EUIConditionType.TAG_ASSIGN_TIMEOUT_CONDITION]: {
      title: 'Время присвоения тега',
      delay: 'С момента присвоения тега прошло',
      tags: 'Присвоенный тег',
    },
    [EUIConditionType.TAG_PRESENCE_CONDITION]: {
      title: 'Наличие тегов',
      include: 'Включить',
      exclude: 'Исключить',
    },
    [EActionType.ADD_SUBSCRIBER_TAG_ACTION]: 'Присвоить теги',
    [EActionType.DELETE_SUBSCRIBER_TAG_ACTION]: 'Удалить теги',
    [EActionType.CC_DIALOG_SWITCH_ACTION]: 'Перевести на оператора',
    [EActionType.SEND_MESSAGE_ACTION]: 'Отправить сообщение WhatsApp',
    [EActionType.PAUSE_ACTION]: 'Ожидание ответа',
  },
  triggerDescription: {
    [EActionType.CC_DIALOG_SWITCH_ACTION]: 'Перевод диалога в edna Chat Center',
  },
  sendMessageAction: {
    template: 'Шаблон',
    ignoreSchedule: 'Игнорировать расписание сценария',
    messageMatcherId: 'Выберите шаблон сообщения',
    hasAttachment: {
      [EContentType.IMAGE]:
        'В этом шаблоне есть заголовок-картинка. Загрузите файл с изображением или введите ссылку на него.',
      [EContentType.VIDEO]:
        'В этом шаблоне есть заголовок-видео. Загрузите файл с видео или введите ссылку на него.',
      [EContentType.DOCUMENT]:
        'В этом шаблоне есть заголовок-документ. Загрузите файл документа или введите ссылку на него.',
    },
    upload: 'Загрузить с компьютера',
    addLink: 'Вставить ссылку на файл',
    imageHint: 'Картинка (PNG или JPG до 5 МБ)',
    viewTemplate: 'Посмотреть шаблон',
    linkHint: {
      [EContentType.IMAGE]: 'Ссылка на картинку (до 200 символов)',
      [EContentType.VIDEO]: 'Ссылка на видео (до 200 символов)',
      [EContentType.DOCUMENT]: 'Ссылка на документ (до 200 символов)',
    },
    button: 'Кнопка',
    buttonName: 'Название',
    attachFile: 'Загрузить файл',
    buttonText: 'Текст кнопки',
    buttonCode: 'Код кнопки',
    buttonType: 'Тип кнопки',
    buttonTypeLabel: {
      [EMessageMatcherButtonTypes.PHONE]: 'Кнопка-звонок',
      [EMessageMatcherButtonTypes.URL]: 'Кнопка-ссылка',
      [EMessageMatcherButtonTypes.CHAT]: 'Кнопка чата',
      [ETemplateButtonOtpType.COPY]: 'Копировать код',
      [ETemplateButtonOtpType.AUTO]: 'Автоматическое заполнение',
    },
    staticUrl: 'Статическая часть ссылки',
    dynamicUrl: 'Динамическая часть ссылки',
    phoneNumber: 'Номер телефона',
    viewMessage: 'Просмотр сообщения',
    back: 'Назад',
    done: 'Готово',
    dynamicUrlRequired: 'Динамическая часть ссылки должна быть заполнена',
  },
  forms: {
    [EUIConditionType.BUTTON_PRESSED]: {
      button: 'Кнопка',
      buttonLabel: 'Название кнопки (должно совпадать с шаблоном)',
      buttonId: 'Код кнопки (должно совпадать с шаблоном)',
      addButton: 'Добавить кнопку',
    },
    [EUIConditionType.TAG_ASSIGN_TIMEOUT_CONDITION]: {
      delay: 'С момента присвоения тега получателю прошло',
      tags: 'Тег, присвоенный получателю',
    },
    [EUIConditionType.TAG_PRESENCE_CONDITION]: {
      tagsTree: 'Дерево тегов',
    },
  },
  tag: 'Тег',
  systemWaiting: 'Ожидание в течение',
  condition: {
    yes: 'Да',
    no: 'Нет',
  },
  contextMenu: {
    add: 'Добавить',
    deleteEdge: 'Удалить',
    deleteBlock: 'Удалить блок?',
    duplicateBlock: 'Дублировать блок',
    deleteTrigger: 'Удалить триггер?',
    duplicateTrigger: 'Дублировать триггер',
  },
  addButton: {
    [EFlowStepType.TRIGGER]: 'Выбрать условие',
    [EFlowStepType.CONDITION]: 'Выбрать условие',
    [EFlowStepType.ACTION]: 'Выбрать действие',
  },
  menu: {
    [EFlowStepType.ACTION]: 'Действие',
    [EFlowStepType.CONDITION]: 'Условие',
    [ECommonMenuType.SETTINGS]: 'Настройки',
    [ECommonMenuType.HELP]: 'Помощь',
    [ECommonMenuType.NOTIFICATIONS]: 'Уведомления',
    [ECommonMenuType.SIGN_OUT]: 'Выход',
  },
  actionButton: {
    [EMenuButtonAction.START]: 'Запустить',
    [EMenuButtonAction.STOP]: 'Остановить',
    [EMenuButtonAction.SETTINGS]: 'Настройки',
    [EMenuButtonAction.VIEW]: 'Посмотреть',
    [EMenuButtonAction.EDIT]: 'Редактировать',
    [EMenuButtonAction.COPY]: 'Копировать',
    [EMenuButtonAction.CANCEL]: 'Отменить запуск',
    [EMenuButtonAction.DELETE]: 'Удалить',
  },
  filters: {
    searchPlaceholder: 'Быстрый поиск по названию',
    flows: 'Цепочек',
  },
  th: {
    activatedAt: 'Дата запуска',
    name: 'Название сценария',
    status: 'Статус',
    started: 'Запущено',
    startedHint: 'Количество абонентов, для которых сработал стартовый блок сценария',
    inProgress: 'Выполняется',
    inProgressHint: 'Количество абонентов, которые проходят сценарий',
    finished: 'Завершено',
    finishedHint: 'Количество абонентов, для которых сценарий завершился успешно',
  },
  modal: {
    [EMenuButtonAction.STOP]: {
      title: 'Остановить сценарий?',
      message:
        'Если вы остановите сценарий, ваш диалог с получателями прервется. Абоненты, которые участвуют в сценарии, не смогут его завершить.',
      confirmButton: 'Остановить',
    },
    [EMenuButtonAction.CANCEL]: {
      title: 'Отменить запуск сценария?',
      message:
        'После отмены запустить сценарий снова невозможно. Чтобы использовать этот сценарий повторно, его необходимо скопировать и сохранить с новым названием и расписанием.',
      confirmButton: 'Отменить запуск',
    },
    [EMenuButtonAction.DELETE]: {
      title: 'Удалить сценарий?',
      message:
        'Если вы удалите сценарий, все настройки, которые в нем содержатся, также будут удалены. Вы уверены?',
      confirmButton: 'Удалить',
    },
    [EMenuButtonAction.COPY]: {
      title: 'Копировать сценарий?',
      message:
        'Все блоки и связи этого сценария продублируются, но вам потребуется задать другое имя сценария и заново выставить расписание.',
      confirmButton: 'Копировать',
    },
    [EMenuButtonAction.START]: {
      title: 'Сценарий успешно активирован!',
      message: '',
      confirmButton: 'Ок',
    },
    [EFlowStepType.TRIGGER]: {
      title: 'Выберите триггер, который запустит ваш сценарий',
    },
    [EFlowStepType.CONDITION]: {
      title: 'Выберите условие',
    },
    [EFlowStepType.ACTION]: {
      title: 'Выберите действие',
    },
  },
  notification: {
    activateSuccess: 'Сценарий успешно запущен',
  },
  errors: {
    tagsRequired: 'Необходимо выбрать хотя бы один тег',
    missingData: 'Не введены настройки блока. Сценарий не может быть запущен',
    missingTriggerData: 'Необходимо настроить условие в стартовом блоке',
    notLastFlowStep: 'Условие не может быть последним блоком цепочки',
  },
};
