import styled, {css} from 'styled-components';

import {NewNotifications as BaseNewNotifications} from '@edna/components';
import {mixins} from '@edna/components/styles';

import {NoShrinkImage} from 'src/components/primitives';

export const Link = styled.a`
  font-weight: 600;
  color: currentColor;
  &:not(:hover) {
    text-decoration: none;
  }
`;

export const Button = styled.button`
  ${mixins.f2}
  ${mixins.pureButton}
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  border: 2px solid currentColor;
  color: currentColor;
  ${({theme: {spacing, borderRadius}}) => css`
    margin-left: ${spacing.s3};
    padding: ${spacing.s1} ${spacing.s4};
    border-radius: ${borderRadius.br2};
  `}
`;

export const ButtonIcon = styled(NoShrinkImage)`
  ${mixins.wh('12px')}
  display: inline-block;
  margin-right: ${({theme: {spacing}}) => spacing.s1};
`;

type TNotificationsProps = {
  width: string;
  left: string;
  hasSystemNotifications?: boolean;
};

export const Notifications = styled(BaseNewNotifications)<TNotificationsProps>`
  ${({left, width, hasSystemNotifications, theme}) => css`
    left: ${left};
    width: ${width};
    max-width: 100%;
    margin-bottom: ${hasSystemNotifications ? theme.spacing.s4 : 0};
    transition:
      left ${theme.animation.duration}ms ease-in-out,
      top ${theme.animation.duration}ms ease-in-out;
  `}
`;
