import {EContentType} from 'src/constants';

import {EMultiApproveStatus, ESmsCategory, ESmsStep} from './SMSForm/definitions';
import {EViberCategory} from './ViberForm/definitions';
import {
  MAX_ATTACHMENTS_LENGTH,
  MAX_BUTTONS_LENGTH,
  MAX_FILE_DIMENSIONS_SUM,
  MAX_FILE_SIZE_MB,
} from './VkNotifyForm/utils';
import {
  BUTTON_NAME_MAX_LENGTH,
  EAuthFormStep,
  EOldWhatsAppCategory,
  EOperatorFormStep,
  ETemplateButtonOtpType,
  EUserFormStep,
  EWhatsAppCategory,
} from './WhatsAppForm/definitions';
import {
  EApproveStatus,
  EListAction,
  EMessageMatcherButtonColor,
  EMessageMatcherButtonTypes,
  EType,
  TEMPLATE_NAME_MAX_LENGTH,
  TEMPLATE_NAME_MIN_LENGTH,
} from './definitions';

export default {
  messageMatcher: 'шаблон',
  noMessageMatcher: 'Без шаблона',
  noOperatorMessageMatcher: 'У вас нет ни одного зарегистрированного операторского шаблона',
  type: {
    [EType.OPERATOR]: {
      title: 'Операторский',
      description:
        'Текстовый шаблон-скелет для оператора (с необходимым синтаксисом и регулярными выражениями). Регистрация такого шаблона у оператора даст возможность в дальнейшем создать на основе него Пользовательские шаблоны и отправлять сообщения дешево',
      short: 'О',
      tooltip: `<strong>Операторский шаблон</strong> - используется для регистрации содержания
 сообщения у оператора канала (мобильные операторы, Viber, Facebook) и является обязательной
  основной для создания пользовательских шаблонов. Наличие зарегистрированного операторского
   шаблона позволяет выгодно тарифицировать ваши рассылки`,
    },
    [EType.USER]: {
      title: 'Пользовательский',
      description:
        'Текстовый шаблон на основе уже зарегистрированного Операторского, в который вы подставите свой текст и переменные и с ним будете отправлять сообщения дешевле ',
      short: 'П',
      tooltip: `<strong>Пользовательский шаблон</strong> - создан на основе зарегистрированного
 у оператора шаблона, но содержит уже подставленные значения переменных или финальный текст
  на конкретную тему обращения`,
    },
    [EType.CUSTOM]: {
      title: 'Свободный',
      description:
        'Шаблон "с нуля" без каких-либо ограничений, который может содержать  любой желаемый контент, разрешенный для этого канала',
      short: 'С',
      tooltip: `<strong>Свободный шаблон</strong> - готовое заранее созданное сообщение,
 которое можно использовать в рассылке`,
    },
  },
  status: {
    [EApproveStatus.APPROVED]: 'Активный',
    [EApproveStatus.REJECTED]: 'Отклонен',
    [EApproveStatus.PENDING]: 'Регистрация',
    [EApproveStatus.NOT_SENT]: 'Черновик',
    [EApproveStatus.PAUSED]: 'На паузе',
    [EApproveStatus.DISABLED]: 'Отключен',
    [EApproveStatus.ARCHIVED]: 'В архиве',
  },
  multiStatus: {
    [EMultiApproveStatus.FULLY_APPROVED]: 'Активный',
    [EMultiApproveStatus.PARTIALLY_APPROVED]: 'Частично активный',
    [EMultiApproveStatus.REJECTED]: 'Отклонен',
    [EMultiApproveStatus.PENDING]: 'Регистрация',
    [EMultiApproveStatus.NOT_SENT]: 'Черновик',
  },
  statusDescription: {
    [EApproveStatus.PAUSED]:
      'Шаблон остановлен на стороне WhatsApp из-за регулярных отрицательных отзывов клиентов. Его временно нельзя использовать в рассылках. Остановка продлится от 3 до 6 часов, после чего шаблон перейдет в статус Активный или Отключен.',
    [EApproveStatus.DISABLED]:
      'Шаблон отключен на стороне WhatsApp из-за регулярных отрицательных отзывов клиентов. Чтобы восстановить шаблон, его необходимо обжаловать. Обратитесь за помощью к вашему менеджеру',
  },
  list: {
    th: {
      name: 'Шаблон',
      type: 'Тип',
      status: 'Статус',
      channel: 'Канал',
      edited: 'Дата редактирования',
    },
    actions: {
      [EListAction.CREATE_BASED_ON]: 'Взять за основу',
      [EListAction.DUPLICATE]: 'Дублировать',
    },
    content: 'Шаблон содержит контент: {{types}}',
    contentType: {
      [EContentType.IMAGE]: 'изображение',
      [EContentType.BUTTON]: 'кнопка',
      [EContentType.DOCUMENT]: 'файл',
    },
  },
  deleteConfirm: {
    title: 'Вы уверены?',
    operatorBody:
      'После удаления шаблона он больше не будет доступен для дальнейшей работы. Если к шаблону были привязаны пользовательские - они также не будут доступны.',
    userBody: 'После удаления шаблона он больше не будет доступен для дальнейшей работы. ',
  },
  deleteDenied: {
    title: 'Требуется дополнительное действие',
    body1:
      'Мы не можем сейчас удалить этот шаблон, так как он используется в запланированных рассылках:',
    body2: 'Если вы отмените эти рассылки, удаление шаблона станет доступным.',
  },
  view: {
    sms: {
      operatorsLabel: 'Статус шаблона у операторов',
      operatorsLabelExtended: 'Шаблон доступен для рассылок по следующим операторам:',
      availableApproved: 'Доступен сейчас',
      availablePending:
        'Доступен только после регистрации операторского шаблона. До этого сообщения тарифицируются как рекламные',
      availableRejected: 'Недоступен из-за отклонения',
    },
  },
  editForm: {
    sms: {
      type: {
        [EType.OPERATOR]: {
          description:
            'Шаблоны этого типа необходимо регистрировать у оператора. В содержании шаблона может быть статический текст для SMS-сообщения и набор регулярных выражений. По умолчанию любое SMS-сообщение без согласованного операторского шаблона тарифицируется как рекламное.',
        },
        [EType.USER]: {
          description:
            'Пользовательский шаблон настраивается на основе активного операторского шаблона. В него можно добавлять текст или переменные вместо регулярных выражений. Пользовательский шаблон должен соответствовать тексту операторского шаблона,  включая статическую и переменную части. В обратном случае сообщения тарифицируются как рекламные.',
        },
        [EType.CUSTOM]: {
          description:
            'У свободного шаблона может быть любое содержание, разрешенное для этого канала. По умолчанию шаблон тарифицируется как рекламный.',
        },
      },
      steps: {
        [ESmsStep.DATA]: 'Данные шаблона',
        [ESmsStep.CONTENT]: 'Текст шаблона',
      },
      operators: {
        title: 'Шаблон доступен для рассылок у операторов:',
        label: 'Мобильные операторы, для которых регистрируется шаблон',
        hint: 'Выберите операторов, поддерживающих указанную категорию шаблона.',
        approved: 'Доступен после создания',
        pending:
          'Доступен только после регистрации операторского шаблона. До этого сообщения тарифицируются как рекламные',
        rejected: 'Недоступен из-за отклонения',
      },
      hints: {
        [EType.OPERATOR]:
          'Добавьте текст в шаблон. Используйте регулярные выражения для автоматической подстановки переменных или текста в шаблон.',
        [EType.USER]:
          'Текст шаблона изменить нельзя. Добавьте необходимые переменные или введите текст в указанных местах.',
        [EType.CUSTOM]: 'Используйте любой текст и добавляйте в него переменные.',
      },
      viewHints: {
        [EType.CUSTOM]: 'Текст шаблона изменить нельзя.',
        [EType.OPERATOR]:
          'Оператор зарегистрирует этот текст и порядок регулярных выражений в шаблоне как пример вашего исходящего сообщения. Регулярные выражения в пользовательском шаблоне или рассылке можно заменить на переменные или необходимый текст.',
      },
      subjectIds: 'Имя отправителя, на которое будет зарегистрирован шаблон',
      nameHint: 'Можно использовать любые буквы и символы.',
      parentId: 'Шаблон-основа',
      viewExamples: 'Посмотреть примеры шаблонов',
      checkText: 'Проверка примера текста на соотвествие шаблону',
      checkHint:
        'Вставьте пример текста, который вы будете отправлять своим пользователям по этому шаблону',
      category: 'Категория шаблона',
      categoryHint:
        'В категории операторского шаблона показывается тип трафика для ваших SMS-сообщений. После регистрации изменить категорию шаблона невозможно. Подробнее про категории шаблонов.',
      chooseCategory: 'Выберите категорию',
      operatorsRequired: 'Выберите хотя бы одного оператора',
      emptyOperators:
        'Нет доступных для выбора мобильных операторов, поддерживающих данную категорию',
      categories: {
        [ESmsCategory.SERVICE]: {
          title: 'Сервисный',
          description:
            'Сервисные сообщения предназначены для информирования клиентов о статусах оказания услуг. Подробнее.',
        },
        [ESmsCategory.AUTHORISATION]: {
          title: 'Авторизационный',
          description:
            'Авторизационные сообщения предназначены для информирования клиентов о процессах авторизации или верификации, для подтверждения каких-либо действий или предоставления согласия на услугу. Подробнее.',
        },
        [ESmsCategory.TEMPLATE_ADV]: {
          title: 'Шаблонированная реклама',
          description:
            'Сообщения шаблонированной рекламы предназначены для привлечения внимания к товарам или услугам и направлены на их продвижение. Шаблоны могут содержать только кириллические символы. Подробнее.',
        },
      },
    },
    whatsApp: {
      steps: {
        [EOperatorFormStep.DETAILS]: 'Данные шаблона',
        [EOperatorFormStep.TEXT_CONTENT]: 'Текст шаблона',
        [EOperatorFormStep.HEADER_AND_SIGNATURE]: 'Заголовок и подпись',
        [EOperatorFormStep.BUTTONS]: 'Интерактив в сообщении',
        [EUserFormStep.USER_CONTENT]: 'Содержание шаблона',
        [EAuthFormStep.AUTH_CONTENT]: 'Содержание шаблона',
      },
      dynamicUrl: 'Динамическая ссылка',
      nameHint:
        'В названии можно использовать только латинские буквы, цифры и подчеркивания (_). Другие символы и пробелы не допускаются.',
      subjectIds: 'Имя отправителя (аккаунт WhatsApp), на которое будет зарегистрирован шаблон',
      subjectIdsView: 'Шаблон доступен для следующих имен отправителей',
      subjectIdsError: 'Учетная запись WABA не найдена',
      subjectIdsHint:
        'По правилам Facebook шаблон регистрируется на вашу учетную запись WABA. После регистрации шаблон будет доступен для всех имен отправителей, привязанных к этой учетной записи WABA:',
      subjectIdsDescription:
        'В пользовательских шаблонах на основе этого операторского шаблона будет доступен только выбранный здесь отправитель',
      categoryHint: 'После регистрации изменить категорию шаблона невозможно',
      languageHint:
        'Выбранный язык должен соответствовать языку текста шаблона. Если языки не совпадают, шаблон будет отклонен.',
      language: 'Язык шаблона',
      category: 'Категория шаблона',
      [EType.OPERATOR]: {
        description:
          'Шаблоны этого типа необходимо регистрировать у оператора. Чтобы отправлять рассылки, создавайте пользовательские шаблоны с персональными настройками на основе зарегистрированного операторского.',
      },
      [EType.USER]: {
        description:
          'Настройте пользовательский шаблон на основе зарегистрированного операторского шаблона. Чтобы создавать пользовательские шаблоны, необходимо зарегистрировать хотя бы один операторский.',
      },
      textHint:
        'В шаблон необходимо добавить текст. В тексте вы можете использовать строки символов. В пользовательских шаблонах такие строки необходимо заменять на текст или переменные. Если строка символов расположена в начале или конце текста, для регистрации потребуется пример значения строки. Вы сможете ввести примеры после завершения четвертого шага создания шаблона.',
      viewTextHint:
        'Текст и порядок строк символов в шаблоне регистрируются у оператора как пример исходящего сообщения. Подставляйте конкретные переменные или необходимый текст вместо строк символов в пользовательском шаблоне и используйте его для рассылок вашим клиентам.',
      headerType: 'Заголовок',
      authTextHint_1: 'Стандартный текст авторизационного шаблона изменить нельзя.',
      authTextHint_2: ' Можно заменить только переменную',
      hasHeaderHint:
        'Добавьте заголовок в виде текста, картинки, видео или файла. После регистрации шаблона изменить тип заголовка невозможно. Если выбран заголовок с вложением (картинка, видео или файл), для регистрации потребуется пример файла. Вы сможете выбрать файл после завершения четвертого шага создания шаблона.',
      footer: 'Подпись',
      header: 'Текст заголовка',
      addFooterHint:
        'После регистрации шаблона изменить подпись невозможно. Максимальная длина подписи — 60 символов.',
      addFooter: 'Добавить подпись',
      footerText: 'Текст подписи',
      addButtons: 'Кнопки',
      authButtonsTitle: 'Кнопки в шаблоне',
      userButtonsHint: 'В шаблоне зарегистрированы следующие кнопки',
      authButtonsHint:
        'Авторизационный шаблон содержит одну обязательную кнопку копирования одноразового пароля. Заданные здесь параметры кнопки нельзя будет изменить после регистрации шаблона',
      addButtonsHint:
        'В одно сообщение можно добавить до 10 кнопок разного типа: несколько кнопок чата, две кнопки-ссылки и одну кнопку-звонок. Кнопки разделены на две секции: кнопки чата и кнопки призыва к действию (ссылки и звонок). После регистрации шаблона текст кнопок изменить нельзя.',
      contentType: {
        [EContentType.TEXT]: 'Заголовок-текст',
        [EContentType.IMAGE]: 'Заголовок-изображение',
        [EContentType.DOCUMENT]: 'Заголовок-файл',
        [EContentType.VIDEO]: 'Заголовок-видео',
      },
      button: 'Кнопка',
      buttonText: `Текст кнопки (до ${BUTTON_NAME_MAX_LENGTH} символов)`,
      phoneLabel: 'Номер телефона',
      buttonPayload: 'Код кнопки, payload (до 250 символов)',
      buttonPayloadHint:
        'Данное значение будет отображаться в отчете по сообщениям как входящее сообщение, если клиент ответит нажатием этой кнопки',
      linkButtonMessage: `Ссылка на кнопке может быть статической (фиксированной) или динамической. Динамическая должна состоять из двух частей. Первая – неизменяемая (например, раздел сайта https://example.com/marketing/). Вторая, конечная часть ссылки, может меняться для каждой рассылки, созданной с помощью этого шаблона (например, https://example.com/marketing/<b>action234</b> или https://example.com/marketing/<b>gift-1000</b>). <br/>В рассылке вы также сможете сделать динамическую ссылку индивидуальной для каждого получателя, если добавите в конце необходимую переменную. Чтобы сделать ссылку в шаблоне динамической, отметьте “сделать ссылку динамической”.`,
      phoneButtonMessage: 'Введите номер телефона с плюсом и кодом страны, например, +74951234567.',
      urlLabel: 'Ссылка (до 2 000 символов)',
      urlLabelView: 'Ссылка кнопки',
      deleteConfirmTitle: 'Удалить шаблон?',
      deleteConfirmText:
        'Введенные данные не сохранятся. Но вы можете сохранить шаблон как черновик и вернуться к нему позднее.',
      parentId: 'Шаблон-основа',
      parentIdView: 'Название шаблона-основы',
      parentIdHint:
        'Вы сможете добавить персональные настройки в этот зарегистрированный операторский шаблон. Важно! Для изменения доступны строки символов, вложения и динамическая часть ссылки для кнопки-ссылки. Основной текст, заголовок, подпись и другие параметры кнопок менять нельзя.',
      parentIdPlaceHolder: 'Выберите шаблон',
      userTextHint_1: 'Текст шаблона изменить невозможно. ',
      userTextHint_2: ' Подставьте необходимые переменные или введите текст в указанных местах.',
      userHeaderHint: {
        [EContentType.DOCUMENT]:
          'В шаблоне предусмотрен заголовок-документ. Сам документ вы вставите при создании рассылки на основе этого шаблона.',
        [EContentType.IMAGE]:
          'В этом шаблоне предусмотрен заголовок-картинка. Само изображение вы вставите при создании рассылки на основе этого шаблона.',
        [EContentType.VIDEO]:
          ' В этом шаблоне предусмотрен заголовок-видео. Само видео вы вставите при создании рассылки на основе этого шаблона.',
        [EContentType.TEXT]:
          'Текст заголовка наследуется из выбранного операторского шаблона и не может быть изменен.',
      },
      chatButtons: 'Кнопки чата (quick reply)',
      callButtons: 'Кнопки призыва к действию (Call to action)',
    },
    id: 'ID шаблона',
    name: 'Название шаблона',
    nameHint: `Для названия шаблона используйте латинские буквы, также допускается нижнее подчеркивание. Использование заглавных букв, пробелов - не допускается. От ${TEMPLATE_NAME_MIN_LENGTH} до ${TEMPLATE_NAME_MAX_LENGTH} символов`,
    subjectIds: 'Выберите имена отправителей',
    subjectIdsUserHint: 'Эти отправители указаны в операторском шаблоне.',
    type: 'Какой шаблон вы хотите создать',
    title: 'Новый шаблон',
    subject: 'Имя отправителя',
    contentType: 'Выберите тип сообщения',
    exampleText: 'Введите текст примера сообщения',
    registeredTemplateText: 'Текст зарегистрированного шаблона',
    textBasedOnTemplate: 'Введите текст на основе шаблона',
    description: 'Оставьте комментарий, если требуется',
    userTypeText: 'Введите текст сообщения',
    textWithRegexp: 'Введите текст шаблона и регулярные выражения',
    textWithVariables: 'Введите текст шаблона и переменные',
    textWithVariablesHint:
      'Вы можете обратиться к получателю сообщения по имени, указав в тексте сообщения тег {имя} или {name}. Например, «Добрый день, {имя}! Приятного дня». Если в списке получателей будет заполнено имя, то SMS будет вида «Добрый день, Юлия! Приятного дня». Если имя не будет заполнено, то тег будет пропущен.',
    text: 'Введите текст шаблона',
    language: 'Выберите язык',
    category: 'Выберите категорию',
    categoryHint: {
      [EWhatsAppCategory.UTILITY]: `К служебным сообщениям относятся:
1. Информация об изменениях в учетной записи, статусе заказа или программе лояльности;
2. Уведомление о получении платежа, подтверждение денежных переводов, прочие транзакции в сфере финансовых услуг`,
      [EWhatsAppCategory.AUTHENTICATION]: `Авторизационные сообщения – это одноразовые коды для личного кабинета или приложения, которые позволят вашим пользователям получить безопасный доступ к учетным записям.`,
      [EWhatsAppCategory.MARKETING]:
        'Маркетинговые сообщения – полезные новости о компании, предложения с акциями и скидками, информация о мероприятиях и вебинарах, upsell, cross-sell, напоминания о брошенной корзине.',
    },
    categoryMessage: {
      [EWhatsAppCategory.MARKETING]: `К маркетинговым сообщениям относятся полезные новости о компании, предложения с акциями и скидками, информация о мероприятиях и вебинарах, upsell, cross-sell, напоминания о брошенной корзине`,
      [EWhatsAppCategory.UTILITY]: `К служебным сообщениям относятся:
1. Информация об изменениях в учетной записи, статусе заказа или программе лояльности;
2. Уведомление о получении платежа, подтверждение денежных переводов, прочие транзакции в сфере финансовых услуг`,
      [EWhatsAppCategory.AUTHENTICATION]: `Авторизационные сообщения – это одноразовые коды для личного кабинета или приложения, которые позволят вашим пользователям получить безопасный доступ к учетным записям.`,
    },
    categoryWarning:
      'WhatsApp может изменить категорию шаблона при модерации. В случае изменения категории шаблона изменится и стоимость диалога, инициированного отправкой сообщения по данному шаблону. Учитывайте это при планировании бюджета.',
    hasHeader: 'Добавить заголовок',
    headerType: 'Тип заголовка',
    header: 'Содержание',
    addFooter: 'Добавить подпись сообщения',
    addButtons: 'Добавить кнопки',
    addImages: 'Добавить изображения',
    checkCompliance: 'Проверить соответствие',
    categories: {
      [EViberCategory.AccountUpdate]: 'Обновление в аккаунте',
      [EViberCategory.AlertUpdate]: 'Обновление данных об оповещениях',
      [EViberCategory.AppointmentUpdate]: 'Изменение статуса записи',
      [EViberCategory.IssueResolution]: 'Решение проблемы',
      [EViberCategory.PaymentUpdate]: 'Обновление платежа',
      [EViberCategory.PersonalFinanceUpdate]: 'Обновление в личных финансах',
      [EViberCategory.ReservationUpdate]: 'Обновление бронирования',
      [EViberCategory.ShippingUpdate]: 'Изменение статуса доставки',
      [EViberCategory.TicketUpdate]: 'Обновление данных о билетах',
      [EViberCategory.AutoReply]: 'Автоответчик',
      [EViberCategory.TransportationUpdate]: 'Обновление данных о транспорте',
      [EOldWhatsAppCategory.TRANSACTIONAL]: 'Транзакционные сообщения',
      [EWhatsAppCategory.MARKETING]: 'Маркетинговые сообщения',
      [EOldWhatsAppCategory.OTP]: 'Одноразовые пароли',
      [EWhatsAppCategory.AUTHENTICATION]: 'Авторизационные сообщения',
      [EWhatsAppCategory.UTILITY]: 'Служебные сообщения',
    },
    addButtonsHint: 'В одном сообщении могут быть либо кнопки-ответ, либо кнопки call-to-action',
    buttonType: 'Тип кнопки',
    buttonTypePlaceholder: 'Выберите тип кнопки',
    buttonFields: {
      resetTemplate: 'Сбросить шаблон',
      textLabel: 'Название кнопки',
      urlLabel: 'Ссылка кнопки',
      urlHint: 'Разрешено использовать только https ссылки',
      payloadLabel: 'Код кнопки',
      payloadHint: 'Используйте этот код, чтобы отслеживать нажатие кнопок через API',
      phoneLabel: 'Номер телефона (необходимо указать с + и кодом страны)',
      phoneHint: 'Телефон необходимо вводить с плюсом и кодом страны. Например, +74951234567',
      urlPostfixLabel: 'Сделать ссылку кнопки динамической',
    },
    buttonTypes: {
      [EMessageMatcherButtonTypes.PHONE]: 'Кнопка-звонок',
      [EMessageMatcherButtonTypes.URL]: 'Кнопка-ссылка',
      [EMessageMatcherButtonTypes.CHAT]: 'Кнопка чата',
      [EMessageMatcherButtonTypes.LOCATION]: 'Локация',
      [EMessageMatcherButtonTypes.VK_MINI_APPS]: 'VK Mini Apps',
    },
    addButtonText: 'кнопку',
    buttonWithCount: 'Кнопка {{count}}',
    parentId: 'Какой шаблон выберем для создания нового Пользовательского',
    testTemplate: 'Проверить шаблон',
    exampleMatches: 'Пример соответствует',
    exampleNotMatches: 'Пример не соответствует',
    additionalParameters: 'Дополнительные параметры',
    // viber custom content
    optional: ' (необязательно)',
    imageLabel: 'Добавьте картинку',
    uploadDocument: 'Загрузить с компьютера',
    addDocumentLink: 'Вставить ссылку на файл',
    documentLabel: 'Загрузить файл',
    documentUrlLabel: 'Адрес ссылки на файл',
    documentUrlHint:
      'Поддерживаемые форматы вложений: текстовые файлы (.doc, .docx, .txt, .rtf, .dot, .dotx, .odt, odf, .fodt, .info), PDF, Excel (.xls и .xlsx) и графические файлы (.xps, .pdax, .eps). Максимальный размер файла 25Мб',
    registrationMessage:
      'Шаблон находится на регистрации. Статус регистрации отображается в списке шаблонов в разделе Шаблоны',
    registrationMessage_one:
      'Шаблон находится на регистрации, это займет примерно {{count}} день. Статус регистрации отображается в списке шаблонов в разделе Шаблоны',
    registrationMessage_few:
      'Шаблон находится на регистрации, это займет примерно {{from}}-{{count}} дня. Статус регистрации отображается в списке шаблонов в разделе Шаблоны',
    registrationMessage_many:
      'Шаблон находится на регистрации, это займет примерно {{from}}-{{count}} дней. Статус регистрации отображается в списке шаблонов в разделе Шаблоны',
    linkButtonMessage:
      'Ссылка на кнопке может быть статической (фиксированной) или динамической. Во втором случае она должна состоять из двух частей. Первая – неизменяемая (например, раздел сайта https://example.com/marketing/). Вторая, конечная часть ссылки, может меняться для каждой рассылки, созданной с помощью этого шаблона (например, https://example.com/marketing/action234 или https://example.com/marketing/gift-1000). В рассылке вы также сможете сделать динамическую ссылку индивидуальной для каждого получателя, если добавите в конце необходимую переменную. Чтобы сделать ссылку в шаблоне динамической, отметьте “сделать ссылку динамической”.',
  },
  regularExpressionsTitle: 'Регулярные выражения',
  whatsAppRules: {
    title: 'Правила вставки строки символов',
    hint: `Строка символов – это место в шаблоне, которое оператор зафиксирует как такое, где значения будут меняться. Т.е. в дальнейшем вы сможете вставить в этом месте свои переменные.
Вместо строки символов можно будет вставить любое сочетание слов, чисел, символов и пробелов, <0>кроме символа &</0>`,
  },
  viberRules: {
    title: 'Правила использования символов в шаблоне',
    rule1:
      'Удалите из шаблона на согласование следующие символы: /! No # % . , : ; ?  / ( ) + - “ ”― _ \' " ` & ^ ? { } [ ] < > /  | ! @ # $ % ^ ( ) + = ~ *',
    rule2: 'Замените переносы строк на пробел',
    rule3: 'Повторяющиеся подряд пробелы замените на один пробел',
    info1:
      'Для регистрации у Вайбер в шаблоне не должно быть спецсимволов. Но в сообщения рассылки, созданные на основе этого шаблона, вы сможете вставить эти символы. Если на основе этого зарегистрированного шаблона вы сделаете пользовательский шаблон, то в него вы также сможете добавить эти спецсимволы.',
    info2: 'Можете использовать документ <0>Правила составления шаблонов для Viber</0>',
  },
  smsRegularExpressions: [
    {key: '%w', value: 'Любой непрерывный набор букв и/или спецсимволов'},
    {
      key: '%w{1, 10}',
      value: 'Ограниченная последовательность слов,',
      hint: 'состоящих из букв, цифр или спецсимволов, разделенных пробелом(-ами), где 10 – максимальное количество слов',
    },
    {key: '%d', value: 'Любой непрерывный набор цифр и/или спецсимволов'},
    {
      key: '%d{1,10}',
      value: 'Ограниченная последовательность чисел,',
      hint: 'состоящих из цифр или спецсимволов, разделенных пробелом(-ами), где 10 - максимальное количество чисел',
    },
  ],
  viberRegularExpressions: [
    {key: '\\d+', value: 'Цифра или число,'},
    {
      key: '\\w+',
      value: 'Любой непрерывный набор букв и/или цифр,',
    },
    {
      key: '[\\s\\w]+',
      value: 'Последовательность слов,',
      hint: 'состоит хотя бы из одного элемента из списка или их комбинации: буква, слово, цифра, число, пробел, перенос строки',
    },
    {
      key: '[\\s\\d]+',
      value: 'Последовательность из нескольких чисел и/или цифр',
    },
  ],
  viberRegularExpressionsHint:
    'Это самые популярные регулярные выражения для шаблонов Viber, вы можете также добавить другие.',
  exampleTemplatesTitle: 'Примеры шаблонов',
  exampleTemplatesTemplate: 'Зарегистрированный шаблон',
  exampleTemplatesMessage: 'Сообщение на основе шаблона',
  viberExampleTemplates: [
    [
      {
        key: '[\\s\\w]+',
        value: 'Иван Петрович',
        text: '<0>,</0> Оплата услуг картой в личном кабинете недоступна ',
      },
      {
        key: '\\d+',
        value: '10<0>.</0>01<0>.</0>2021',
        text: ` из<0>-</0>за `,
      },
      {
        key: '[\\s\\w]+',
        value: 'проведения технических работ',
        text: '<0>.</0> Приносим извинения за доставленные неудобства<0>.</0>',
      },
    ],
    [
      {
        key: '[\\s\\w]+',
        value: 'Нина Семеновна',
        text: '<0>,</0> с ',
      },
      {
        key: '\\d+',
        value: '14<0>.</0>06<0>.</0>2021',
        text: ' меняется тарифный план карты Полезная<0>.</0> С новыми условиями можете ознакомиться по ссылке ',
      },
      {
        key: '[\\s\\w]+',
        value: 'https<0>://</0>site<0>.</0>com<0>/</0>UmR8E',
      },
    ],
    [
      {
        key: '\\w+',
        value: 'Елена',
        text: `<0>,</0> напоминаем о записи в службу передержки <0>"</0>Питомец<0>"</0> с `,
      },
      {
        key: '\\d+',
        value: '10<0>.</0>10<0>.</0>2021',
        text: '<0>.</0> К оплате<0>:</0> ',
      },
      {key: '[\\s\\d]+', value: '2 000', text: 'руб<0>.</0> Ваш питомец '},
      {
        key: '[\\s\\w]+',
        value: 'доберман Макс',
        text: ' <0>-</0> наш долгожданный гость<0>!</0>',
      },
    ],
  ],
  specialCharactersHint: `
    - Спецсимволы, которые были удалены в шаблоне для регистрации, можно вернуть при отправке сообщения
     или при создании пользовательского шаблона
  `,
  smsExampleTemplates: [
    [
      {
        key: '%w{1,10}',
        value: 'Иван Петрович,',
        text: ' Оплата услуг картой в личном кабинете недоступна ',
      },
      {key: '%d', value: '10.01.2021', text: ' из-за '},
      {
        key: '%w{1,10}',
        value: 'проведения технических работ.',
        text: ' Приносим извинения за доставленные неудобства.',
      },
    ],
    [
      {
        key: '%w{1,10}',
        value: 'Нина Семеновна,',
        text: ' с ',
      },
      {
        key: '%d',
        value: '14.06.2021',
        text: ' меняется тарифный план карты Полезная. С новыми условиями можете ознакомиться по ссылке ',
      },
      {key: '%w', value: 'https://site.com/UmR8E'},
    ],
    [
      {
        key: '%w{1,10}',
        value: 'Елена,',
        text: ` напоминаем о записи в службу передержки "Питомец" с `,
      },
      {key: '%d', value: '10.10.2021', text: '. К оплате: '},
      {
        key: '%w{1,10}',
        value: 'доберман Макс',
        text: ' - наш долгожданный гость!',
      },
    ],
  ],
  selectMessage: {
    placeholder: 'Найти шаблон',
    name: 'Название шаблона',
    type: 'Тип',
    date: 'Дата последнего использования',
    action: 'Использовать',
  },
  successRegister: {
    head: 'Шаблон отправлен на регистрацию!',
    text: 'Проверка и регистрация займут у оператора какое-то время. Следите за статусом шаблона в списке шаблонов',
    textWithDays_one:
      'Проверка и регистрация шаблона займут у оператора примерно {{count}} день. Следите за статусом шаблона в списке',
    textWithDays_few:
      'Проверка и регистрация шаблона займут у оператора примерно {{from}}-{{count}} дня. Следите за статусом шаблона в списке',
    textWithDays_many:
      'Проверка и регистрация шаблона займут у оператора примерно {{from}}-{{count}} дней. Следите за статусом шаблона в списке',
    mainButton: 'На главную',
    listButton: 'К списку',
  },
  empty: {
    title: 'Упс.. Тут пока пусто',
    subtitle: 'К сожалению, у вас пока нет ни одного шаблона. Но надо же с чего-то начинать',
    action: 'Перейти к созданию шаблона',
  },
  filters: {
    status: 'Статус шаблона',
    channelType: 'Канал',
    type: 'Тип шаблона',
    channelName: 'Название канала',
    templates: 'Шаблонов',
  },
  template: {
    options: {
      variable: 'Переменная',
      text: 'Текст',
      changeToText: 'Поменять на текст',
      changeToVariable: 'Поменять на переменную',
    },
    placeholders: {
      variable: 'Выберите переменную',
      textOrVariable: 'текст или переменная',
    },
    title: 'Текст шаблона',
    postTitle: ' (подставьте переменную или введите текст в указанных местах )',
  },
  message:
    'Основной текст и наполнение шаблона менять нельзя. Изменить можно только переменные и файлы-вложения',
  parameterExamplesModal: {
    title: 'Добавьте пример контента шаблона',
    text: 'Чтобы Facebook понимал, какого рода сообщения вы собираетесь рассылать своим клиентам, нужны конкретные примеры контента в вашем шаблоне',
    header: 'Заголовок',
    [EContentType.IMAGE]: {
      label: 'Картинка для примера (.jpg или .png до 5 МБ)',
      addLink: 'Загрузить ссылку на картинку',
      hint: 'Url картинки (должен содержать на конце расширение файла. Например: https://ltdfoto.ru/image/lRe1bG.png)',
    },
    [EContentType.VIDEO]: {
      label: 'Видео для примера (.mp4, .3gpp до 5 МБ)',
      addLink: 'Загрузить ссылку на видео',
      hint: 'Url видео (должен содержать на конце расширение файла. Например: https://ltdfoto.ru/lRe1bG.mp4)',
    },
    [EContentType.DOCUMENT]: {
      label: 'Файл для примера (.pdf)',
      addLink: 'Загрузить ссылку на файл',
      hint: 'Url файла (должен содержать на конце расширение .pdf. Например: https://ltdfoto.ru/lRe1bG.pdf)',
    },
    upload: 'Загрузить с компьютера',
    textLabel: 'Содержание',
    textHint: 'Текст шаблона (вставьте примеры переменной или текста в указанных местах)',
    example: `Пример для {\u200b{\u200b{{variableName}}\u200b}\u200b}`,
    linkButton: 'Кнопка со ссылкой',
    linkButtonHint: 'Добавьте пример ссылки в кнопке',
    linkButtonText: 'Текст кнопки:',
    linkButtonUrlHint:
      'Введите полный URL-адрес, который вы планируете использовать в шаблоне. Пример: https://weblink.com/1" . Где https://weblink.com/ - это ссылка кнопки, введенная при заполнение формы шаблона. 1 - это изменяемая часть ссылки.',
  },
  vkNotify: {
    images: 'Изображения для шаблона',
    imagePickerAdd: ' изображение',
    imagesHint: `Вы можете загрузить до ${MAX_ATTACHMENTS_LENGTH} изображений. Допустимые форматы: JPG, PNG, GIF. Суммарный размер файлов не более ${MAX_FILE_SIZE_MB} МБ. Сумма высоты и ширины не более ${MAX_FILE_DIMENSIONS_SUM} пикселей, соотношение сторон не менее 1:20.`,
    button: 'Кнопка',
    buttons: 'Кнопки для шаблона',
    buttonsHint: `Вы можете добавить кнопки разного типа (до ${MAX_BUTTONS_LENGTH} суммарно) . После регистрации шаблона этот набор кнопок нельзя изменить.`,
    buttonText: 'Текст кнопки (до 40 символов)',
    buttonPayload: 'Код кнопки, payload (до 255 символов)',
    buttonColor: 'Цвет кнопки',
    buttonColorPlaceholder: 'Выберите цвет',
    buttonHash: 'Хэш приложения (не обязательно)',
    buttonOwnerId: 'owner_id',
    buttonAppId: 'app ID',
    buttonLocation: 'Место',
    invalidImageFormat: 'Ошибка загрузки изображения: неверный формат',
  },
  vkNotifyUserAnswer: {
    label: 'Ответы пользователей',
    on: 'Включить',
    onHint:
      'Пользователи могут отвечать на уведомления в чате, а компания – продолжать диалог с ними. Читайте и отвечайте на входящие сообщения получателей через сообщения сообщества VK/группы OK.',
    off: 'Отключить',
    offHint: 'Компания отправляет пользователям бизнес-уведомления без возможности ответа.',
  },
  vkNotifyButtons: {
    [EMessageMatcherButtonTypes.CHAT]: 'Отправляет сообщение с текстом в диалог с ботом или беседу',
    [EMessageMatcherButtonTypes.URL]: 'Открывает указанную ниже ссылку',
    [EMessageMatcherButtonTypes.LOCATION]:
      'Отправляет местоположение пользователя в диалог с ботом или беседу',
    [EMessageMatcherButtonTypes.VK_MINI_APPS]: 'Открывает указанное приложение VK Mini Apps',
  },
  vkNotifyColors: {
    [EMessageMatcherButtonColor.PRIMARY]: 'Синий',
    [EMessageMatcherButtonColor.SECONDARY]: 'Серый',
    [EMessageMatcherButtonColor.POSITIVE]: 'Зеленый',
    [EMessageMatcherButtonColor.NEGATIVE]: 'Красный',
  },
  whatsAppAuthTemplate: {
    text: 'Текст сообщения',
    addSecurityRecommendation: 'Добавить рекомендации по безопасности',
    securityRecommendationHint:
      'К сообщению с кодом будет добавлено предупреждение: “Из соображений безопасности никому не сообщайте этот код.“',
    userSecurityRecommendationHint:
      'К сообщению с кодом добавлено предупреждение: “Из соображений безопасности никому не сообщайте этот код.“',
    addPasswordExpiration: 'Добавить срок действия одноразового пароля',
    passwordExpirationTimeHint: 'Укажите время от 1 до 90 минут',
    passwordExpirationHint:
      'К сообщению с кодом будет добавлено предупреждение: “Срок действия этого кода истекает через <NUM_MINUTES> мин.”',
    userPasswordExpirationHint:
      'К сообщению с кодом добавлено предупреждение: “Срок действия этого кода истекает через {{codeExpirationMinutes}} мин.”',
    baseText: 'Ваш код подтверждения – {{1}}.',
    securityRecommendationText: ' Из соображений безопасности никому не сообщайте этот код.',
    passwordExpirationText: 'Срок действия этого кода истекает через {{count}} {{minutes}}.',
    button: 'Кнопка в сообщении',
    buttonText: 'Текст кнопки',
    buttonName: 'Название кнопки',
    buttonLabel: {
      [ETemplateButtonOtpType.COPY]: 'Копировать код',
      [ETemplateButtonOtpType.AUTO]: 'Автоматическое заполнение',
    },
    autoButtonText: 'Заполнить автоматически',
    package: 'Название пакета',
    hash: 'Хэш подписи приложения',
    autofillText: 'Текст кнопки “Копировать код”',
    handshakeCopyButton:
      'Текст кнопки “Копировать код”. Эта кнопка используется, если ваше приложение не может выполнить процедуру "рукопожатия". <0>Подробнее.</0>',
    handshakeLink:
      'https://developers.facebook.com/docs/whatsapp/business-management-api/authentication-templates#handshake',
  },
};
