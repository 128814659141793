import {EContentType, EOperatorName} from 'src/constants';

export default {
  title: 'Edna Pulse',
  support: 'Поддержка',
  passwordValid: 'Пароль безупречен',
  button: {
    yes: 'Да',
    no: 'Нет',
    back: 'Назад',
    ok: 'Ок',
    create: 'Создать',
    new: 'Новый',
    download: 'Скачать',
    upload: 'Загрузить',
    next: 'Далее',
    save: 'Сохранить',
    saveDraft: 'Сохранить как черновик',
    cancel: 'Отменить',
    cancel2: 'Отмена',
    reset: 'Сбросить',
    resetAll: 'Сбросить все',
    apply: 'Применить',
    accept: 'Принять',
    check: 'Проверить',
    close: 'Закрыть',
    copy: 'Копировать',
    view: 'Просмотреть',
    edit: 'Редактировать',
    delete: 'Удалить',
    actions: 'Действия',
    submit: 'Отправить',
    add: 'Добавить',
    continue: 'Продолжить',
    confirm: 'Подтвердить',
    register: 'Отправить на регистрацию',
    toPlan: 'Запланировать',
    toMain: 'На главную',
    toList: 'К списку',
    toProfile: 'Назад в Профиль',
    goToBack: 'Вернуться и проверить',
    update: 'Обновить',
    change: 'Изменить',
    understand: 'Понятно',
    search: 'Найти',
  },
  operator: {
    [EOperatorName.RuMts]: 'МТС',
    [EOperatorName.RuBeeline]: 'Билайн',
    [EOperatorName.RuTele2]: 'Tеле2',
    [EOperatorName.RuMegafon]: 'Мегафон',
    [EOperatorName.RuYota]: 'Скартел (Yota)',
    [EOperatorName.RuVainahtelecom]: 'Вайнах Телеком',
    [EOperatorName.RuMotiv]: 'Мотив',
    [EOperatorName.RuKtelecom]: 'К-Телеком',
    [EOperatorName.RuKtktelecom]: 'КТК Телеком',
    [EOperatorName.RuSbertelecom]: 'СберМобайл',
    [EOperatorName.RuDefault]: 'Прочие операторы',
    [EOperatorName.IoDefault]: 'Международные операторы',
  },
  contentType: {
    [EContentType.TEXT]: 'Текст',
    [EContentType.IMAGE]: 'Изображение',
    [EContentType.BUTTON]: 'Сообщение с кнопкой',
    [EContentType.DOCUMENT]: 'Документ',
    [EContentType.VIDEO]: 'Видео',
  },
  time: {
    seconds_one: 'секунды',
    seconds_few: 'секунд',
    seconds_many: 'секунд',
    minutes_one: 'минуты',
    minutes_few: 'минут',
    minutes_many: 'минут',
    hours_one: 'часа',
    hours_few: 'часов',
    hours_many: 'часов',
    days_one: 'дня',
    days_few: 'дней',
    days_many: 'дней',
  },
  time_alternate: {
    seconds_one: 'секунда',
    seconds_few: 'секунды',
    seconds_many: 'секунд',
    minutes_one: 'минута',
    minutes_few: 'минуты',
    minutes_many: 'минут',
    hours_one: 'час',
    hours_few: 'часа',
    hours_many: 'часов',
    days_one: 'день',
    days_few: 'дня',
    days_many: 'дней',
  },
  gender: {
    male: 'Мужской',
    female: 'Женский',
  },
  boolean: {
    true: 'Есть',
    false: 'Нет',
  },
  loadingError: 'Ошибка при загрузке файла',
  loadingByUrlError: 'Ошибка при загрузке по ссылке',
  loadingByUrlSuccess: 'Загрузка по ссылке прошла успешно',
  step: 'Шаг {{count}}',
  successDownload: 'Файл успешно скачан',
  errorDownload: 'Произошла ошибка при скачивании',
  changeHistory: 'История изменения',
  weekdays: {
    0: 'Понедельник',
    1: 'Вторник',
    2: 'Среда',
    3: 'Четверг',
    4: 'Пятница',
    5: 'Суббота',
    6: 'Воскресенье',
  },
  attachAnotherFile: 'Загрузить другой файл',
  attachFile: 'Загрузить файл',
  more: 'Подробнее',
  monthsKeysByIndex: {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  },
};
