import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

type TProps = {
  isValid?: boolean;
};

export const Rules = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const RuleItem = styled.div<TProps>`
  ${mixins.flexAlign('flex-start', 'center')}
  ${mixins.f2}
  position: relative;
  flex: 0 0 50%;
  ${({isValid, theme: {spacing, branding}}) => css`
    color: ${isValid ? branding.content.secondary : branding.colors.disabled};
    margin-bottom: ${spacing.s2};
    padding-right: ${spacing.s2};
  `}
`;

export const RuleCheck = styled.div<TProps>`
  ${mixins.round('14px')}
  ${mixins.flexAlign('center')}
  flex-shrink: 0;
  ${({theme, isValid}) => css`
    margin-right: ${theme.spacing.s2};
    border: 2px solid ${isValid ? theme.branding.content.secondary : theme.branding.colors.disabled};
  `}
`;
