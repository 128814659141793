import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {TValue as TPickerValue} from '@edna/components/PeriodPicker';
import getPeriod from '@edna/utils/getPeriod';

import {TItemId} from 'src/constants';
import PATHS from 'src/paths';

import {LIST_CONFIG} from '../definitions';
import {setFiltersAction} from '../slice';

export type TNavigateToReportByBroadcastId = {
  period?: TPickerValue;
  broadcastId?: TItemId;
};

export const useNavigateToReportByBroadcastId = ({
  period,
  broadcastId,
}: TNavigateToReportByBroadcastId) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return React.useCallback(async () => {
    if (!broadcastId) {
      return;
    }

    dispatch(
      setFiltersAction({
        ...LIST_CONFIG.filtersConfig.defaultFilters,
        broadcasts: [Number(broadcastId)],
        period: period ? period : getPeriod('hours24'),
      }),
    );

    navigate(PATHS.ANALYTICS_MESSAGES);
  }, [broadcastId, dispatch, navigate, period]);
};
