import styled, {CSSProperties, css} from 'styled-components';

import {mixins} from '@edna/components/styles';

import {EOrientation, ESize, TOrientation, TSize} from './types';

type TPageDialogProps = {
  orientation: TOrientation;
};

export const PageDialog = styled.div<TPageDialogProps>`
  ${({orientation}: TPageDialogProps) => {
    const common = css`
      width: 100%;
    `;

    switch (orientation) {
      case EOrientation.VERTICAL:
        return css`
          ${common}
          ${mixins.flexAlign('flex-start', 'stretch')}
          flex-direction: column;
        `;
      case EOrientation.HORIZONTAL:
      default:
        return css`
          ${common}
          ${mixins.flexAlign('space-between', 'center')}
        `;
    }
  }}
`;

export const Content = styled.div`
  ${mixins.flexAlign('flex-start')}
  flex: 0 0 50%;
  flex-direction: column;
`;

export const Image = styled.img`
  flex: 0 0 50%;
  margin: auto;
`;

type TTitleProps = {
  size: TSize;
  hasTopIndent?: boolean;
};

export const Title = styled.div<TTitleProps>`
  ${({theme, size, hasTopIndent}) => {
    const common = css`
      margin-top: ${hasTopIndent ? theme.spacing.s10 : 0};
      margin-bottom: ${theme.spacing.s4};
    `;

    switch (size) {
      case ESize.SMALL:
        return css`
          ${common}
          ${mixins.f6}
          font-weight: 500;
        `;
      case ESize.MEDIUM:
      default:
        return css`
          ${common}
          ${mixins.f8}
          font-weight: 600;
        `;
    }
  }}
`;

type TSubTitleProps = {
  size: TSize;
};

export const SubTitle = styled.div<TSubTitleProps>`
  ${({theme, size}) => {
    const common = css`
      margin-bottom: ${theme.spacing.s4};
    `;

    switch (size) {
      case ESize.SMALL:
        return css`
          ${common}
          ${mixins.f3}
        `;
      case ESize.MEDIUM:
      default:
        return css`
          ${common}
          ${mixins.f5}
        `;
    }
  }}
`;

type TActionProps = {
  justifyContent: CSSProperties['justifyContent'];
};

export const Actions = styled.div<TActionProps>`
  ${({theme, justifyContent}) => css`
    display: flex;
    justify-content: ${justifyContent};
    margin-top: ${theme.spacing.s8};
  `}
`;
