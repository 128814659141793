import {EDocumentType, EPaymentType} from 'src/containers/User';

import {EDetailsAction} from './definitions';

export default {
  empty: 'Не указано',
  kpp: 'КПП',
  legalAddress: 'Юридический адрес',
  postAddress: 'Почтовый адрес для документов',
  modalTitle: {
    companyDetails: 'реквизиты организации',
    paymentDetails: 'платежные реквизиты',
  },
  reminderModal: {
    title: 'Вы кое-что забыли',
    text: 'Вы не сможете зарегистрировать канал пока не заполнены реквизиты вашей компании и платежные реквизиты',
    button: 'Заполнить реквизиты',
  },
  contractInfo: 'Данные договора и правила взаиморасчетов',
  companyRequisites: 'Реквизиты организации',
  companyDetails: 'Данные компании',
  contactDetails: 'Контактное лицо клиента',
  settlementRules: 'Правила взаиморасчетов',
  licenseConditions: 'Условия подключения лицензий',
  paymentDetails: 'Платежные реквизиты',
  country: 'Страна',
  taxNumber: 'ИНН',
  blrTaxNumber: 'УНП',
  binNumber: 'Бизнес-идентификационный номер (БИН)',
  ogrn: 'ОГРН/ОГРНИП',
  currencyCode: 'Валюта',
  // TODO: когда на бэке добавят поле, проверить ключи на схожесть
  accountType: 'Тип аккаунта',
  documentType: 'Тип договора',
  documentTypes: {
    [EDocumentType.AGREEMENT]: 'Оферта',
    [EDocumentType.CONTRACT]: 'Бумажный договор',
    [EDocumentType.TECH_PARTNER]: 'Технический партнер',
  },
  securityDeposit: 'Размер обеспечительного платежа',
  paymentType: 'Тип оплаты',
  paymentTypes: {
    [EPaymentType.MIXED_PREPAYMENT]: 'Смешанная оплата',
    [EPaymentType.POSTPAID]: 'Постоплата',
    [EPaymentType.PREPAYMENT]: 'Предоплата',
  },
  contactName: 'ФИО',
  phone: 'Телефон',
  email: 'Email',
  resellerId: 'Юрисдикция',
  maxOverdraft: 'Размер овердрафта',
  managerResponsibleForCheckingId: 'Ответственный за проверку компании',
  // TODO: когда на бэке добавят поле, проверить ключи на схожесть
  validity: 'Срок действия',
  activateLicenseDebt: 'Активация лицензии в долг',
  activateLicenseDebtOptions: {
    true: 'Разрешена',
    false: 'Запрещена',
  },
  licensePaymentTerm: 'Срок оплаты лицензии',
  termBeforeTerminationLicense: 'Срок до аннулирования лицензии при неоплате',
  // TODO: когда на бэке добавят поле, проверить ключи на схожесть
  overdraftAgreed: 'Овердрафт согласовал',
  companyName: 'Название организации',
  editContactInfo: 'Изменить контакты клиента?',
  editContactName: 'ФИО контактного лица компании',
  siteAddress: 'Сайт',
  bankName: 'Название банка',
  bic: 'BIC',
  rcbic: 'БИК',
  blrBic: 'БИК / SWIFT',
  operBankAccount: 'Расчетный счет',
  corrBankAccount: 'Корреспондентский счет',
  authorizedPersonName: 'Авторизованный пользователь',
  registrationEmail: 'Email при регистрации',
  vatNumber: 'Номер НДС',
  bankAddress: 'Адрес банка',
  blrBankAddress: 'Юридический адрес банка',
  iban: 'IBAN (International Bank Account Number, международный номер банковского счета)',
  blrIban: 'Расчетный счет / IBAN',
  detailsAction: {
    [EDetailsAction.ADD]: 'Дополнить {{detailsName}}',
    [EDetailsAction.FILL]: 'Заполнить {{detailsName}}',
    [EDetailsAction.UPDATE]: 'Редактировать {{detailsName}}',
  },
  notification:
    'Чтобы зарегистрировать каналы, вам необходимо полностью заполнить реквизиты компании и платежные реквизиты. Согласованные  с менеджером тарифы вы найдете во вкладке Тарифы',
};
