import React from 'react';

import {FieldFooter} from '../primitives';
import {EFooterStatus} from './definitions';
import * as S from './style';

type TProps = {
  successLabel?: string;
  warningLabel?: string;
  errorLabel?: string;
  status: EFooterStatus;
};

const FooterStatus = React.memo<TProps>(
  ({status, warningLabel = '', successLabel = '', errorLabel}) => (
    <FieldFooter justifyContent="flex-end">
      {status === EFooterStatus.WARNING && (
        <>
          <S.StatusIcon name="warning" />
          <S.CheckResult status={status}>{warningLabel}</S.CheckResult>
        </>
      )}
      {status === EFooterStatus.SUCCESS && (
        <>
          <S.StatusIcon name="ok" />
          <S.CheckResult status={status}>{successLabel}</S.CheckResult>
        </>
      )}
      {status === EFooterStatus.ERROR && (
        <>
          <S.StatusIcon name="error" />
          <S.CheckResult status={status}>{errorLabel}</S.CheckResult>
        </>
      )}
    </FieldFooter>
  ),
);

FooterStatus.displayName = 'FooterStatus';

export default FooterStatus;
