import pickBy from 'lodash/pickBy';

import {EDetailsAction, EResidentCountry, TCompanyDetails, TPaymentDetails} from './definitions';

export const getResidentCountry = (country?: TPaymentDetails['country']): EResidentCountry => {
  if (!country) {
    return EResidentCountry.RUS;
  }

  if (Object.values(EResidentCountry).includes(country as EResidentCountry)) {
    return country as EResidentCountry;
  }

  return EResidentCountry.FOREIGN;
};

export const PAYMENT_DETAILS = {
  [EResidentCountry.RUS]: ({
    country,
    bankName,
    bic,
    operBankAccount,
    corrBankAccount,
  }: TPaymentDetails) => ({
    country,
    bankName,
    bic,
    operBankAccount,
    corrBankAccount,
  }),
  [EResidentCountry.BLR]: ({country, bankName, bankAddress, bic, iban}: TPaymentDetails) => ({
    country,
    bankName,
    bankAddress,
    bic,
    iban,
  }),
  [EResidentCountry.FOREIGN]: ({
    country,
    bankName,
    corrBankAccount,
    bankAddress,
    operBankAccount,
    iban,
    bic,
  }: TPaymentDetails) => ({
    country,
    bankName,
    corrBankAccount,
    bankAddress,
    operBankAccount,
    iban,
    bic,
  }),
};

export const COMPANY_DETAILS = {
  [EResidentCountry.RUS]: ({
    country,
    ogrn,
    taxNumber,
    kpp,
    companyName,
    siteAddress,
    postAddress,
    legalAddress,
  }: TCompanyDetails) => ({
    country,
    ogrn,
    taxNumber,
    kpp,
    companyName,
    siteAddress,
    postAddress,
    legalAddress,
  }),
  [EResidentCountry.BLR]: ({
    country,
    companyName,
    taxNumber,
    siteAddress,
    postAddress,
    legalAddress,
  }: TCompanyDetails) => ({
    country,
    companyName,
    taxNumber,
    siteAddress,
    postAddress,
    legalAddress,
  }),
  [EResidentCountry.FOREIGN]: ({
    country,
    ogrn,
    companyName,
    legalAddress,
    postAddress,
    taxNumber,
    authorizedPersonName,
    registrationEmail,
    vatNumber,
  }: TCompanyDetails) => ({
    country,
    ogrn,
    companyName,
    legalAddress,
    postAddress,
    taxNumber,
    authorizedPersonName,
    registrationEmail,
    vatNumber,
  }),
};

export const getActionName = (details?: TCompanyDetails | TPaymentDetails) => {
  if (!details) {
    return EDetailsAction.FILL;
  }

  const emptyFieldsCount = Object.keys(
    pickBy(details, (value) => value === undefined || value === null),
  ).length;

  if (emptyFieldsCount === 0) {
    return EDetailsAction.UPDATE;
  }

  if (Object.keys(details).length === emptyFieldsCount) {
    return EDetailsAction.FILL;
  }

  return EDetailsAction.ADD;
};
