import {isValid} from 'date-fns/isValid';
import {parse} from 'date-fns/parse';
import {parseISO as baseParseISO} from 'date-fns/parseISO';
import {startOfDay} from 'date-fns/startOfDay';

import formatDate from '@edna/utils/formatDate';

const combineDateAndTime = (date?: Date, time?: string | null): Date => {
  if (!date) {
    return new Date();
  }

  const roundDate = startOfDay(date);
  const [hh = 0, mm = 0] = (time ?? '').split(':').map(Number);

  return new Date(roundDate.getFullYear(), roundDate.getMonth(), roundDate.getDate(), hh, mm);
};

const convertTimezoneToGMTOffset = (timezoneRegion: string) => {
  const now = new Date();

  const offsetString = new Intl.DateTimeFormat('en-US', {
    timeZone: timezoneRegion,
    timeStyle: 'long',
  }).formatToParts(now);

  return offsetString[8].value.replace('GMT', 'UTC');
};

const isDateBetween = (
  start: Date | string,
  end: Date | string = new Date(),
  current: Date | string = new Date(),
) => {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const currentTime = new Date(current).getTime();

  return currentTime >= startTime && currentTime <= endTime;
};

const getFormatTimeBySeconds = (sec: number) =>
  [
    Math.floor(sec / 60)
      .toString()
      .padStart(2, '0'),
    Math.floor(sec % 60)
      .toString()
      .padStart(2, '0'),
  ].join(':');

const parseDate = (payload: unknown, format: string, referenceDate: Date = new Date()) => {
  if (!payload || typeof payload !== 'string') {
    return '';
  }

  return parse(payload, format, referenceDate);
};

const parseISO = (payload: unknown, format?: string) => {
  if (!payload) {
    return '';
  }

  try {
    const date =
      typeof payload === 'string' ? baseParseISO(payload) : new Date(payload as number | Date);

    if (!isValid(date)) {
      return '';
    }

    return format ? (formatDate(date, {format}) as string) : date;
  } catch (e) {
    return '';
  }
};

const trimISOTimezone = (iso: string) => iso.replace(/Z|[-+]\d\d:\d\d$/, '');

export {
  convertTimezoneToGMTOffset,
  isDateBetween,
  combineDateAndTime,
  getFormatTimeBySeconds,
  parseDate,
  parseISO,
  trimISOTimezone,
};
