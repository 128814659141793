import styled from 'styled-components';

import {Icon} from '@edna/components/primitives';

import {DragAndDropIcon} from 'src/icons';

export const DragIcon = styled(Icon).attrs({
  as: DragAndDropIcon,
  size: '24px',
})`
  &:hover {
    cursor: grab;
  }
`;
