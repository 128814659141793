import {EClientStatus} from 'src/containers/UserCompanyProfile/definitions';

export default {
  notifications: {
    successBlock: 'The request to block the tenant has been sent',
    successUnblock: 'The tenant has been successfully unblocked',
  },
  options: {
    moveToDeactivated: 'Deactivate',
    moveToActivated: 'Activate',
  },
  confirmActivatingModal: {
    title: 'Restore the active status for the client?',
    subtitle:
      'In the active status, the client will be able to renew access to the services. Restore the status?',
    notNeeded: 'No',
  },
  confirmDeactivatingModal: {
    title: 'Deactivate the client?',
    subtitle:
      'In the inactive status, the client will not be able to use edna Pulse. They will still have access to their edna dashboard, but to use the platform functionality, they will need to sign a new contract. Are you sure you want to deactivate them?',
    no: 'No',
  },
  deactivateClientModal: {
    title: 'Deactivate the client?',
    message:
      'You can deactivate a client for a period of up to 120 days. The maximum number of such periods per year is 3.',
    periodLabel: 'Specify the period during which the client is deactivated',
    move: 'Continue',
  },
  clientStatus: 'Client status:',
  clientStatuses: {
    [EClientStatus.ACTIVE]: 'Active',
    [EClientStatus.BLOCKED]: 'Blocked',
    [EClientStatus.DEACTIVATED]: 'Inactive',
  },
  changeStatus: 'Change status:',
  until: 'until {{date}}',
  seeAsClient: "See client's cabinet",
  clientPages: {
    messageMatchers: 'Templates',
    report: 'Report',
    scenarios: 'Scenarios',
  },
};
