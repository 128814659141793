import {TData, TPayload} from 'src/components/List';
import {EChannelType, TChannelType, TItemId, TSubject} from 'src/constants';
import {rootApi} from 'src/models';

export type TFilters = {
  filter: string;
  channelTypes: EChannelType[] | null;
};

type TParams = {
  tenantId?: TItemId;
  channelType?: TChannelType;
  locked?: boolean;
  testing?: boolean;
  hasStepTariffication?: boolean;
};

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubjects: builder.query<TData<TSubject>, TPayload<TFilters & {supportTenantId?: string}>>({
      query: ({supportTenantId: tenantId, ...data}) => ({
        method: 'POST',
        url: !!tenantId
          ? '/channel-profile/subjects/support/list'
          : '/channel-profile/subjects/list',
        meta: {isShowError: true},
        data,
        params: {tenantId},
      }),
    }),
    getSubject: builder.query<TSubject, TItemId>({
      query: (id) => ({
        method: 'GET',
        url: `/channel-profile/subjects/${id}`,
        meta: {isShowError: true},
      }),
    }),
    getAllSubjects: builder.query<TSubject[], TParams | void>({
      query: ({tenantId, ...params} = {}) => ({
        method: 'GET',
        url:
          tenantId !== undefined
            ? `/channel-profile/tenant/${tenantId}/subjects/`
            : '/channel-profile/subjects',
        meta: {isShowError: true},
        params,
      }),
    }),
    getAllSupportSubjects: builder.query<TSubject[], {supportTenantId: string} & Partial<TParams>>({
      query: ({supportTenantId: tenantId, ...params}) => ({
        method: 'GET',
        url: '/channel-profile/subjects/support',
        meta: {isShowError: true},
        params: {tenantId, ...params},
      }),
    }),
    getSubjectsByWabaId: builder.query<TSubject[], TItemId>({
      query: (id) => ({
        method: 'GET',
        url: `/channel-profile/subjects/with-same-waba-id/${id}`,
        meta: {isShowError: true},
      }),
    }),
  }),
});

export const {
  useGetSubjectsQuery,
  useGetAllSubjectsQuery,
  useGetAllSupportSubjectsQuery,
  useGetSubjectQuery,
  useLazyGetSubjectsByWabaIdQuery,
} = api;
