export enum EStatus {
  SENT_TO_PLATFORM = 'SENT_TO_PLATFORM',
  FINISH_REGISTER = 'FINISH_REGISTER',
  UNKNOWN = 'UNKNOWN',
}

export type TInstagramChannel = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: EStatus;
  facebookAccountId: number | string | null;
  instagramAccountId: number | string | null;
  profileName: string | null;
  profileUrl: string | null;
  userName: string | null;
  profilePictureUrl: string | null;
  userAccessToken: string | null;
  tariffTemplateId: number | null;
};

export type TFormData = {
  id?: TInstagramChannel['id'];
  name?: TInstagramChannel['name'];
  facebookAccountId?: TInstagramChannel['facebookAccountId'];
  instagramAccountId?: TInstagramChannel['instagramAccountId'];
  profileName?: TInstagramChannel['profileName'];
  profileUrl?: TInstagramChannel['profileUrl'];
  userName?: TInstagramChannel['userName'];
  profilePictureUrl?: TInstagramChannel['profilePictureUrl'];
  userAccessToken?: TInstagramChannel['userAccessToken'];
  tariffTemplateId?: TInstagramChannel['tariffTemplateId'];
};

export type TAccount = {
  id: string; // page_id
  name: string;
  connected_instagram_account?: TInstagramAccount | null;
};

export type TInstagramAccount = {
  id?: string;
  name?: string;
  username?: string;
  profile_picture_url?: string;
};

export type TPreparedInstagramAccount = {
  facebookAccountId: TAccount['id'];
  instagramAccountId?: TInstagramAccount['id'];
  profileName?: TInstagramAccount['username'];
  userName?: TInstagramAccount['name'];
  profilePictureUrl?: TInstagramAccount['profile_picture_url'];
  profileUrl?: string | null;
};
