import styled, {css} from 'styled-components';

import {Button as BaseButton} from '@edna/components';
import {mixins} from '@edna/components/styles';
import BaseCloseIcon from '@edna/icons/close.svg';

import {Badge as BaseBadge} from 'src/components';

export const Badge = styled(BaseBadge).attrs({color: 'GRAY'})`
  ${mixins.f2};
  ${mixins.stack({direction: 'ROW', align: 'center', size: '1'})};
  border-width: 1px;
  font-weight: 500;
  color: ${({theme}) => theme.branding.content.secondary};
`;

export const News = styled.div`
  ${mixins.f2};
  width: 100%;
  box-shadow: none;
  white-space: break-spaces;
  ${({theme}) => css`
    padding: ${theme.spacing.s6};
    border: 2px solid ${theme.branding.colors.accentA};
    border-radius: ${theme.borderRadius.br4};
  `};
`;

export const NewsDate = styled.span`
  ${mixins.f5};
  width: inherit;
  color: ${({theme}) => theme.palette.green500};
`;

export const CloseIcon = styled(BaseCloseIcon)`
  ${mixins.wh('24px')}
  flex-shrink: 0;
  cursor: pointer;
  color: ${({theme}) => theme.branding.content.tertiary};
`;

export const Button = styled(BaseButton)`
  margin-top: ${({theme}) => theme.spacing.s5};
  margin-right: auto;
`;
