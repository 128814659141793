import {getTheme} from '@edna/components/styles';

const customDimensions = {
  maxWidthLayout: 1192,
  asideNavWidth: 244,
  headerHeight: 64,
} as const;

const customPalette = {
  whatsApp: '#128C7E',
  whatsAppButton: '#007AFF',
  facebook: '#1877F2',
  facebookDark: '#4267B2',
} as const;

const customZIndex = {
  dropdown: 3,
  tooltip: 2,
  subHeader: 1,
  header: 6,
  drawer: 7,
  modal: 8,
  notifications: 9,
} as const;

export default getTheme({
  palette: customPalette,
  dimensions: customDimensions,
  zIndex: customZIndex,
});
