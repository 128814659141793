import styled, {css} from 'styled-components';

import {Option as BaseOption} from '@edna/components';
import {TInputProps, mixins} from '@edna/components/styles';
import KeyboardArrowDownIcon from '@edna/icons/keyboardArrowDown.svg';
import BaseSearchIcon from '@edna/icons/search.svg';

import {TPosition} from './types';

type TValueProps = {
  disabled?: boolean;
};
type THeadProps = {
  isOpen: boolean;
  disabled: boolean;
  isPlacementBottom: boolean;
};
type TIconProps = {
  open: boolean;
};
type TOverlayProps = {
  isPlacementBottom: boolean;
  position: TPosition;
};

const VALUE_SIZE = 24;

const getIconTransform = ({open, theme}: TTheme & TIconProps) => css`
  transform: rotate(${open ? '180deg' : 0});
  transition: transform ${theme.animation.duration}ms;
`;

const getHeadDisabledStyle = ({disabled}: THeadProps) => {
  if (disabled) {
    return mixins.disabled;
  }

  return css`
    cursor: pointer;
  `;
};

const getHeadOpenStyle = ({
  theme: {
    boxShadow,
    borderRadius: {br1},
  },
  isOpen,
  isPlacementBottom,
}: TTheme & THeadProps) => {
  if (isOpen) {
    return css`
      border-color: transparent;
      border-radius: ${isPlacementBottom ? `${br1} ${br1} 0 0` : `0 0 ${br1} ${br1}`};
      box-shadow: ${boxShadow.input};
    `;
  }

  return '';
};

const getHeadStyle = ({theme: {dimensions, spacing}}: TTheme & THeadProps) => {
  const top = (dimensions.controlHeightM - 2 * dimensions.borderWidth - VALUE_SIZE) / 2;

  return css`
    min-height: ${dimensions.controlHeightM}px;
    padding: ${top}px 0 0 ${spacing.s2};
  `;
};

const getValueStyle = ({theme: {borderRadius, branding}, disabled}: TTheme & TValueProps) => css`
  min-height: ${VALUE_SIZE}px;
  border-radius: ${borderRadius.br1};
  color: ${disabled ? branding.colors.disabled : 'inherit'};
`;

const getInputStyle = ({theme: {dimensions, spacing, branding}}: TTheme) => css`
  ${mixins.wh(`calc(100% - ${spacing.s4})`, `calc(${dimensions.controlHeightM}px - ${spacing.s2})`)}
  margin: ${spacing.s2};
  padding: 0 ${spacing.s7} 0 ${spacing.s2};
  :focus {
    border-color: ${branding.content.primary};
    box-shadow: none;
  }
`;

const getOverlayStyle = ({
  theme: {
    borderRadius: {br1},
    zIndex,
    boxShadow,
  },
  position,
  isPlacementBottom,
}: TTheme & TOverlayProps) => css`
  ${mixins.position({position: 'absolute', left: 0, ...position})}
  z-index: ${zIndex.dropdown};
  width: 100%;
  overflow: auto;
  box-shadow: ${boxShadow.dropdown};
  border-radius: ${isPlacementBottom ? `0 0 ${br1} ${br1}` : `${br1} ${br1} 0 0`};
`;

export const ArrowIcon = styled(KeyboardArrowDownIcon)<TIconProps>`
  ${mixins.wh('20px')}
  ${getIconTransform}
`;

export const TreeArrowIcon = styled(KeyboardArrowDownIcon)`
  ${mixins.wh('16px')}
  margin-left: ${({theme}) => theme.spacing.s4};
  transform: rotate(-90deg);
`;

export const TreeArrowBackIcon = styled(KeyboardArrowDownIcon)`
  ${mixins.wh('16px')}
  margin-right: ${({theme}) => theme.spacing.s4};
  transform: rotate(90deg);
`;

export const ArrowIconWrapper = styled.div`
  ${mixins.position({
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: '12px',
  })}
`;

export const Select = styled.div`
  position: relative;
  min-height: ${({theme}) => theme.dimensions.controlHeightM}px;
  padding: 0;
  outline: 0;
  user-select: none;
`;

export const Head = styled.div<TInputProps & THeadProps>`
  ${mixins.input}
  position: relative;
  height: auto;
  ${getHeadStyle}
  ${getHeadOpenStyle}
  ${getHeadDisabledStyle}
`;

export const Placeholder = styled.div`
  line-height: ${VALUE_SIZE}px;
  padding: 0 ${({theme}) => theme.spacing.s2};
`;

export const ValueLabel = styled.div<TValueProps>`
  ${({theme: {spacing}}) => css`
    margin-right: ${spacing.s6};
    padding-left: ${spacing.s2};
  `}
  ${mixins.truncate}
`;

export const Value = styled.div<TValueProps>`
  ${getValueStyle};
  display: flex;
  align-items: center;
  max-width: max-content;
`;

export const Overlay = styled.div<TOverlayProps>`
  ${mixins.paper}
  ${getOverlayStyle}
  outline: 0;
`;

export const Option = styled(BaseOption)`
  ${mixins.flexAlign('space-between', 'center')}
  white-space: pre-line;
  min-height: ${({theme: {dimensions}}) => `${dimensions.controlHeightM}px`};
`;

export const TopOption = styled(BaseOption)`
  ${mixins.flexAlign('flex-start', 'center')}
  white-space: pre-line;
  min-height: ${({theme: {dimensions}}) => `${dimensions.controlHeightM}px`};
`;

export const SearchOptionsWrapper = styled.div`
  width: 100%;
  overflow: auto;
  ${({theme: {dimensions, borderRadius}}) => css`
    max-height: ${6 * dimensions.controlHeightM}px;
    border-radius: 0 0 ${borderRadius.br1} ${borderRadius.br1};
  `}
`;

export const SearchIcon = styled(BaseSearchIcon)`
  ${mixins.wh('16px')}
  ${mixins.position({
    position: 'absolute',
    top: 'calc(50% - 8px)',
    right: '16px',
  })}
`;

export const Input = styled.input`
  ${mixins.input}
  ${getInputStyle}
`;

export const SearchInput = styled.div`
  position: relative;
`;
