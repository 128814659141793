import omit from 'lodash/omit';

import getPeriod from '@edna/utils/getPeriod';

import {TData, TPayload} from 'src/components/List/useList';
import {TWebNotification} from 'src/containers/Notifications';
import {CACHE_LIFETIME, ECacheTag, rootApi} from 'src/models';
import {booleanHashToArray} from 'src/utils';

import {TFilters} from './definitions';

const prepareFilters = (payload: TAnyObject) => {
  const {
    levels,
    categories,
    period: {period, startDate, stopDate},
  } = payload;

  const nextFilters: TAnyObject = {
    ...omit(payload, 'period'),
    levels: booleanHashToArray(levels),
    categories: booleanHashToArray(categories),
  };

  if (period === 'anytime') {
    return {...nextFilters, timeFrom: null, timeTo: null};
  }

  if (period === 'range') {
    return {...nextFilters, timeFrom: startDate, timeTo: stopDate};
  }

  const newPeriod = getPeriod(period);

  return {...nextFilters, timeFrom: newPeriod.startDate, timeTo: newPeriod.stopDate};
};

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<TData<TWebNotification>, TPayload<TFilters>>({
      query: (data) => ({
        method: 'POST',
        url: `/web-notification/list`,
        data: prepareFilters(data),
      }),
      keepUnusedDataFor: CACHE_LIFETIME,
      providesTags: [ECacheTag.NOTIFICATIONS],
    }),
  }),
});

export const {useGetNotificationsQuery, endpoints: notificationsEndpoints} = api;
