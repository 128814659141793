import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {TFilters} from './definitions';

const slice = createSlice({
  name: 'report',
  initialState: {
    filters: {},
  },
  reducers: {
    setFilters(state, action: PayloadAction<TFilters>) {
      state.filters = action.payload;
    },
  },
});

export const {setFilters: setFiltersAction} = slice.actions;
export const selectFilters = (state: TRootState) => state.report.filters as TFilters;

export default slice.reducer;
