import {TValue as TPickerValue} from '@edna/components/PeriodPicker';
import {TCurrency} from '@edna/utils/formatMoney';

import {EChannelType, TItemId} from 'src/constants';
import {TAutoDebit} from 'src/containers/AccountStatement/definitions';
import {TDocumentType} from 'src/containers/Billing/definitions';

export enum EPaymentTab {
  REPLENISHMENT = 'replenishment',
  ACCOUNT_STATEMENT = 'accountStatement',
  DOCUMENTATION = 'documentation',
}

export const PAYMENT_TABS = Object.values(EPaymentTab);

export enum EDebtPaymentType {
  WHATSAPP_LICENSE_INIT_PAYMENT = 'WHATSAPP_LICENSE_INIT_PAYMENT',
  WHATSAPP_LICENSE_RENEWAL_PAYMENT = 'WHATSAPP_LICENSE_RENEWAL_PAYMENT',
  WHATSAPP_AMAU_PAYMENT = 'WHATSAPP_AMAU_PAYMENT',
  VIBER_LICENSE_INIT_PAYMENT = 'VIBER_LICENSE_INIT_PAYMENT',
  VIBER_LICENSE_RENEWAL_PAYMENT = 'VIBER_LICENSE_RENEWAL_PAYMENT',
  SMS_LICENSE_INIT_PAYMENT = 'SMS_LICENSE_INIT_PAYMENT',
  SMS_LICENSE_RENEWAL_PAYMENT = 'SMS_LICENSE_RENEWAL_PAYMENT',
  SMS_RECURRENT_BILLED = 'SMS_RECURRENT_BILLED',
  VIBER_RECURRENT_PAYMENT = 'VIBER_RECURRENT_PAYMENT',
}

export const channelByDebtPaymentHash = {
  [EDebtPaymentType.WHATSAPP_LICENSE_INIT_PAYMENT]: EChannelType.WHATSAPP,
  [EDebtPaymentType.WHATSAPP_LICENSE_RENEWAL_PAYMENT]: EChannelType.WHATSAPP,
  [EDebtPaymentType.WHATSAPP_AMAU_PAYMENT]: EChannelType.WHATSAPP,
  [EDebtPaymentType.VIBER_LICENSE_INIT_PAYMENT]: EChannelType.VIBER,
  [EDebtPaymentType.VIBER_LICENSE_RENEWAL_PAYMENT]: EChannelType.VIBER,
  [EDebtPaymentType.VIBER_RECURRENT_PAYMENT]: EChannelType.VIBER,
  [EDebtPaymentType.SMS_LICENSE_INIT_PAYMENT]: EChannelType.SMS,
  [EDebtPaymentType.SMS_LICENSE_RENEWAL_PAYMENT]: EChannelType.SMS,
  [EDebtPaymentType.SMS_RECURRENT_BILLED]: EChannelType.SMS,
};

export type TDebtPaymentType = keyof typeof EDebtPaymentType;

export type TPaymentDebtItems = {
  totalAmount: number;
  paymentDebtsGroups: TPaymentDebtGroup[];
};

export type TPaymentDebt = {
  createdAt: string;
  cancelAt: string;
  paymentType: TDebtPaymentType;
  tenantId: number;
  amount: number;
  currency: TCurrency;
  details: TAutoDebit;
  expirationTime: string;
  expiredAt: string;
};

export type TPaymentDebtGroup = {
  createdAt: string;
  cancelAt: string;
  paymentType: TDebtPaymentType;
  totalAmount: number;
  paymentDebts: TPaymentDebt[];
};

export type TNextPaymentItems = {
  totalAmount: number;
  nextPaymentsGroups: TNextPaymentGroup[];
};

export type TNextPaymentGroup = {
  paymentDate: string;
  expireAt: string;
  paymentType: TDebtPaymentType;
  totalAmount: number;
  nextPayments: TNextPayment[];
};

export type TNextPayment = {
  paymentDate: string;
  expireAt: string;
  paymentType: TDebtPaymentType;
  tenantId: number;
  amount: number;
  currency: TCurrency;
  details: TAutoDebit;
};

export const defaultPaymentDebt = {
  totalAmount: 0,
  paymentDebtsGroups: [],
};

export const defaultNextPayment = {
  totalAmount: 0,
  nextPaymentsGroups: [],
};

export type TDocumentFilters = {
  period: TPickerValue;
  filter?: string;
  tenantId?: TItemId | null;
  isSupport: boolean;
  types: TDocumentType | TDocumentType[];
};
