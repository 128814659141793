import {TAudienceGroupState} from 'src/constants';
import {TItem as TTagsItem} from 'src/containers/Tags/definitions';

export const DEFAULT_TAGS_LIMIT = 50;
export const SUBSCRIBERS_TAGS_LIMIT = 20;
export const PROHIBIT_REPETITIONS_CATEGORIES_CODES = ['GENDER', 'AGE'];

export const ALL_RECEIVERS_TAG_ID = -1;
export const BROADCAST_LIST_CATEGORY_CODE = 'BROADCAST_LISTS';
export const SYSTEM_CATEGORY_CODE = 'SYSTEM_TAGS';
export const HIDDEN_CATEGORY_CODE = 'HIDDEN';
export const ALL_RECEIVERS_TAG = {
  id: ALL_RECEIVERS_TAG_ID,
  name: '',
  code: null,
  subscriberId: null,
  // categoryID добавляется в getTagsGroups
  categoryId: null,
};
export const BLACKLIST_CODE = 'BLACKLIST';
export const AUDIENCE_LISTS_CATEGORY_ID = 5787;

export type TCategoryMap = Record<TTagCategory['id'], TTagCategory>;

export type TTagCategory = {
  id: number;
  parentId: number;
  name: string;
  childIds: TTagCategory['id'][];
  code?: string;
};

export type TBackendItem = {
  tagId: number;
  name: string;
  code: string | null;
  subscriberId: number | string | null;
  categoryId: number;
};

export type TItem = {
  id: number;
  name: string;
  code: string | null;
  subscriberId: number | string | null;
  categoryId: number | null;
};

export type TState = {
  itemsMap: Record<TItem['id'], TItem>;
  newTagsIds: TItem['id'][];
  limit: number;
  blacklistTag: TItem;
};

export type TTagsGroup = {id: TItem['categoryId']; path: TItem['id'][]; tags: TItem[]};
export type TTagsGroups = Record<number, TTagsGroup>;

export type TOperator = 'MINUS' | 'AND' | 'OR' | 'ALL';

export type TTagsOperand = {
  operator: 'EQ';
  delay: string | null;
  tagId: number;
};

export type TOperand = {
  operator: TOperator;
  operands?: (TOperand | TTagsOperand)[];
};

export type TStatus = 'CREATED' | 'CALCULATION_IN_PROGRESS' | 'COMPLETED' | 'ERROR';

export type TTagQuery = {
  id: number;
  query: TOperand | TTagsOperand;
  status: TStatus;
  subscribersCount: number;
  error: string;
};

export type TAudience = Record<TAudienceGroupState, TAudienceParam[]>;

export type TAudienceOperator = 'OR' | 'AND';

export type TAudienceParam = {
  id: string | number;
  type: TAudienceOperator;
  items: TTagsItem['id'][];
  delay: string | null;
};

export type TFormData = {
  audience: TAudience;
};

export type TTagsFilters = {
  filter?: string;
};

export type TTagsByCategoryFilters = {
  filter?: string;
  categoryIds?: number[];
  categoryId?: number;
};
