export default {
  invoiceId: 'ID платежа',
  tenantId: 'Зачислить платеж для тенанты',
  assign: 'Зачислить',
  assignSuccess: 'Зачисление успешно',
  tenantNotFound: 'Такой тенанты не найдено',
  invoiceNotFound: 'Платеж не найден',
  onlyUnknownHint: 'Здесь может быть выбран только нераспределенный платеж!',
  onlyKnownHint: 'Здесь может быть выбран только распределенный платеж!',
  cancelInvoice: 'Вернуть платеж',
  cancelError: 'Платеж не отменен. Произошла ошибка',
  cancelSuccess: 'Платеж отменен успешно',
};
