import React from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath} from 'react-router';
import {useNavigate} from 'react-router-dom';

import {Modal, ModalActions, ModalHeader, ModalSubmitButton, showModal} from '@edna/components';

import {InaccessibleImage} from 'src/images';
import PATHS from 'src/paths';
import {parseISO} from 'src/utils';

import {ETemporaryBlockedTypes, EUserCompanyProfileTabs} from '../definitions';
import * as S from './style';

type TProps = {
  url?: string;
  date?: string;
  closeModal: TEmptyFunction;
};

const getTemporaryBlockedTypeByUrl = (url: string) => {
  // важен порядок, тк broadcast - есть и в первых двух случаях
  // здесь проверяется либо url из error config, либо pathname из useLocation
  if (url.includes('cascade-template') || url.includes('cascades')) {
    return ETemporaryBlockedTypes.CASCADES;
  }

  if (url.includes('message-matcher')) {
    return ETemporaryBlockedTypes.TEMPLATES;
  }

  if (url.includes('broadcast')) {
    return ETemporaryBlockedTypes.BROADCASTS;
  }

  if (url.includes('channel-profile') || url.includes('channels')) {
    return ETemporaryBlockedTypes.CHANNELS;
  }

  return ETemporaryBlockedTypes.OTHER;
};

const TemporaryBlockModal = React.memo<TProps>(({url, date, closeModal}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const temporaryBlockedTypes = getTemporaryBlockedTypeByUrl(url ?? '');

  const toProfile = React.useCallback(() => {
    navigate(
      generatePath(PATHS.COMPANY_PROFILE_TAB, {
        tab: EUserCompanyProfileTabs.LICENSE,
      }),
      {replace: true},
    );
    closeModal();
  }, [closeModal, navigate]);

  return (
    <Modal width="430px">
      <ModalHeader>
        {t(`UserCompanyProfile:temporaryBlockModal.${temporaryBlockedTypes}.title`)}
      </ModalHeader>
      <S.ModalSubHeader>
        {t(`UserCompanyProfile:temporaryBlockModal.${temporaryBlockedTypes}.subtitle`, {
          date: parseISO(date, 'dd.MM.yyyy'),
        })}
      </S.ModalSubHeader>
      <S.ModalCenterImage as={InaccessibleImage} />
      <ModalActions>
        <ModalSubmitButton onClick={toProfile}>
          {t('UserCompanyProfile:temporaryBlockModal.toProfile')}
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
});

TemporaryBlockModal.displayName = 'TemporaryBlockModal';

export default TemporaryBlockModal;

// TODO: стоит пересмотреть логику вывода, так как в url может попадать или pathname, или error.config.url
export const showTemporaryBlockModal = (url?: string, date?: string) =>
  showModal((closeModal) => <TemporaryBlockModal url={url} date={date} closeModal={closeModal} />);
