import {EContentType} from 'src/constants';
import {DocumentIcon, ImageIcon, TextIcon, VideoIcon} from 'src/icons';

import {TMessageMatcher, TTHeaderType} from '../definitions';

export const CONTENT_TYPE_ICON: Record<TTHeaderType, TSvgComponentType> = {
  [EContentType.TEXT]: TextIcon,
  [EContentType.VIDEO]: VideoIcon,
  [EContentType.IMAGE]: ImageIcon,
  [EContentType.DOCUMENT]: DocumentIcon,
};
export enum EWhatsAppCategory {
  UTILITY = 'UTILITY',
  AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
}

// для корректного показа старых категорий шаблонов
export enum EOldWhatsAppCategory {
  TRANSACTIONAL = 'TRANSACTIONAL',
  OTP = 'OTP',
}

export enum ETemplateButtonOtpType {
  AUTO = 'ONE_TAP',
  COPY = 'COPY_CODE',
}

export enum EOperatorFormStep {
  DETAILS = 'DETAILS',
  TEXT_CONTENT = 'TEXT_CONTENT',
  HEADER_AND_SIGNATURE = 'HEADER_AND_SIGNATURE',
  BUTTONS = 'BUTTONS',
}

export enum EAuthFormStep {
  DETAILS = 'DETAILS',
  AUTH_CONTENT = 'AUTH_CONTENT',
}

export enum EUserFormStep {
  DETAILS = 'DETAILS',
  USER_CONTENT = 'USER_CONTENT',
}

export type TFormStep = EOperatorFormStep | EUserFormStep | EAuthFormStep;

export type TSteps = Record<string, {disabled: boolean; completed: boolean}>;

export type TExamplesFormData = {
  textExampleParams?: string[];
  header?: {
    headerExampleMediaUrl?: string;
  };
  mediaUrl?: string;
  mediaFile?: TFile | null;
  isUploadExampleFile?: boolean;
  buttons?: {
    urlTextExample?: string;
    empty?: boolean;
  }[];
};

export type TExamplesPayload = Omit<TExamplesFormData, 'isUploadExampleFile'> & {
  id: TMessageMatcher['id'];
};

export const WHATSAPP_CATEGORIES = Object.values(EWhatsAppCategory);
export const AUTH_BUTTON_NAME_MAX_LENGTH = 25;
export const BUTTON_NAME_MAX_LENGTH = 25;

export const defaultOperatorSteps = {
  [EOperatorFormStep.DETAILS]: {disabled: false, completed: false},
  [EOperatorFormStep.TEXT_CONTENT]: {disabled: true, completed: false},
  [EOperatorFormStep.HEADER_AND_SIGNATURE]: {disabled: true, completed: false},
  [EOperatorFormStep.BUTTONS]: {disabled: true, completed: false},
};

export const defaultUserSteps = {
  [EUserFormStep.DETAILS]: {disabled: false, completed: false},
  [EUserFormStep.USER_CONTENT]: {disabled: true, completed: false},
};

export const defaultAuthSteps = {
  [EAuthFormStep.DETAILS]: {disabled: false, completed: false},
  [EAuthFormStep.AUTH_CONTENT]: {disabled: true, completed: false},
};
