import {EClientStatus} from './definitions';

export default {
  title: 'Leads',
  addClient: 'client',
  searchPlaceholder: 'Quick search by client',
  createLink: 'Create link',
  personalTariff: 'Personal pricing',
  status: {
    [EClientStatus.WAITING]: 'Pending registration',
    [EClientStatus.ACCEPTED]: 'Confirmed',
    [EClientStatus.ERROR]: 'Error',
    [EClientStatus.CANCELLED]: 'Declined',
  },
  th: {
    createdAt: 'Created at',
    company: 'Company',
    status: 'Current license status',
    inviteUrl: 'Company',
  },
  filters: {
    title: 'Filters',
    statusLabel: 'Registration status',
    createdAtLabel: 'Client registration date',
    managerLabel: 'Assigned manager',
    managerPlaceholder: 'Select a manager',
    resellerLabel: 'Jurisdiction',
    resellerPlaceholder: 'Select a jurisdiction',
    leadClients: 'Clients',
  },
  notification: {
    updateResponsibleManager: 'The assigned manager has been successfully changed',
  },
  createInviteLinkModal: {
    title: 'Generate a client registration link?',
    text: "Registration login is the email address of the client's contact person",
  },
  copyInviteLinkModal: {
    inviteLink: 'Client registration link',
    copyLink: 'Copy this link and send it to the client so they could log into edna Pulse',
    linkIsValid: 'Please remember, the link is valid for 24 hours',
    link: 'Registration link',
    wellDone: 'Well done',
  },
  leadClientCard: {
    licenseStatus: 'Current license status',
    expiredAt: 'The license is valid until',
    responsibleManager: 'Responsible manager edna',
    partner: 'Agent',
    taxNumber: 'Taxpayer Identification Number (TIN)',
    binNumber: 'Business Identification Number (BIN)',
    ogrn: 'OGRN/OGRNIP code',
    country: 'Country',
    currency: 'Currency',
    reseller: 'Jurisdiction',
    document: 'Document',
    documentName: '{{documentNumber}} from {{documentDate}}',
    contact: 'Contact details',
    contactName: 'Contact details',
    phone: 'Phone number',
    email: 'e-mail',
    companyName: 'Company name',
    companyData: 'Company details',
    companyLicenses: 'Company licenses',
    settlementRules: 'Rules of mutual settlements',
    paymentType: 'Payment type',
    managerResponsibleForCheckingId: 'Person responsible for audit',
    depositAmount: 'Security payment amount',
    overdraftAmount: 'Overdraft amount',
    activateLicenseDebt: 'Activate license on credit',
    paymentPeriod: 'License payment period',
    cancellationPeriod: 'Number of days until the license is cancelled in case of non-payment',
    empty: 'Not specified',
    allowed: 'allowed',
    notAllowed: 'not allowed',
    tariffs: 'Pricing',
    responsibleManagerModal: {
      title: 'Change the assigned manager',
      selectManager: 'Select the assigned manager',
    },
    validDate: 'Valid from {{activatedAt}} until {{validUntil}}',
  },
};
