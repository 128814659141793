import {EAutopaymentError} from '../Autopayment';
import {EApproveStatus} from '../MessageMatchers/definitions';
import {
  EAutopaymentNotificationStatus,
  ESystemNewsType,
  ESystemNotificationType,
  EWebNotificationType,
} from './model';

export default {
  button: {
    downloadErrorFile: 'Скачать файл с ошибкой',
    toAudienceSection: 'В раздел аудитории',
    contactTechnicalSupport: 'Свяжитесь с техподдержкой',
    toReportBroadcast: 'В отчет рассылки',
  },
  news: {
    [ESystemNewsType.UpdatePlanned]: {
      title: 'Обновление {{date}}',
      content:
        '<0></0> будет обновление edna Pulse. Мы рекомендуем не планировать и не отправлять рассылки с {{startTime}} до {{endTime}}, так как в работе программы возможны кратковременные перебои.\n' +
        'Мы стараемся быть лучше для вас! Надеемся, вам понравится обновление.',
    },
  },
  system: {
    [ESystemNotificationType.UpdateRunning]:
      '{{date}} с {{startTime}} до {{endTime}} <0>мы обновляем edna Pulse</0>. В это время возможны кратковременные перебои в работе системы.',
    [ESystemNotificationType.MaintenanceRunning]:
      'В работе edna Pulse наблюдаются кратковременные перебои. Мы уже работаем над их устранением!',
  },
  web: {
    [EWebNotificationType.SubscribersUploadFailed]: `Список аудитории не загружен. <0>В уведомления</0>`,
    [EWebNotificationType.SubscribersUploadPartial]: `Список аудитории загружен частично. <0>В уведомления</0>`,
    [EWebNotificationType.SubscribersUploadSuccess]: `Список аудитории загружен. <0>В уведомления</0>`,
    [EWebNotificationType.BroadcastCompleted]: `Рассылка "{{name}}" завершена. <0>В уведомления</0>`,
    [EWebNotificationType.BroadcastCancelled]: `Рассылка "{{name}}" остановлена. <0>В уведомления</0>`,
    [EWebNotificationType.LicenseExpiring]:
      'Ваша лицензия в Системе edna Pulse истекает {{date}}. Чтобы продлить действие лицензии, обратитесь, пожалуйста, к вашему персональному менеджеру: {{name}} (<0>{{email}}</0>)',
    [EWebNotificationType.CallbackUpdated]: `Callback URL успешно изменен. <0>В уведомления</0>`,
    [EWebNotificationType.ApikeyUserUpdated]: `API-ключ успешно изменен. <0>В уведомления</0>`,
    [EWebNotificationType.CompanyDetailsUpdated]: `Пользователь "{{login}}" поменял реквизиты компании. <0>В уведомления</0>`,
    [EWebNotificationType.PaymentDetailsUpdated]: `Пользователь "{{login}}" поменял платежные реквизиты компании. <0>В уведомления</0>`,
    [EWebNotificationType.TenantTariffAccepted]: `Пользователь "{{login}}" согласился с доступными тарифами. <0>В уведомления</0>`,
    [EWebNotificationType.AgreedChangeTariffRequest]: `Пользователь "{{login}}" принял изменения по тарифу <b>{{tariffName}}</b>. <0>В уведомления</0>`,
    [EWebNotificationType.NotAgreedChangeTariffRequest]: `Пользователь "{{login}}" отклонил изменения по тарифу <b>{{tariffName}}</b>. <0>В уведомления</0>`,
    [EWebNotificationType.JivoEnabled]:
      'Пользователь "{{login}}" подключил весь трафик канала WhatsApp к чат-платформе Jivo. <0>В уведомления</0>',
    [EWebNotificationType.JivoDisabled]:
      'Пользователь "{{login}}" отключил весь трафик канала WhatsApp от чат-платформы Jivo. <0>В уведомления</0>',
    [EWebNotificationType.MessageMatcherStatusChanged]: {
      [EApproveStatus.DISABLED]:
        'Шаблон "{{name}}" был отключен. Все запланированные рассылки, в которых используется данный шаблон, будут остановлены. <0>В уведомления</0>',
      [EApproveStatus.PAUSED]:
        'Шаблон "{{name}}" был остановлен. Все запланированные рассылки, в которых используется данный шаблон, будут остановлены. <0>В уведомления</0>',
      [EApproveStatus.APPROVED]:
        'У шаблона "{{name}}" изменился статус. Новый статус: Активный. <0>В уведомления</0>',
      [EApproveStatus.REJECTED]:
        'У шаблона "{{name}}" изменился статус. Новый статус: Отклонен. <0>В уведомления</0>',
      [EApproveStatus.PENDING]:
        'У шаблона "{{name}}" изменился статус. Новый статус: Регистрация. <0>В уведомления</0>',
      [EApproveStatus.NOT_SENT]:
        'У шаблона "{{name}}" изменился статус. Новый статус: Черновик. <0>В уведомления</0>',
      [EApproveStatus.ARCHIVED]:
        'У шаблона "{{name}}" изменился статус. Новый статус: В архиве. <0>В уведомления</0>',
    },
    [EWebNotificationType.MessageMatcherCategoryChanged]: `Категория шаблона WhatsApp "{{name}}" была изменена на стороне WhatsApp. Новая категория шаблона: "{{newCategory}}". <0>В уведомления</0>`,
    [EWebNotificationType.InvoicePaid]: 'Успех! Баланс пополнен. <0>В уведомления</0>',
    [EWebNotificationType.ChannelProfileQualityScoreChanged]:
      'WhatsApp Business API {{name}}: изменилось качество. Новое качество: {{qualityScore}}. <0>В уведомления</0>',
    [EWebNotificationType.ChannelProfileMessagingLimitChanged]:
      'WhatsApp Business API {{name}}: изменился лимит. Новый лимит: {{messagingLimit}}. <0>В уведомления</0>',
    [EWebNotificationType.ChannelProfileQualityStatusChanged]:
      'У WhatsApp-аккаунта {{name}} изменился статус. Новый статус: {{qualityStatus}}.',
    [EWebNotificationType.StripeCheckout]: {
      [EAutopaymentError.GENERIC_DECLINE]:
        'Не удалось выполнить автоплатеж {{date}}. Для получения дополнительной информации обратитесь в службу технической поддержки <1>{{email}}</1>. <0>В уведомления</0>',
      [EAutopaymentError.AUTHENTICATION_REQUIRED]:
        'Не удалось выполнить автоплатеж {{date}}. Ваш банк запросил подтверждение платежа. <0>В уведомления</0>',
      [EAutopaymentError.CARD_DECLINE]:
        'Не удалось выполнить автоплатеж с помощью привязанной карты. <0>В уведомления</0>',
      [EAutopaymentError.INSUFFICIENT_FUNDS]:
        'Не удалось выполнить списание {{date}}. Недостаточно средств на карте. <0>В уведомления</0>',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_ENABLED]:
        'Автоплатеж подключен. <0>В уведомления</0>',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_DISABLED]:
        'Автоплатеж отключен. <0>В уведомления</0>',
      [EAutopaymentNotificationStatus.PAYMENT_SUCCEEDED]:
        'Автоплатеж подключен. Для получения чека об оплате перейдите по ссылке на <1>сайт платежной системы Stripe</1>. <0>В уведомления</0>',
    },
  },
};
