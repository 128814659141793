import {createSlice} from '@reduxjs/toolkit';

import {listenerMiddleware} from 'src/models';

import {notificationsEndpoints} from './api';
import {NOTIFICATIONS_POLLING_INTERVAL} from './model';

type TState = {
  isNewNotificationsReceived: boolean;
};

const initialState: TState = {
  isNewNotificationsReceived: false,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNewNotificationsReceived(state) {
      state.isNewNotificationsReceived = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationsEndpoints.pollNotifications.matchFulfilled,
      (state, {payload}) => {
        state.isNewNotificationsReceived = !!payload.webNotifications.length;
      },
    );
  },
});

export const selectIsNewNotificationsReceived = (state: TRootState) =>
  state.notifications.isNewNotificationsReceived;
export const {resetNewNotificationsReceived} = slice.actions;

listenerMiddleware.startListening({
  predicate: (_, currentState) => !!currentState.notifications.isNewNotificationsReceived,
  effect: (data, listenerApi) => {
    window.setTimeout(
      () => listenerApi.dispatch(resetNewNotificationsReceived()),
      NOTIFICATIONS_POLLING_INTERVAL / 2,
    );
  },
});

export default slice.reducer;
