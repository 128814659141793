import React from 'react';

import * as S from './style';
import {useSystemNotifications} from './useNotifications';
import {useNotificationsSize} from './useNotificationsSize';

const DEFAULT_VISIBLE_COUNT = 5;

type TProps = {
  contentRef: React.RefObject<HTMLDivElement>;
  isPublicLayout?: boolean;
};

const Notifications = React.memo<TProps>(({contentRef, isPublicLayout}) => {
  const {width, left} = useNotificationsSize(contentRef);
  const systemNotifications = useSystemNotifications({skip: isPublicLayout});

  return (
    <S.Notifications
      visibleCount={DEFAULT_VISIBLE_COUNT}
      isCheckDuplicates
      width={width}
      left={left}
      hasSystemNotifications={!!systemNotifications.length}
    />
  );
});

export {Notifications};
