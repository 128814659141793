import {EDocumentType, EPaymentType} from 'src/containers/User';

import {EDetailsAction} from './definitions';

export default {
  empty: 'Not specified',
  kpp: 'Tax Registration Reason Code (KPP)',
  legalAddress: 'Legal address',
  postAddress: 'Postal address for the document exchange',
  modalTitle: {
    companyDetails: 'company details',
    paymentDetails: 'payment details',
  },
  reminderModal: {
    title: "There's something you forgot to fill in",
    text: 'You cannot register the channel until you fill in the details of your company and payment organizations',
    button: 'Fill in details',
  },
  contractInfo: 'Contract data and settlement rules',
  companyRequisites: 'Company details',
  companyDetails: 'Company details',
  contactDetails: 'Client`s contact person',
  settlementRules: 'Settlement rules',
  licenseConditions: 'License activation conditions',
  paymentDetails: 'Payment details of the company',
  country: 'Country',
  taxNumber: 'Taxpayer Identification Number (TIN)',
  blrTaxNumber: 'Payer’s Account Number (UNP)',
  binNumber: 'Business Identification Number (BIN)',
  ogrn: 'OGRN/OGRNIP code',
  currencyCode: 'Currency',
  // TODO: когда на бэке добавят поле, проверить ключи на схожесть
  accountType: 'Account type',
  documentType: 'Document type',
  documentTypes: {
    [EDocumentType.AGREEMENT]: 'Agreement',
    [EDocumentType.CONTRACT]: 'Contract',
    [EDocumentType.TECH_PARTNER]: 'Technical partner',
  },
  securityDeposit: 'Security payment amount',
  paymentType: 'Payment type',
  paymentTypes: {
    [EPaymentType.MIXED_PREPAYMENT]: 'Mixed payment',
    [EPaymentType.POSTPAID]: 'Postpayment',
    [EPaymentType.PREPAYMENT]: 'Prepayment',
  },
  contactName: 'Full name',
  phone: 'Phone',
  email: 'Email',
  resellerId: 'Jurisdiction',
  maxOverdraft: 'Overdraft amount',
  managerResponsibleForCheckingId: 'Responsible for the company audit',
  // TODO: когда на бэке добавят поле, проверить ключи на схожесть
  validity: 'Validity period',
  activateLicenseDebt: 'Activate license "on credit"',
  activateLicenseDebtOptions: {
    true: 'Permitted',
    false: 'Forbidden',
  },
  licensePaymentTerm: 'License payment period',
  termBeforeTerminationLicense:
    'Number of days until the license is cancelled in case of non-payment',
  // TODO: когда на бэке добавят поле, проверить ключи на схожесть
  overdraftAgreed: 'Overdraft has been approved by',
  companyName: 'Company name',
  editContactInfo: 'Change client`s contact details?',
  editContactName: 'Full name of company`s contact person',
  siteAddress: 'Website',
  bankName: 'Bank',
  bic: 'BIC',
  rcbic: 'Bank Identifier Code',
  blrBic: 'Bank Identifier Code / SWIFT',
  operBankAccount: 'Transaction account (USD/EUR)',
  corrBankAccount: 'Correspondent account',
  authorizedPersonName: 'Authorized user',
  registrationEmail: 'Registration email',
  vatNumber: 'VAT number',
  bankAddress: 'Bank address',
  blrBankAddress: "Bank's legal address",
  iban: 'IBAN (USD/EUR)',
  blrIban: 'Bank account / IBAN',
  detailsAction: {
    [EDetailsAction.ADD]: 'Add {{detailsName}}',
    [EDetailsAction.FILL]: 'Fill {{detailsName}}',
    [EDetailsAction.UPDATE]: 'Update {{detailsName}}',
  },
  notification:
    'To register a channel, you need to fill in the company details and payment organization details in full. You will find your pricing as agreed with the manager on the Pricing tab',
};
