/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {DebounceSettings} from 'lodash';
import debounce from 'lodash/debounce';

const useDebouncedPromise = <T extends (...args: any[]) => Promise<any>>(
  promise: T,
  delay: number,
  options?: DebounceSettings,
): T => {
  const promiseRef = React.useRef<Promise<any>>();
  const resolveRef = React.useRef<TAnyFunction>(() => {});
  const rejectRef = React.useRef<TAnyFunction>(() => {});
  const isInitedRef = React.useRef<boolean>(false);

  const init = React.useCallback(
    () =>
      new Promise<void>((rootResolve) => {
        promiseRef.current = new Promise((resolve, reject) => {
          resolveRef.current = resolve;
          rejectRef.current = reject;

          isInitedRef.current = true;
          rootResolve();
        });
      }),
    [],
  );

  const debouncedPromise = React.useMemo<TAnyFunction>(
    () =>
      debounce(
        (...args: []) =>
          promise(...args)
            .then((result) => {
              resolveRef.current(result);
            })
            .catch((error) => {
              rejectRef.current(error);
            })
            .finally(() => init()),
        delay,
        options,
      ),
    [promise, delay, options, init],
  );

  React.useEffect(() => {
    init();
  }, []);

  return (async (...args: []) => {
    if (!isInitedRef.current) {
      await init();
    }

    debouncedPromise(...args);

    return promiseRef.current;
  }) as any as T;
};

export {useDebouncedPromise};
/* eslint-enable @typescript-eslint/no-explicit-any */
