import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

import {UnavailableImage as BaseUnavailableImage} from 'src/images';

type TServiceUnavailableProps = TTheme & {
  isFullWidth?: boolean;
};

const getServiceUnavailableStyle = ({theme, isFullWidth}: TServiceUnavailableProps) => {
  if (isFullWidth) {
    return css`
      ${mixins.position({
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      })}
      z-index: ${theme.zIndex.modal};
      background-color: ${theme.branding.backgrounds.primary};
    `;
  }

  return css`
    height: 100%;
  `;
};

export const ServiceUnavailable = styled.div<TServiceUnavailableProps>`
  ${mixins.flexAlign('center', 'start')}
  margin: auto;
  padding: ${({theme}) => theme.spacing.s8};
  ${getServiceUnavailableStyle}
`;

export const Text = styled.div`
  ${mixins.f5}
  max-width: 500px;
  ${({theme}) => css`
    margin-top: ${theme.spacing.s8};
    margin-bottom: ${theme.spacing.s6};
  `}
`;

export const Hint = styled.div`
  ${mixins.f4}
  max-width: 460px;
  margin-bottom: ${({theme}) => theme.spacing.s10};
`;

export const UnavailableImage = styled(BaseUnavailableImage)`
  display: none;
  ${mixins.media.laptop`
    display: block;
  `}
`;
