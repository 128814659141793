import React from 'react';
import {useTranslation} from 'react-i18next';

import {TMenuButtonOption} from 'src/containers/MenuButton';

import * as S from './style';

type TProps = {
  value: string;
  options: TMenuButtonOption[];
  className?: string;
};

const InputWithOptions = React.memo<TProps>(({value, options, className}) => {
  const {t} = useTranslation();

  return (
    <S.Row className={className}>
      <S.TextInput value={value} type="text" readOnly />
      <S.MenuButton title={t('Common:button.actions')} options={options} size="M" />
    </S.Row>
  );
});

InputWithOptions.displayName = 'InputWithOptions';

export default InputWithOptions;
