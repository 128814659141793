import {TFunction} from 'react-i18next';

import i18n from 'i18next';
import isEmpty from 'lodash/isEmpty';

import {showNewNotification} from '@edna/components';

import {showTemporaryBlockModal} from 'src/containers/UserCompanyProfile/TemporaryBlockModal';

import {ETenantCode} from '../constants';

type TViolation = {
  field: string;
  message: string;
};

type TErrorPayload = {
  messageKey?: string;
  code?: string;
  title?: string;
  status?: number;
  args?: TAnyObject;
};

type TGetErrorMessage = (
  t: TFunction,
  payload: TErrorPayload,
  i18nOptions?: TAnyObject & {
    violations?: TViolation[];
  },
) => string;

const getErrorMessage: TGetErrorMessage = (t, {messageKey, code, status}, i18nOptions) => {
  const strings = [];

  if (messageKey) {
    strings.push(t(messageKey, i18nOptions));
  }

  if (code) {
    if (i18nOptions?.violations && !isEmpty(i18nOptions?.violations)) {
      Object.values(i18nOptions.violations).forEach((value) => {
        strings.push(t(`Errors:violations.${value?.field}`, ''));
      });
    } else {
      strings.push(t([`Errors:${code}`, 'Errors:unknownErrorCode'], {...i18nOptions, code}));
    }
  }

  if (isEmpty(strings)) {
    strings.push(t([`Errors:httpStatus.${status}`, 'Errors:unexpectedError']));
  }

  return strings.join(' ');
};

const isErrorWithCode = (error?: unknown): error is {data: TErrorPayload} => {
  if (error === null || typeof error !== 'object') {
    return false;
  }

  if (!('data' in error) || typeof error.data !== 'object' || error.data === null) {
    return false;
  }

  const code =
    'code' in error.data ? error.data.code : 'title' in error.data ? error.data.title : null;

  return typeof code === 'string';
};

const getErrorCode = (error?: unknown) => {
  if (!isErrorWithCode(error)) {
    return;
  }

  return error.data.code || error.data.title;
};

// TODO: https://jira.edna.ru/browse/EP-4341 typify error, rename
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const errorNotification = (error: any, messageKey?: string, i18nOptions?: TAnyObject) => {
  if (getErrorCode(error) === ETenantCode.TENANT_TEMPORARY_BLOCKED) {
    showTemporaryBlockModal(error.config.url, error.data.detail);

    return;
  }

  showNewNotification({
    type: 'error',
    message: getErrorMessage(
      i18n.t,
      {
        code: getErrorCode(error),
        status: error?.status,
        messageKey,
      },
      {
        violations: {...error?.data?.violations},
        ...error?.data?.args,
        ...i18nOptions,
      },
    ),
  });
};

export default errorNotification;

export {errorNotification, getErrorMessage, getErrorCode, isErrorWithCode};
export type {TErrorPayload};
