import {EClientStatus} from './definitions';

export default {
  title: 'Лиды',
  addClient: 'клиента',
  searchPlaceholder: 'Поиск по клиенту',
  createLink: 'Создать ссылку',
  personalTariff: 'Индивидуальный тариф',
  status: {
    [EClientStatus.WAITING]: 'Ожидает',
    [EClientStatus.ACCEPTED]: 'Активна',
    [EClientStatus.ERROR]: 'Ошибка',
    [EClientStatus.CANCELLED]: 'Отклонено',
  },
  th: {
    createdAt: 'Дата создания',
    company: 'Компания',
    status: 'Статус',
    inviteUrl: 'Компания',
  },
  filters: {
    title: 'Фильтры',
    statusLabel: 'Статус',
    createdAtLabel: 'Дата регистрации',
    managerLabel: 'Ответственный менеджер',
    managerPlaceholder: 'Выберите менеджера',
    resellerLabel: 'Юрисдикция',
    resellerPlaceholder: 'Выберите юрлицо',
    leadClients: 'Клиентов',
  },
  notification: {
    updateResponsibleManager: 'Ответственный менеджер успешно изменен',
  },
  createInviteLinkModal: {
    title: 'Создать ссылку регистрации для клиента?',
    text: 'Логином для регистрации будет e-mail контактного лица клиента',
  },
  copyInviteLinkModal: {
    inviteLink: 'Ссылка регистрации для клиента',
    copyLink: 'Скопируйте и отправьте эту ссылку клиенту чтобы он мог войти в edna Pulse.',
    linkIsValid: 'Помните, ссылка действительна 24 часа',
    link: 'Ссылка для регистрации',
    wellDone: 'Отлично',
  },
  leadClientCard: {
    licenseStatus: 'Статус лицензии',
    expiredAt: 'Лицензия действительна до',
    responsibleManager: 'Ответственный менеджер edna',
    partner: 'Агент',
    taxNumber: 'ИНН',
    binNumber: 'Бизнес-идентификационный номер (БИН)',
    ogrn: 'ОГРН/ОГРНИП',
    country: 'Страна',
    currency: 'Валюта',
    reseller: 'Юрисдикция',
    document: 'Договор',
    documentName: '{{documentNumber}} от {{documentDate}}',
    contact: 'Контактное лицо клиента',
    contactName: 'ФИО',
    phone: 'Телефон',
    email: 'e-mail',
    companyName: 'Название организации',
    companyData: 'Данные компании',
    companyLicenses: 'Лицензии компании',
    settlementRules: 'Правила взаиморасчетов',
    paymentType: 'Тип оплаты',
    managerResponsibleForCheckingId: 'Ответственный за проверку компании',
    depositAmount: 'Размер обеспечительного платежа',
    overdraftAmount: 'Размер овердрафта',
    activateLicenseDebt: 'Активация лицензии в долг',
    paymentPeriod: 'Срок оплаты лицензии',
    cancellationPeriod: 'Срок до аннулирования лицензии при неоплате',
    allowed: 'разрешена',
    notAllowed: 'не разрешена',
    tariffs: 'Тарифы',
    empty: 'Не указано',
    responsibleManagerModal: {
      title: 'Изменить ответственного менеджера',
      selectManager: 'Выберите ответственного менеджера',
    },
    validDate: 'Действительна с {{activatedAt}} до {{validUntil}}',
  },
};
