export default {
  invoiceId: '[en] ID платежа',
  tenantId: '[en] Зачислить платеж для тенанты',
  assign: '[en] Зачислить',
  assignSuccess: '[en] Зачисление успешно',
  tenantNotFound: '[en] Такой тенанты не найдено',
  invoiceNotFound: '[en] Платеж не найден',
  onlyUnknownHint: '[en] Здесь может быть выбран только нераспределенный платеж!',
  onlyKnownHint: '[en] Здесь может быть выбран только распределенный платеж!',
  cancelInvoice: '[en] Вернуть платеж',
  cancelError: '[en] Платеж не отменен. Произошла ошибка',
  cancelSuccess: '[en] Платеж отменен успешно',
};
