import React from 'react';

import {useOnChange} from '@edna/hooks';

type TValue = unknown;

type TProps = {
  value: TValue;
  children: React.ReactNode;
  setValue: (value: TValue) => void;
};

const ExternalModificationDetector = React.memo<TProps>(({value, setValue, children}) => {
  useOnChange(() => setValue(value), [setValue, value]);

  return <>{children}</>;
});

export default ExternalModificationDetector;
