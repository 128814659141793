import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';
import BaseSmallArrowIcon from '@edna/icons/arrowUpward.svg';
import {hexToRgba} from '@edna/utils/hexToRgba';

import {SecondaryText} from 'src/components/primitives';
import {EQualityScore} from 'src/containers/Channels/definitions';

import BaseStatusIcon from '../StatusIcon';

export const Message = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
  ${({theme}) => css`
    margin-bottom: ${theme.spacing.s5};
    padding: ${theme.spacing.s5} ${theme.spacing.s6} ${theme.spacing.s5};
    border-radius: ${theme.borderRadius.br4};
    box-shadow:
      0 3px 12px -1px ${hexToRgba(theme.branding.content.primary, '26%')},
      0 2px 4px -1px ${hexToRgba(theme.branding.content.primary, '16%')};
  `}
`;

export const StatusIcon = styled(BaseStatusIcon)`
  flex-grow: 0;
  flex-shrink: 0;
  ${({theme}) => css`
    margin-top: ${theme.spacing.s1};
    margin-right: ${theme.spacing.s3};
  `}
`;

export const QualityWarningIcon = styled(BaseStatusIcon)`
  ${mixins.wh('16px')}
`;

export const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

export const Text = styled(SecondaryText)`
  white-space: pre-line;
`;

export const InfoRow = styled.div`
  ${mixins.row}
  ${mixins.f3}
`;

export type TQualityScoreProps = TTheme & {
  qualityColor: EQualityScore | null;
};

const getQualityScoreColor = ({qualityColor, theme}: TQualityScoreProps) => {
  switch (qualityColor) {
    case EQualityScore.GREEN:
      return css`
        color: ${theme.branding.colors.success};
      `;
    case EQualityScore.YELLOW:
      return css`
        color: ${theme.branding.colors.warning};
      `;
    case EQualityScore.RED:
      return css`
        color: ${theme.palette.red500};
      `;
    case EQualityScore.NA:
    default:
      return css`
        color: ${theme.branding.content.secondary};
      `;
  }
};

export const QualityScore = styled.div<TQualityScoreProps>`
  ${mixins.flexAlign('flex-start', 'center')}
  ${getQualityScoreColor}
`;

export type TLimitProps = {
  isGrayColor?: boolean;
};

export const Limit = styled.div<TLimitProps>`
  ${mixins.flexAlign('flex-start', 'center')}
  font-weight: 500;
  ${({theme, isGrayColor}) => css`
    color: ${isGrayColor ? theme.branding.content.secondary : theme.branding.content.primary};
  `}
`;
export const LimitDate = styled.div`
  ${mixins.flexAlign('flex-start', 'center')}

  ${({theme}) => css`
    color: ${theme.branding.content.primary};
  `}
`;

export const InfoLabel = styled.div<{noMargin?: boolean}>`
  ${({theme, noMargin}) => css`
    color: ${theme.branding.content.secondary};

    ${!noMargin &&
    css`
      margin-right: ${theme.spacing.s1};
    `};

    font-weight: 500;
    &::after {
      content: ':';
    }
  `}
`;

export type TArrowIconProps = {
  isDown: boolean;
};

export const ArrowIcon = styled(BaseSmallArrowIcon)<TArrowIconProps>`
  ${({theme}) => css`
    color: ${theme.branding.content.primary};
  `}
  ${mixins.wh('18px', '16px')}
  transform: rotate(${({isDown}) => (isDown ? 180 : 0)}deg);
`;
