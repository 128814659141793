import {EChannelType, TChannelType, TItemId, TSubject, TTimeUnit} from 'src/constants';
import {enumToBooleanHash} from 'src/utils';

import {selectFilters, setFiltersAction} from './slice';

export enum EStepDecisionType {
  TTL = 'TTL',
}

export type TEditFormTTL = Record<TTimeUnit, string>;

export type TDecision<TTL> = {
  type: EStepDecisionType.TTL;
  ttl: TTL;
};

export type TCyclicStage = {
  order: number;
  uuid: string;
  decision: TDecision<string> | null;
  subject: TSubject | null;
  stages: TCyclicStage[];
};

export type TCyclicStageEditForm = {
  order: number;
  uuid: string;
  decision: TDecision<Partial<TEditFormTTL>> | null;
  subject: TSubject | null;
  stages: TCyclicStageEditForm[];
};

export type TStageEditForm = Omit<TCyclicStageEditForm, 'stages'> & {
  stages: [];
};

export const TTL_CHANNELS = [
  EChannelType.WHATSAPP,
  EChannelType.VIBER,
  EChannelType.VK_NOTIFY,
  EChannelType.OK_NOTIFY,
  EChannelType.PUSH,
  EChannelType.SMS,
];

export enum EStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export const CASCADE_STATUSES = Object.values(EStatus);

export type TStatus = keyof typeof EStatus;

export type TItem<Stage = TCyclicStage> = {
  id: TItemId;
  name: string;
  status: TStatus;
  stages: Stage[];
  channelTypes: TChannelType[];
};

export const DEFAULT_STAGE: TStageEditForm = {
  order: 0,
  uuid: '',
  decision: null,
  stages: [],
  subject: null,
};

export const DEFAULT_INITIAL_VALUES: TItem<TStageEditForm> = {
  id: '',
  name: '',
  status: EStatus.ACTIVE,
  stages: [DEFAULT_STAGE],
  channelTypes: [],
};

export type TFilters = {
  filter?: string;
  subjectIds?: number[];
  statuses?: Partial<Record<EStatus, boolean>>;
  channelTypes?: Record<EChannelType, boolean>;
};

export const LIST_CONFIG = {
  filtersConfig: {
    defaultFilters: {
      filter: '',
      subjectIds: [],
      statuses: enumToBooleanHash(EStatus),
      channelTypes: enumToBooleanHash(EChannelType),
    },
    action: setFiltersAction,
    selector: selectFilters,
  },
};
