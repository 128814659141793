import React from 'react';
import {useTranslation} from 'react-i18next';

import {Tr} from '@edna/components';

import ReportTable from 'src/components/ReportTable';
import {parseISO} from 'src/utils';

import * as S from '../style';
import {TBilledEventData} from './definitions';

const BILLED_EVENT_TABLE_COLUMNS_WIDTH = ['15%', '35%', '20%', '15%', '15%'];
const DEBT_TRANSACTION_TABLE_COLUMNS_WIDTH = [
  '10%',
  '10%',
  '10%',
  '15%',
  '15%',
  '10%',
  '10%',
  '10%',
  '10%',
];
const TRANSACTION_TABLE_COLUMNS_WIDTH = [
  '8%',
  '8%',
  '8%',
  '14%',
  '14%',
  '8%',
  '8%',
  '8%',
  '8%',
  '8%',
  '8%',
];

export const BilledEventData = React.memo<{data: TBilledEventData}>(({data}) => {
  const {t} = useTranslation();

  const {billedEvent, debtTransaction, transaction} = data;

  return (
    <S.BilledEventTables>
      {billedEvent && (
        <ReportTable
          isScroll
          label={t('Corrections:payments.billedEvent')}
          columnsWidth={BILLED_EVENT_TABLE_COLUMNS_WIDTH}
        >
          <Tr>
            <S.Th>{t('Corrections:payments.id')}</S.Th>
            <S.Th>{t('Corrections:payments.originalEventId')}</S.Th>
            <S.Th>{t('Corrections:payments.originalEventType')}</S.Th>
            <S.Th>{t('Corrections:payments.confirmation')}</S.Th>
            <S.Th>{t('Corrections:payments.createdAt')}</S.Th>
          </Tr>
          <Tr>
            <S.Td>{billedEvent.id}</S.Td>
            <S.Td>{billedEvent.originalEventId}</S.Td>
            <S.Td>{billedEvent.originalEventType}</S.Td>
            <S.Td>{billedEvent.confirmation}</S.Td>
            <S.Td>{parseISO(billedEvent.createdAt, 'dd.MM.yyyy, HH:mm')}</S.Td>
          </Tr>
        </ReportTable>
      )}
      {debtTransaction && (
        <ReportTable
          isScroll
          label={t('Corrections:payments.debtTransaction')}
          columnsWidth={DEBT_TRANSACTION_TABLE_COLUMNS_WIDTH}
        >
          <Tr>
            <S.Th>{t('Corrections:payments.id')}</S.Th>
            <S.Th>{t('Corrections:payments.eventId')}</S.Th>
            <S.Th>{t('Corrections:payments.tenantId')}</S.Th>
            <S.Th>{t('Corrections:payments.reason')}</S.Th>
            <S.Th>{t('Corrections:payments.reasonCode')}</S.Th>
            <S.Th>{t('Corrections:payments.balanceId')}</S.Th>
            <S.Th>{t('Corrections:payments.amount')}</S.Th>
            <S.Th>{t('Corrections:payments.currency')}</S.Th>
            <S.Th>{t('Corrections:payments.createdAt')}</S.Th>
          </Tr>
          <Tr>
            <S.Td>{debtTransaction.id}</S.Td>
            <S.Td>{debtTransaction.eventId}</S.Td>
            <S.Td>{debtTransaction.tenantId}</S.Td>
            <S.Td>{debtTransaction.reason}</S.Td>
            <S.Td>{debtTransaction.reasonCode}</S.Td>
            <S.Td>{debtTransaction.balanceId}</S.Td>
            <S.Td>{debtTransaction.amount}</S.Td>
            <S.Td>{debtTransaction.currency}</S.Td>
            <S.Td>{parseISO(debtTransaction.createdAt, 'dd.MM.yyyy, HH:mm')}</S.Td>
          </Tr>
        </ReportTable>
      )}
      {transaction && (
        <ReportTable
          isScroll
          label={t('Corrections:payments.transaction')}
          columnsWidth={TRANSACTION_TABLE_COLUMNS_WIDTH}
        >
          <Tr>
            <S.Th>{t('Corrections:payments.id')}</S.Th>
            <S.Th>{t('Corrections:payments.eventId')}</S.Th>
            <S.Th>{t('Corrections:payments.tenantId')}</S.Th>
            <S.Th>{t('Corrections:payments.reason')}</S.Th>
            <S.Th>{t('Corrections:payments.reasonCode')}</S.Th>
            <S.Th>{t('Corrections:payments.balanceId')}</S.Th>
            <S.Th>{t('Corrections:payments.openingBalance')}</S.Th>
            <S.Th>{t('Corrections:payments.amount')}</S.Th>
            <S.Th>{t('Corrections:payments.currency')}</S.Th>
            <S.Th>{t('Corrections:payments.createdAt')}</S.Th>
            <S.Th>{t('Corrections:payments.state')}</S.Th>
          </Tr>
          <Tr>
            <S.Td>{transaction.id}</S.Td>
            <S.Td>{transaction.eventId}</S.Td>
            <S.Td>{transaction.tenantId}</S.Td>
            <S.Td>{transaction.reason}</S.Td>
            <S.Td>{transaction.reasonCode}</S.Td>
            <S.Td>{transaction.balanceId}</S.Td>
            <S.Td>{transaction.openingBalance}</S.Td>
            <S.Td>{transaction.amount}</S.Td>
            <S.Td>{transaction.currency}</S.Td>
            <S.Td>{parseISO(transaction.createdAt, 'dd.MM.yyyy, HH:mm')}</S.Td>
            <S.Td>{transaction.state}</S.Td>
          </Tr>
        </ReportTable>
      )}
    </S.BilledEventTables>
  );
});

BilledEventData.displayName = 'BilledEventData';
